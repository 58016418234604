import axios from 'axios';
import { useEffect, useState } from 'react';
import { GAME_WAITING_STATES } from '../../Constants';
import { initialStateModel } from '../../redux/reduxStates';
import { axiosErrorText } from '../../utils/helpers';

export const useLeaveText = (gameStatus: string, timer_status: string, confirm: any, setConfirm: any) => {
  useEffect(() => {
    let mounted = true;
    const getText = async () => {
      const url = '/timer_api/leave_text';
      try {
        const { data } = await axios.get(url);
        if (!mounted) return;

        setConfirm({ ...confirm, question: data.result });
      } catch (error) {
        setConfirm({ ...confirm, question: axiosErrorText(error) });
      }
    };
    getText();
    return () => {
      mounted = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gameStatus, timer_status]);
};

export const useDemand = (user: initialStateModel['user'], game: initialStateModel['game']) => {
  const [demand, setDemand] = useState('');
  const [shine, setShine] = useState('');
  const [demandAction, setDemandAction] = useState('');
  useEffect(() => {
    // eslint-disable-next-line
    if (GAME_WAITING_STATES.includes(user.gameStatus)) {
      setDemand('');
      setShine('');
      setDemandAction('');
    } else if (game.nextMove === false) {
      setDemand('- YOUR OPPONENT IS CHOOSING - ');
      setShine('');
      setDemandAction('');
    } else if (game.currentCard === -1) {
      setDemand('- SELECT YOUR NEXT CARD -');
      setShine('OWN_CARD');
      setDemandAction('Click on one of your cards!');
    } else if (game.currentProperty === '') {
      setDemand('- SELECT YOUR STRATEGY -');
      setShine('STRATEGY');
      setDemandAction('Click on one strategy!');
    } else if (game.currentOpponentCard === -1) {
      setDemand('- SELECT THE OPPONENT CARD -');
      setShine('OPPONENT_CARD');
      setDemandAction('Click on one of the cards from your opponent!');
    } else if (game.currentProperty !== '' && game.currentCard !== -1 && game.currentOpponentCard !== -1) {
      setDemand('- PRESS GO TO PLAY -');
      setShine('PRESS_PLAY');
      setDemandAction('');
    } else {
      setDemand('');
      setShine('');
      setDemandAction('');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [game, user]);
  return { demand, shine, demandAction };
};
