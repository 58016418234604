import React, { Fragment } from "react";
import { useSelector } from "react-redux";

import { RootState } from "../../redux/store";
import CardChoosen from "../Cards/CardChoosen";
import CardDrapDrop from "../Cards/CardDrapDrop";

interface DashboardChoosenCardsProps {}

const DashboardChoosenCards: React.FC<DashboardChoosenCardsProps> = () => {
  const { chosen } = useSelector((state: RootState) => state.deck);
  const demand = "- SELECT YOUR 5 CARDS -";
  return (
    <Fragment>
      <div className='text-center size-40 font-family-1 text-center primary-color pb-5'>{demand}</div>

      <div className='dashboard-choosen-cards'>
        <div className='one-card-container'>
          {chosen[0] ? <CardChoosen position={0} /> : <CardDrapDrop position={0} />}
        </div>
        <div className='one-card-container'>
          {chosen[1] ? <CardChoosen position={1} /> : <CardDrapDrop position={1} />}
        </div>
        <div className='one-card-container'>
          {chosen[2] ? <CardChoosen position={2} /> : <CardDrapDrop position={2} />}
        </div>
        <div className='one-card-container'>
          {chosen[3] ? <CardChoosen position={3} /> : <CardDrapDrop position={3} />}
        </div>
        <div className='one-card-container'>
          {chosen[4] ? <CardChoosen position={4} /> : <CardDrapDrop position={4} />}
        </div>
      </div>
    </Fragment>
  );
};

export default DashboardChoosenCards;
