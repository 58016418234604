export enum HandlerActionType {
  SET_LOADER = "HANDLER_SET_LOADER",
  SET_MESSAGE = "HANDLER_SET_MESSAGE",
  REMOVE_MESSAGE = "HANDLER_REMOVE_MESSAGE",
  SET_COOKIE_STATUS = "HANDLER_SET_COOKIE_STATUS",
}

interface SET_LOADER_MODEL {
  type: HandlerActionType.SET_LOADER;
  payload: boolean;
}

interface SET_MESSAGE_MODEL {
  type: HandlerActionType.SET_MESSAGE;
  payload: {
    message: string;
    isHTML: boolean;
  };
}

interface REMOVE_MESSAGE_MODEL {
  type: HandlerActionType.REMOVE_MESSAGE;
  payload: null;
}

interface SET_COOKIE_STATUS {
  type: HandlerActionType.SET_COOKIE_STATUS;
  payload: boolean;
}

export type HandlerActionModel =
  | SET_LOADER_MODEL
  | SET_MESSAGE_MODEL
  | REMOVE_MESSAGE_MODEL
  | SET_COOKIE_STATUS;
