import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { GAME_PLAYING, GAME_WAITING_STATES } from '../../Constants';
import { gameSetDefaultMove } from '../../redux/game/GameActions';
import { setLoader, setMessage } from '../../redux/handler/handlerActions';
import { RootState } from '../../redux/store';
import { apiPost, timeout } from '../../utils/helpers';
import GameLayout from './GameLayout';
import GameLeave from './GameLeave';
// import GameNotification from './GameNotification';
import GameOpponentCards from './GameOpponentCards';
import GameOwnCards from './GameOwnCards';
import GameProperties from './GameProperties';
import GameRounds from './GameRounds';
import GameRules from './GameRules';
import { useDemand } from './GameService';
import GameTimer from './GameTimer';
import GameTransferNow from './GameTransferNow';
import GameWaiting from './GameWaiting';

interface GameArenaProps {}

const GameArena: React.FC<GameArenaProps> = () => {
  // const [notificationAllowed, setNotificationAllowed] = useState(Notification.permission === 'granted' ? true : false);
  const { game, user } = useSelector((state: RootState) => state);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { currentCard, currentOpponentCard, currentProperty, round, nextMove } = game;
  const { lastRoundSeen, gameStatus } = user;

  const [showRules, setShowRules] = useState(false);

  const { demand, demandAction } = useDemand(user, game);

  const goGame = currentCard !== -1 && currentOpponentCard !== -1 && currentProperty !== '' && nextMove === true;

  const postRound = async () => {
    dispatch(setLoader(true));
    const body = {
      position_own: currentCard,
      position_opponent: currentOpponentCard,
      property: currentProperty,
    };
    const { isError, text } = await apiPost('/rounds_api', body);
    await timeout(2000);
    if (isError) {
      dispatch(setLoader(false));
      dispatch(setMessage(text));
    } else {
      dispatch(setLoader(false));
    }
  };

  useEffect(() => {
    if (gameStatus === GAME_PLAYING && round === 1 && lastRoundSeen !== false) {
      dispatch(setMessage('THE GAME BEGINS....'));
    }
    if (lastRoundSeen === false) {
      dispatch(gameSetDefaultMove());
      navigate('/showdown');
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gameStatus, round, lastRoundSeen]);

  return (
    <GameLayout>
      <Fragment>
        <GameRules setShow={setShowRules} show={showRules} />
        <GameTransferNow />
        <div className='game-arena'>
          <div className='row'>
            <div className='col-2'>
              <Fragment>
                <div className='nordic-logo'>
                  <img
                    className='nordic-logo-img'
                    src='/img/logos/nordic-game-logo-corp.png'
                    alt='Logo of the Nordic Games'
                  />
                </div>
                <div className='game-name size-32 game-1 mt-3'>1 vs 1</div>
              </Fragment>

              {/* {!notificationAllowed && (
                <GameNotification
                  notificationAllowed={notificationAllowed}
                  setNotificationAllowed={setNotificationAllowed}
                />
              )} */}
            </div>
            <div className='col-8'>
              {GAME_WAITING_STATES.includes(user.gameStatus) && <GameWaiting />}
              {user.gameStatus === GAME_PLAYING && <GameOpponentCards />}
            </div>
            <div className='col-2'>
              <div className='white-color text-center pb-3 size-16'> SELECTED WALLET</div>
              <div className='oppenent-name size-18 mb-3'>{user.id}</div>
              {game.opponentName !== '' && (
                <Fragment>
                  <div className='white-color text-center pb-3 size-16'> VS</div>
                  <div className='oppenent-name size-18 mb-3'>{game.opponentName}</div>
                </Fragment>
              )}

              <div
                className={`go-game flex-center size-40 ${nextMove && 'go-game-move'} ${goGame && 'activce'}`}
                onClick={() => {
                  if (nextMove) {
                    if (goGame) {
                      postRound();
                    } else {
                      dispatch(setMessage(demandAction));
                    }
                  }
                }}
              >
                GO !
              </div>
            </div>
          </div>
          <div className='row'>
            <div className='col-2 flex-center'>
              <GameTimer />
            </div>
            <div className='col-8 flex-center'>
              <div className='text-center size-40 text-center primary-color py-4'>{demand}</div>
            </div>
            <div className='col-2 flex-center'>
              <div className='rules-container size-24 white-color text-center my-3' onClick={() => setShowRules(true)}>
                Rules
              </div>
            </div>
          </div>

          <div className='row'>
            <div className='col-2'>
              <div className='py-2'></div>
              <GameRounds />
              <div className='py-2'></div>
              <GameLeave />
            </div>
            <div className='col-8'>
              <GameOwnCards />
            </div>
            <div className='col-2'>
              <GameProperties />
            </div>
          </div>
        </div>
      </Fragment>
    </GameLayout>
  );
};

export default GameArena;
