import { defaultState } from '../reduxStates';
import { CardsActionModel, CardsActionType } from './cardsModel';

export const cardsReducer = (state = defaultState.cards, action: CardsActionModel) => {
  switch (action.type) {
    case CardsActionType.REQUEST_START:
      return {
        ...state,
        isLoading: true,
      };
    case CardsActionType.REQUEST_FAIL:
      return {
        ...defaultState.cards,
        isLoading: false,
      };
    case CardsActionType.GET_LTG:
      return {
        ...state,
        data: action.payload.data,
        common: action.payload.common,
        rare: action.payload.rare,
        epic: action.payload.epic,
        ltgCoin: action.payload.ltg_coin,
        nlCoin: action.payload.nl_coin,
        isLoading: false,
      };

    default:
      return state;
  }
};
