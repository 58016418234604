import React, { Fragment, useRef, useEffect } from 'react';

export interface ConfirmStepState {
  question: string;
  confirmText: string;
  rejectText: string;
  answer: boolean;
  answerReject: boolean;
  active: boolean;
  outsideClick: boolean;
  classNameExtension?: string;
}

interface ConfirmStepProps {
  confirm: ConfirmStepState;
  setConfirm: (value: ConfirmStepState) => void;
}
const ConfirmStep: React.FC<ConfirmStepProps> = ({ confirm, setConfirm }) => {
  // const [confirm, setConfirm] =  useState<ConfirmStepState>({
  //   question: 'Möchtest du den Partner wirklich löschen?',
  //   confirmText: 'Bestätigen',
  //   rejectText: 'Verwerfen',
  //   answer: false,
  //   answerReject: false,
  //   active: false,
  //   outsideClick:true
  //   classNameExtension: "question",
  // });

  // useEffect(() => {
  //   const deleteFct = async () => {

  //   };
  //   if (confirm.answer === true) {
  //     deleteFct();
  //
  //   }
  //   // eslint-disable-next-line
  // }, [confirm.answer, confirm.answerReject]);

  // onClick={() =>
  //   setConfirm({
  //     ...confirm,
  //     active: true,
  //   })
  // }

  const { question, confirmText, rejectText, active, outsideClick } = confirm;
  const messageRef0 = useRef<HTMLDivElement>(null);
  const messageRef1 = useRef<HTMLDivElement>(null);
  const messageRef2 = useRef<HTMLDivElement>(null);
  useEffect(() => {
    let handler = (e: any) => {
      if (
        !messageRef1.current?.contains(e.target) ||
        !messageRef0.current?.contains(e.target) ||
        !messageRef2.current?.contains(e.target)
      ) {
        if (outsideClick === true) {
          setConfirm({
            ...confirm,
            active: false,
            answer: false,
            answerReject: false,
          });
        }
      } else {
        setConfirm({
          ...confirm,
          active: false,
          answer: false,
          answerReject: false,
        });
      }
    };
    document.addEventListener('mousedown', handler);
    return () => {
      document.removeEventListener('mousedown', handler);
    };
  });
  const remove = () => {
    setConfirm({
      ...confirm,
      answerReject: true,
      active: false,
      answer: false,
    });
  };
  const confirmBtn = () => {
    setConfirm({
      ...confirm,
      active: false,
      answer: true,
      answerReject: false,
    });
  };
  return (
    <Fragment>
      <div className={`message  ${active && 'active'} ${confirm.classNameExtension}`}>
        <div ref={messageRef0} className='content pt-3' style={{ maxWidth: '900px' }}>
          <div className='close'>
            <i className='far fa-times-circle' onClick={remove}></i>
          </div>
          <p className='pt-4 py-2' ref={messageRef1} dangerouslySetInnerHTML={{ __html: question }} />
          <div className='btn_container pb-4' ref={messageRef2}>
            <button onClick={remove} className='transparent'>
              {rejectText}
            </button>
            <button onClick={confirmBtn}>{confirmText}</button>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default ConfirmStep;
