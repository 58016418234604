import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { GAME_PLAYING } from '../../Constants';
import { RootState } from '../../redux/store';
import { apiGet } from '../../utils/helpers';

interface GameTimerProps {}

const GameTimer: React.FC<GameTimerProps> = () => {
  const { user, game } = useSelector((state: RootState) => state);

  const [isLoaded, setIsLoaded] = useState(false);
  const [timeIsUp, setTimeIsUp] = useState(false);
  const [seconds, setSeconds] = useState<number>(45);
  const changeTime = 20;
  const [dropState, setDropState] = useState('drop-green');
  useEffect(() => {
    const interval = setInterval(() => {
      if (isLoaded) {
        if (seconds > 0) {
          setSeconds((seconds) => seconds - 1);
        }
        if (seconds > 0 && seconds >= changeTime) {
          setDropState('drop-green');
        } else if (seconds < changeTime && seconds > 0) {
          setDropState('drop-red');
        } else if (seconds <= 0) {
          clearInterval(interval);
          setTimeIsUp(true);
        }
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [seconds, isLoaded]);

  useEffect(() => {
    let mounted = true;
    const get = async () => {
      setTimeIsUp(false);
      const { isError, data } = await apiGet('/timer_api');
      if (!isError) {
        if (data.rest_seconds > 0) {
          if (!mounted) return;
          setSeconds(data.rest_seconds);
          setDropState('drop-green');
          setIsLoaded(true);
        } else {
          if (!mounted) return;
          setDropState('drop-red');
          setSeconds(0);
          setTimeIsUp(true);
        }
      }
    };

    get();
    return () => {
      mounted = false;
    };
  }, [game.round, user.gameStatus, user.lastRoundSeen, user.timerStatus]);

  if (user.gameStatus !== GAME_PLAYING) {
    return <></>;
  }

  return (
    <div className='game-timer font-family-1'>
      <div className='size-24 text-center pb-3 white-color'>TIMER</div>
      <div className='animation-container'>
        <div className='art'>
          {[0, 1, 2, 3, 4, 5, 6].map((_el: any, index) => {
            return <Drop state={dropState} key={index} />;
          })}
        </div>
        {timeIsUp && <div className={`seconds red-color size-24 text-center bold`}>TIME IS OVER</div>}

        {!timeIsUp && (
          <div
            className={`seconds white-color ${seconds >= changeTime && 'size-24'} 
      ${seconds < changeTime && 'size-32'} text-center bold`}
          >
            {seconds}s
          </div>
        )}
      </div>
    </div>
  );
};

export default GameTimer;

interface DropProps {
  state: string;
}
const Drop: React.FC<DropProps> = ({ state }) => {
  return <div className={`drop ${state}`}></div>;
};
