import {
  PROPERTY_AAF,
  PROPERTY_AF,
  PROPERTY_BK,
  PROPERTY_DEV,
  PROPERTY_PIL,
  PROPERTY_RSI,
  PROPERTY_SI,
} from '../Constants';

export const setHoverText = (title: string) => {
  switch (title) {
    case PROPERTY_AAF:
      return 'Associates - Alliances - Followership';
    case PROPERTY_PIL:
      return 'Power - Influence - Leadership';
    case PROPERTY_BK:
      return 'Level of Bad Karma';
    case PROPERTY_SI:
      return 'Strategy - Intelligence';
    case PROPERTY_AF:
      return 'Level of Attack and Force';
    case PROPERTY_DEV:
      return 'Level of Defence';
    case PROPERTY_RSI:
      return 'Relevance - Symbolism - Importance';
    default:
      return '';
  }
};

export const setPropertyTextShowdown = (title: string) => {
  switch (title) {
    case PROPERTY_AAF:
      return 'Associates - Alliances - Followership';
    case PROPERTY_PIL:
      return 'Power - Influence - Leadership';
    case PROPERTY_BK:
      return 'Level of Bad Karma';
    case PROPERTY_SI:
      return 'Strategy - Intelligence';
    case PROPERTY_AF:
      return 'Level of Attack and Force';
    case PROPERTY_DEV:
      return 'Level of Defence';
    case PROPERTY_RSI:
      return 'Relevance - Symbolism - Importance';
    default:
      return '';
  }
};

export const getPropertyImage = (title: string, schema = 'Rare') => {
  var src = '';

  if (title === PROPERTY_AAF) {
    src = allianzSrc[0];
  } else if (title === PROPERTY_PIL) {
    src = influenceSrc[0];
  } else if (title === PROPERTY_BK) {
    src = karmaSrc[0];
  } else if (title === PROPERTY_SI) {
    src = strategySrc[0];
  } else if (title === PROPERTY_AF) {
    src = attackSrc[0];
  } else if (title === PROPERTY_DEV) {
    src = defenceSrc[0];
  } else if (title === PROPERTY_RSI) {
    src = importanceSrc[0];
  }
  return src;
};

export const getPropertyIndex = (title: string, own: boolean = true) => {
  if (own) {
    switch (title) {
      case PROPERTY_AAF:
        return 0;
      case PROPERTY_PIL:
        return 1;
      case PROPERTY_BK:
        return 2;
      case PROPERTY_SI:
        return 3;
      case PROPERTY_AF:
        return 4;
      case PROPERTY_DEV:
        return 5;
      case PROPERTY_RSI:
        return 6;
      default:
        return '';
    }
  } else {
    switch (title) {
      case PROPERTY_AAF:
        return 0;
      case PROPERTY_PIL:
        return 1;
      case PROPERTY_BK:
        return 2;
      case PROPERTY_SI:
        return 3;
      case PROPERTY_AF:
        return 5;
      case PROPERTY_DEV:
        return 4;
      case PROPERTY_RSI:
        return 6;
      default:
        return '';
    }
  }
};

export const moveOfEnemy = (move: string) => {
  if (move === PROPERTY_AF) {
    return PROPERTY_DEV;
  } else if (move === PROPERTY_DEV) {
    return PROPERTY_AF;
  } else {
    return move;
  }
};

export const NordicCategories = [
  { id: 'beowulf', title: 'Beowulf' },
  { id: 'nibelungen', title: 'The Nibelungen Saga' },
  { id: 'swan_knight', title: 'The Swan Knight' },
  { id: 'von_bern', title: 'Dietrich von Bern' },
  { id: 'hans_fortunat', title: 'Hans Fortunat Glückssäckel' },
  { id: 'duke_ernst', title: 'Herzog Ernst' },
  { id: 'jring_jrminfried', title: 'Jring & Jrminfried' },
  { id: 'siegfried', title: 'Young Siegfried' },
  { id: 'king_rother', title: 'King Rother' },
  { id: 'ortnit', title: 'Ortnit' },
  { id: 'parzival', title: 'Parzival' },
  { id: 'wieland', title: 'Wieland' },
  { id: 'dietrich', title: 'Wolf Dietrich' },
];

export const allianzSrc = [
  '/img/iconfinder/allianz_2.png',
  '/img/iconfinder/allianz_R_2.png',
  '/img/iconfinder/allianz_E_2.png',
];
export const influenceSrc = ['/img/iconfinder/power.png', '/img/iconfinder/power_R.png', '/img/iconfinder/power_E.png'];
export const karmaSrc = ['/img/iconfinder/karma.png', '/img/iconfinder/karma_R.png', '/img/iconfinder/karma_E.png'];
export const strategySrc = [
  '/img/iconfinder/strategy.png',
  '/img/iconfinder/strategy_R.png',
  '/img/iconfinder/strategy_E.png',
];
export const attackSrc = [
  '/img/iconfinder/attack_2.png',
  '/img/iconfinder/attack_2_R.png',
  '/img/iconfinder/attack_2_E.png',
];
export const defenceSrc = [
  '/img/iconfinder/defence.png',
  '/img/iconfinder/defence_R.png',
  '/img/iconfinder/defence_E.png',
];
export const importanceSrc = [
  '/img/iconfinder/importance.png',
  '/img/iconfinder/importance_R.png',
  '/img/iconfinder/importance_E.png',
];
