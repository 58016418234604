export enum CardsActionType {
  REQUEST_START = "CARDS_REQUEST_START",
  SET_BUY_OPTION = "CARDS_SET_BUY_OPTION",
  REQUEST_FAIL = "CARDS_REQUEST_FAIL",

  GET_LTG = "CARDS_GET_LTG",
}

interface REQUEST_START {
  type: CardsActionType.REQUEST_START;
}

interface REQUEST_FAIL {
  type: CardsActionType.REQUEST_FAIL;
}

interface GET_LTG {
  type: CardsActionType.GET_LTG;
  payload: any;
}

export type CardsActionModel = REQUEST_START | REQUEST_FAIL | GET_LTG;
