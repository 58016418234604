import React, { Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import CardScroll from "../Cards/CardScroll";

import { Scrollbars } from "react-custom-scrollbars";
import BuyButton from "../../components/nordic-games/BuyButton";
import { userSetLoginOption } from "../../redux/user/userActions";
import { forceCheck } from "react-lazyload";
import { NordicCategories } from "../../utils/NordicHelpers";
import CustomLazyload from "../../utils/CustomLazyload";
interface DashboardCardScrollProps {}

const DashboardCardScroll: React.FC<DashboardCardScrollProps> = () => {
  const { cards, user } = useSelector((state: RootState) => state);
  const dispatch = useDispatch();
  const onScroll = () => {
    forceCheck();
  };
  return (
    <div className='dashboard-card-scroll'>
      {!cards.isLoading && !user.isLoading && (
        <Fragment>
          <div className='size-18 white-color font-family-1 text-center pb-2'>
            {user.isLogged ? (
              <span>
                your <br /> Nordic Legends
                <br />
                assets
              </span>
            ) : (
              <Fragment>
                <BuyButton
                  text='LOGIN'
                  onClick={() => {
                    dispatch(userSetLoginOption(true));
                  }}
                  classNameExtension='dashboard-card-scroll--buy-now pb-3'
                />
              </Fragment>
            )}
          </div>

          <div className='dashboard-card-scroll-container' id='scrollbar-wrapper'>
            <Scrollbars style={{ height: 700 }} onScroll={onScroll}>
              {NordicCategories.map((el, index) => {
                return (
                  <OneCategory
                    key={index}
                    title={el.title}
                    data={cards.data.filter((x: any) => x.category === el.id)}
                  />
                );
              })}
            </Scrollbars>
          </div>

          <BuyButton classNameExtension='dashboard-card-scroll--buy-now pt-3' text='EXTEND YOUR DECK' />
        </Fragment>
      )}
    </div>
  );
};

export default DashboardCardScroll;

interface OneCategoryProps {
  title: string;
  data: any;
}
const OneCategory: React.FC<OneCategoryProps> = ({ title, data }) => {
  return (
    <div className='one-category pb-2'>
      <div className='size-24 white-color  font-family-1'>{title}</div>
      <hr className='shared-hr ' />

      <div className='py-2'></div>
      {data.map((el: any, key: any) => {
        return (
          <CustomLazyload key={key}>
            <div className='one-card-container'>
              <CardScroll cardElement={el} />
            </div>
          </CustomLazyload>
        );
      })}
    </div>
  );
};
