import React, { useState, Fragment, useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { removeMessage } from '../../redux/handler/handlerActions';
import { RootState } from '../../redux/store';

const Message: React.FC = () => {
  const handlers = useSelector((state: RootState) => state.handlers);
  const [afterRender, setAfterRender] = useState(false);
  const { isMessage, message, isHTML } = handlers;
  const dispatch = useDispatch();
  let messageRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    let handler = (e: any) => {
      if (!messageRef.current?.contains(e.target) && isMessage === true) {
        dispatch(removeMessage());
      }
    };
    document.addEventListener('mousedown', handler);
    return () => {
      document.removeEventListener('mousedown', handler);
    };
  });

  useEffect(() => {
    setTimeout(() => {
      setAfterRender(true);
    }, 1200);
  }, []);

  return (
    <Fragment>
      <div className={`overlay-message ${isMessage && afterRender && 'active'}`}></div>
      <div className={isMessage && afterRender ? 'message active' : 'message'}>
        <div ref={messageRef} className='content' style={{ maxWidth: '650px' }}>
          <div className='close'>
            <i className='far fa-times-circle' onClick={() => dispatch(removeMessage())}></i>
          </div>
          {isHTML === true ? (
            <div className='content-text text-justify' dangerouslySetInnerHTML={{ __html: message }} />
          ) : (
            <div className='content-text'>{message}</div>
          )}
        </div>
      </div>
    </Fragment>
  );
};

export default Message;
