import React, { Fragment, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { GAME_RESULT_LOST, ROUND_LOST, GAME_RESULT_WON, ROUND_WON, NFT_SOURCE_URL } from '../../Constants';
import { RootState } from '../../redux/store';

import GameRounds from './GameRounds';

interface GameFinalStatisticProps {
  outcomes: any;
  gameResult: string;
}

const GameFinalStatistic: React.FC<GameFinalStatisticProps> = ({ outcomes, gameResult }) => {
  const { cards } = useSelector((state: RootState) => state);
  const [text, setText] = useState('');
  const [count, setCount] = useState([0, 0]);
  useEffect(() => {
    const countWon = outcomes.filter((obj: string) => {
      if (obj === ROUND_WON) {
        return true;
      }
      return false;
    }).length;
    const countLost = outcomes.filter((obj: string) => {
      if (obj === ROUND_LOST) {
        return true;
      }
      return false;
    }).length;

    setCount([countWon, countLost]);

    if (gameResult === GAME_RESULT_WON) {
      setText('CONGRATULATIONS YOU WON !');
    } else if (gameResult === GAME_RESULT_LOST) {
      setText('YOU LOST THE GAME');
    } else {
      setText('THAT WAS A TIE GAME');
    }
  }, [outcomes, gameResult]);
  return (
    <div className='game-final-statistic'>
      <div className='row py-5'>
        <div className='col-lg-6 left-col'>
          <div className='text-center white-color size-24 pb-4'>STATS OF THE GAME</div>
          <GameRounds outcomeExtern={outcomes} />
        </div>

        <div className='col-lg-6 right-col-content'>
          <div className='white-color size-24 text-center'>{text}</div>
          <div className='end-result size-64 white-color py-4'>
            <span
              className={`mx-4 ${gameResult === GAME_RESULT_WON && 'primary-color'} ${
                gameResult === GAME_RESULT_LOST && 'red-color'
              }`}
            >
              {count[0]}
            </span>{' '}
            <span className='mx-3'>:</span>{' '}
            <span
              className={`mx-4 ${gameResult === GAME_RESULT_WON && 'red-color'} ${
                gameResult === GAME_RESULT_LOST && 'primary-color'
              }`}
            >
              {count[1]}
            </span>
          </div>
          {gameResult === GAME_RESULT_WON && (
            <Fragment>
              <div className='primary-color pb-3'>YOU WON ONE NL COIN</div>
              <div className='winner-coin'>
                <div className={`final-container-nl-coin`}>
                  {cards.nlCoin.length > 0 && (
                    <video
                      src={NFT_SOURCE_URL + cards.nlCoin[0].immutable_data.video}
                      className={`final-coin-video-nl-coin`}
                      playsInline
                      autoPlay
                      muted
                      loop
                    ></video>
                  )}
                </div>{' '}
              </div>
            </Fragment>
          )}
        </div>
      </div>
    </div>
  );
};

export default GameFinalStatistic;
