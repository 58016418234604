import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import HoverTippy from '../../components/gadgets/HoverTippy';

import { GameEnterArena } from '../../redux/game/GameActions';

import { RootState } from '../../redux/store';

interface DashboardGameStripProps {}

const DashboardGameStrip: React.FC<DashboardGameStripProps> = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { deck } = useSelector((state: RootState) => state);
  const isReady = deck.chosen[0] && deck.chosen[1] && deck.chosen[2] && deck.chosen[3] && deck.chosen[4];

  return (
    <div className='dashboard-game-strip font-family-1 white-color'>
      <div className='game-name size-32 game-1 active' onClick={() => navigate('/rules')}>
        1 vs 1
      </div>
      <div
        className={`game-enter size-32 ${isReady && 'ready'}`}
        onClick={async () => {
          if (isReady) {
            dispatch(GameEnterArena());
          }
        }}
      >
        ENTER GAME
      </div>
      <HoverTippy
        hoverElement={<div className='game-name size-32 game-fame'>fame</div>}
        classNameExtension='tippy-game-property'
      >
        <span>COMING SOON</span>
      </HoverTippy>
    </div>
  );
};

export default DashboardGameStrip;
