import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import Footer from '../../components/layout/Footer';
import DashboardTopElements from '../Dashboard/DashboardTopElements';

interface OnlyBigScreensProps {}

const OnlyBigScreens: React.FC<OnlyBigScreensProps> = () => {
  return (
    <Fragment>
      <div className='background-welcome pt-5'>
        <DashboardTopElements />
        <div className='only-big-screens'>
          <div className='container pt-5'>
            <div className='text-center py-5 size-32 size-18-md font-family-1 white-color text-uppercase'>
              We are currently working on game Interface for Mobile Devices....
            </div>
            <div className='text-center py-4'>
              <Link className='btn size-24 size-18-md font-family-1' to='/wallet'>
                BACK TO WALLET
              </Link>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </Fragment>
  );
};

export default OnlyBigScreens;
