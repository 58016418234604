import { defaultState } from '../reduxStates';
import { UserActionType, UserActionModel } from './userModel';

export const userReducer = (state = defaultState.user, action: UserActionModel) => {
  switch (action.type) {
    case UserActionType.REQUEST_START:
      return {
        ...state,
        isLoading: true,
      };
    case UserActionType.LOGIN_SUCCESS:
      return {
        ...state,
        id: action.payload.id,
        userStatus: action.payload.userStatus,
        gameStatus: action.payload.gameStatus,
        nlsoneanicom: action.payload.nlsoneanicom,
        nlsoneanirar: action.payload.nlsoneanirar,
        nlsoneaniepi: action.payload.nlsoneaniepi,
        ltgcoin: action.payload.ltgcoin,
        nlcoin: action.payload.nlcoin,
        nordic_count: action.payload.nordic_count,
        templates: action.payload.templates,
        isLoading: false,
        isLogged: true,
      };

    case UserActionType.LOAD_USER:
      return {
        ...state,
        id: action.payload.id,
        userStatus: action.payload.usertatus,
        gameStatus: action.payload.gameStatus,
        nlsoneanicom: action.payload.result.nlsoneanicom,
        nlsoneanirar: action.payload.result.nlsoneanirar,
        nlsoneaniepi: action.payload.result.nlsoneaniepi,
        ltgcoin: action.payload.result.ltgcoin,
        nlcoin: action.payload.result.nlcoin,
        nordic_count: action.payload.result.nordic_count,
        templates: action.payload.result.templates,
        isLoading: false,
        isLogged: action.payload.isLogged,
      };

    case UserActionType.UPDATE_GAME_STATUS:
      return {
        ...state,
        gameStatus: action.payload.gameStatus,
        timerStatus: action.payload.timerStatus,
        lastRoundSeen: action.payload.lastRoundSeen,
      };

    case UserActionType.LOGOUT_SUCCESS:
      return {
        ...defaultState.user,
        isLoading: false,
      };
    case UserActionType.SET_BUY_OPTION:
      return {
        ...state,
        isBuyOptions: action.payload,
      };
    case UserActionType.SET_LOGIN_OPTION:
      return {
        ...state,
        isLoginOptions: action.payload,
      };
    case UserActionType.REQUEST_FAIL:
      return {
        ...defaultState.user,
        isLoading: false,
      };

    default:
      return state;
  }
};
