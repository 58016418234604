import { defaultState } from "../reduxStates";
import { HandlerActionModel, HandlerActionType } from "./handlerModel";

export const handlerReducer = (state = defaultState.handlers, action: HandlerActionModel) => {
  switch (action.type) {
    case HandlerActionType.SET_LOADER:
      return { ...state, isLoading: action.payload };
    case HandlerActionType.SET_COOKIE_STATUS:
      return { ...state, cookieStatus: action.payload };
    case HandlerActionType.SET_MESSAGE:
      return {
        ...state,
        isMessage: true,
        message: action.payload.message,
        isHTML: action.payload.isHTML,
      };

    case HandlerActionType.REMOVE_MESSAGE:
      return {
        ...state,
        isMessage: false,
      };
    default:
      return state;
  }
};
