import React from 'react';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { DndProvider } from 'react-dnd';

import DashboardTopElements from './DashboardTopElements';
import BackToTopBtn from '../../components/layout/BackToTopBtn';
import Footer from '../../components/layout/Footer';

interface DashboardLayoutProps {
  children: JSX.Element;
}

const DashboardLayout: React.FC<DashboardLayoutProps> = ({ children }) => {
  return (
    <DndProvider backend={HTML5Backend}>
      <div className='dashboard-layout background-welcome'>
        <div className='dashboard-layout-container'>
          <div className='top-elemnts'>
            <DashboardTopElements />
          </div>
          {children}
        </div>
      </div>
      <BackToTopBtn />
      <Footer />
    </DndProvider>
  );
};

export default DashboardLayout;
