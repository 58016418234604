import React, { Fragment } from "react";
import { useSelector } from "react-redux";
import { IS_LOADING_REDUX_STATE } from "../../Constants";
import { RootState } from "../../redux/store";

interface LoaderProps {
  loadingSafari: boolean;
}

const Loader: React.FC<LoaderProps> = ({ loadingSafari }) => {
  const state = useSelector((state: RootState) => state);
  let isLoading = false;

  for (let obj of Object.values(state)) {
    if (IS_LOADING_REDUX_STATE in obj) {
      if (obj.isLoading === true) {
        isLoading = true;
      }
    }
  }
  return (
    <Fragment>
      <div className={`loader-overlay ${isLoading ? "active" : ""}`}>
        {loadingSafari && (
          <div className='hollowLoader'>
            <div className='largeBox'></div>
            <div className='smallBox'></div>
          </div>
        )}
      </div>
    </Fragment>
  );
};

export default Loader;
