export enum ContractActionType {
  REQUEST_START = "CONTRACT_REQUEST_START",
  REQUEST_FAIL = "CONTRACT_REQUEST_FAIL",
  TRANSFER = "CONTRACT_TEST_CONTRACT",
  SHOW_TRANSFER_NOW = "CONTRACT_SHOW_TRANSFER_NOW",
}

interface REQUEST_START {
  type: ContractActionType.REQUEST_START;
}

interface TRANSFER {
  type: ContractActionType.TRANSFER;
  payload: any;
}

interface SHOW_TRANSFER_NOW {
  type: ContractActionType.SHOW_TRANSFER_NOW;
  payload: boolean;
}

interface REQUEST_FAIL {
  type: ContractActionType.REQUEST_FAIL;
}

export type ContractActionModel = REQUEST_START | TRANSFER | REQUEST_FAIL | SHOW_TRANSFER_NOW;
