import React, { Fragment } from 'react';
import { useTransition, animated } from 'react-spring';
interface SpringTransitionProps {
  type?: string;
  isLoaded: boolean;
  children: JSX.Element;
}
const SpringTransition: React.FC<SpringTransitionProps> = ({ children, isLoaded, type }) => {
  const fadeIn = useTransition(isLoaded, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
    config: { duration: 400 },
  });

  const fromTop = useTransition(isLoaded, {
    from: { transform: 'translate(0%,-100%)' },
    enter: { transform: 'translate(0%,0%)' },
    leave: { transform: 'translate(0%,-100%)' },
    config: { duration: 800 },
  });

  const fromTopNavbar = useTransition(isLoaded, {
    from: { transform: 'translate(0%,-20px)', opacity: 0 },
    enter: { transform: 'translate(0%,0%)', opacity: 1 },
    leave: { transform: 'translate(0%,-20px)', opacity: 0 },
    config: { duration: 500 },
  });
  return (
    <>
      {type === 'fadeIn' ? (
        <Fragment>
          {fadeIn((style, item) => item !== null && <animated.div style={style}>{children}</animated.div>)}
        </Fragment>
      ) : type === 'fromTop' ? (
        <Fragment>
          {fromTop((style, item) => item !== null && <animated.div style={style}>{children}</animated.div>)}
        </Fragment>
      ) : type === 'fromTopNavbar' ? (
        <Fragment>
          {fromTopNavbar((style, item) => item !== null && <animated.div style={style}>{children}</animated.div>)}
        </Fragment>
      ) : (
        <Fragment>
          {fadeIn((style, item) => item !== null && <animated.div style={style}>{children}</animated.div>)}
        </Fragment>
      )}
    </>
  );
};

export default SpringTransition;
