import React, { Fragment, useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { USER_STATUS_ANCHOR, USER_STATUS_WAX } from '../../Constants';
import { RootState } from '../../redux/store';
import { userLoginWallet, userSetLoginOption } from '../../redux/user/userActions';

export interface LoginOptionsState {}

interface LoginOptionsProps {}
const LoginOptions: React.FC<LoginOptionsProps> = () => {
  const dispatch = useDispatch();
  const user = useSelector((state: RootState) => state.user);
  const { isLoginOptions } = user;
  const messageRef0 = useRef<HTMLDivElement>(null);
  const messageRef1 = useRef<HTMLDivElement>(null);
  const messageRef2 = useRef<HTMLDivElement>(null);

  useEffect(() => {
    let handler = (e: any) => {
      if (
        !messageRef1.current?.contains(e.target) ||
        !messageRef0.current?.contains(e.target) ||
        !messageRef2.current?.contains(e.target)
      ) {
        // dispatch(userSetBuyOption(false));
      } else {
        // dispatch(userSetBuyOption(false));
      }
    };
    document.addEventListener('mousedown', handler);
    return () => {
      document.removeEventListener('mousedown', handler);
    };
  });
  const remove = () => {
    dispatch(userSetLoginOption(false));
  };

  return (
    <Fragment>
      <div className={`overlay-message ${isLoginOptions && 'active'}`}></div>
      <div className={`message login-options ${isLoginOptions && 'active'}`} onClick={remove}>
        <div ref={messageRef0} className='content pt-3' style={{ maxWidth: '650px' }}>
          <div className='close'>
            <i className='far fa-times-circle' onClick={remove}></i>
          </div>

          <div className='color-white text-center font-family-1 size-24 bold'>CONNECT YOUR WALLET</div>
          <div className='content-container'>
            <div className='one-el mr-o me-lg-4 pb-4'>
              <div className='wax-content' onClick={() => dispatch(userLoginWallet(USER_STATUS_WAX))}>
                <div className='img-wax-container'>
                  <img className='img-wax' src='/img/partner/wax-cloud-wallet.png' alt='Wax Cloud Wallet' />
                </div>
                <div className='text-center font-family-1 wax-text ml-3 size-16'> CLOUD WALLET </div>
              </div>
            </div>
            <div className='one-el ml-0 ml-lg-4 pb-4'>
              <div className='anchor-content' onClick={() => dispatch(userLoginWallet(USER_STATUS_ANCHOR))}>
                <div className='img-anchor-container'>
                  <img className='img-anchor' src='/img/partner/anchor.png' alt='Anchor' />
                </div>
                <div className='text-center font-family-1 anchor-text ml-3 size-16'> ANCHOR </div>
              </div>
            </div>
          </div>
          <div
            className='color-white text-center font-family-1 size-16 pt-4 not-yet'
            onClick={() => window.open('https://create.anchor.link/', '_blank')}
          >
            Don't have an account yet?
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default LoginOptions;
