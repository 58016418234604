// import { setSessionStorage } from "../utils/ReduxHelpers";
import axios from 'axios';
import { Dispatch } from 'react';

import { RootState } from '../store';
import { CardsActionModel, CardsActionType } from './cardsModel';

export const getTemplates = () => async (dispatch: Dispatch<CardsActionModel>, getState: () => RootState) => {
  try {
    dispatch({
      type: CardsActionType.REQUEST_START,
    });
    const { data } = await axios.get(`/cards_api/get_templates/`);
    dispatch({
      type: CardsActionType.GET_LTG,
      payload: data.result,
    });
  } catch (error) {
    dispatch({
      type: CardsActionType.REQUEST_FAIL,
    });
    defaultLocalStorage();
  }
};

const defaultLocalStorage = () => {
  // setSessionStorage("cards.data", defaultState.cards.data);
};
