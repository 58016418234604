import React, { Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import BuyButton from '../../components/nordic-games/BuyButton';
import {
  GAME_WAITING,
  GAME_WAITING_CONTRACTS_BOTH,
  GAME_WAITING_CONTRACTS_OPPONENT,
  GAME_WAITING_CONTRACTS_OWN,
  GAME_WAITING_CONTRACTS_OWN_CONFIRMED,
} from '../../Constants';
import { contractTransactionUser } from '../../redux/contract/contractActions';
import { RootState } from '../../redux/store';

interface GameWaitingProps {}

const GameWaiting: React.FC<GameWaitingProps> = () => {
  const { user } = useSelector((state: RootState) => state);
  return (
    <div className='game-waiting flex-center'>
      {user.gameStatus === GAME_WAITING && <WaitingOpponent />}
      {user.gameStatus === GAME_WAITING_CONTRACTS_BOTH && <WaitingContractBoth />}
      {user.gameStatus === GAME_WAITING_CONTRACTS_OWN_CONFIRMED && <WaitingContractConfirmedOwn />}
      {user.gameStatus === GAME_WAITING_CONTRACTS_OWN && <WaitingContractOwn />}
      {user.gameStatus === GAME_WAITING_CONTRACTS_OPPONENT && <WaitingContractOpponent />}
    </div>
  );
};

export default GameWaiting;

interface WaitingOpponentProps {}
const WaitingOpponent: React.FC<WaitingOpponentProps> = () => {
  const { game } = useSelector((state: RootState) => state);
  return (
    <Fragment>
      {game.opponentName !== '' && (
        <Fragment>
          <h2 className='primary-color size-40 text-center pb-3 text-uppercase'>WAITING FOR {game.opponentName}</h2>
          <div className='text-center white-color size-24 pb-5 text-uppercase'>Write a reminder on Social Media </div>
          <div className='btn-container'>
            <div
              className='social-links'
              onClick={() => window.open('https://t.me/losttreasuresgalaxytelegram', '_blank')}
            >
              <img
                src='/img/social/telegram.png'
                alt='Lost Treasures Galaxy Social Media Link to Telegram'
                className='telegram-img'
              />
            </div>
            <div className='social-links' onClick={() => window.open('https://discord.gg/6jMx2TRzjR', '_blank')}>
              <img
                src='/img/social/Discord.png'
                alt='Lost Treasures Galaxy Social Media Link to Discord'
                className='discord-img'
              />
            </div>
          </div>
        </Fragment>
      )}

      {game.opponentName === '' && (
        <Fragment>
          <h2 className='primary-color size-40 text-center pb-3'>WAITING FOR AN OPPONENT</h2>
          <div className='text-center white-color size-24 pb-5 text-uppercase'>Share on Social Media </div>
          <div className='btn-container'>
            <div
              className='social-links'
              onClick={() => window.open('https://t.me/losttreasuresgalaxytelegram', '_blank')}
            >
              <img
                src='/img/social/telegram.png'
                alt='Lost Treasures Galaxy Social Media Link to Telegram'
                className='telegram-img'
              />
            </div>
            <div className='social-links' onClick={() => window.open('https://discord.gg/6jMx2TRzjR', '_blank')}>
              <img
                src='/img/social/Discord.png'
                alt='Lost Treasures Galaxy Social Media Link to Discord'
                className='discord-img'
              />
            </div>
          </div>
        </Fragment>
      )}
    </Fragment>
  );
};

interface WaitingContractBothProps {}
const WaitingContractBoth: React.FC<WaitingContractBothProps> = () => {
  const dispatch = useDispatch();
  return (
    <Fragment>
      <h2 className='primary-color size-32 text-center pb-3'>YOUR OPPONENT IS IN THE ARENA</h2>
      <div className='text-center white-color size-20 pb-5 text-uppercase'>
        Transfer your Deck now to our casino wallet so the game can start
      </div>
      <div>
        <BuyButton
          classNameExtension='size-big'
          text='TRANSFER NOW'
          onClick={() => {
            dispatch(contractTransactionUser());
          }}
        />
      </div>
    </Fragment>
  );
};

interface WaitingContractOpponentProps {}
const WaitingContractOpponent: React.FC<WaitingContractOpponentProps> = () => {
  return (
    <Fragment>
      <h2 className='primary-color size-32 text-center pb-3'>WAITING FOR YOUR OPPONENT TO TRANSFER HIS DECK</h2>
    </Fragment>
  );
};

interface WaitingContractOwnProps {}
const WaitingContractOwn: React.FC<WaitingContractOwnProps> = () => {
  const dispatch = useDispatch();
  return (
    <Fragment>
      <h2 className='primary-color size-32 text-center pb-3'>YOUR OPPONENT IS IN THE ARENA</h2>
      <div className='text-center white-color size-20 pb-5 text-uppercase'>
        Transfer your Deck now to our Escrow wallet so the game can start
      </div>
      <div>
        <BuyButton
          classNameExtension='size-big'
          text='TRANSFER NOW'
          onClick={() => {
            dispatch(contractTransactionUser());
          }}
        />
      </div>
    </Fragment>
  );
};

interface WaitingContractConfirmedOwnProps {}
const WaitingContractConfirmedOwn: React.FC<WaitingContractConfirmedOwnProps> = () => {
  return (
    <Fragment>
      <div className='waiting-text size-24 bold'>
        <div className='animation-container'>
          <div className='art'>
            {[0, 1, 2, 3, 4, 5, 6].map((_el: any, index) => {
              return <div className={`drop`} key={index}></div>;
            })}
          </div>
        </div>
        <div className='size-24 bold white-color text-uppercase '>Waiting for Transfer Confirmation</div>
      </div>
    </Fragment>
  );
};
