import React, { Fragment } from "react";
import { useSelector } from "react-redux";

import { RootState } from "../../redux/store";
import CardGame from "../Cards/CardGame";

interface GameOwnCardsProps {}

const GameOwnCards: React.FC<GameOwnCardsProps> = () => {
  const { deck } = useSelector((state: RootState) => state.game);
  return (
    <Fragment>
      <div className='game-own-cards'>
        <div className='one-card-container'>{deck[0] && <CardGame position={0} />}</div>
        <div className='one-card-container'>{deck[1] && <CardGame position={1} />}</div>
        <div className='one-card-container'>{deck[2] && <CardGame position={2} />}</div>
        <div className='one-card-container'>{deck[3] && <CardGame position={3} />}</div>
        <div className='one-card-container'>{deck[4] && <CardGame position={4} />}</div>
      </div>
    </Fragment>
  );
};

export default GameOwnCards;
