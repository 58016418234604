import React from 'react';
import { useDispatch } from 'react-redux';

import { userSetBuyOption } from '../../redux/user/userActions';
import UserDrop from '../Users/UserDrop';
import UserOwns from '../Users/UserOwns';

interface DashboardTopElementsProps {}

//  SEBASTIAN in layout einbauen analog zu nlstaking???
const DashboardTopElements: React.FC<DashboardTopElementsProps> = () => {
  const dispatch = useDispatch();

  return (
    <div className='dashboard-top-elements'>
      <div className='nordic-logo'>
        <img className='nordic-logo-img' src='/img/logos/nordic-game-logo-corp.png' alt='Logo of the Nordic Games' />
      </div>
      <div className='you-own'>
        <UserOwns />
      </div>
      <div className='banner'>
        <img
          onClick={() => dispatch(userSetBuyOption(true))}
          className='banner-img'
          src='/img/ltgcoinbanner/Pack_Banner.png'
          alt='Banner to Buy a Nordic Pack'
        />
      </div>
      <div className='login-part'>
        <div className='user-text mb-3 size-18'>
          <UserDrop />
        </div>
      </div>
    </div>
  );
};

export default DashboardTopElements;
