import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { setLoader, setMessage } from '../../redux/handler/handlerActions';
import { apiGet } from '../../utils/helpers';
import { cardsGetScores } from '../Cards/CardsService';
import DashboardLayout from '../Dashboard/DashboardLayout';
import GameFinalStatistic from './GameFinalStatistic';

import GameOutcome from './GameOutcome';

interface GameFinalProps {}

const GameFinal: React.FC<GameFinalProps> = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [state, setState] = useState<any | null>(null);
  const [outcomes, setOutcomes] = useState<any | null>(null);
  const [gameResult, setGameResult] = useState('');
  const [opponentName, setOpponentName] = useState('');
  useEffect(() => {
    const get = async () => {
      dispatch(setLoader(true));
      const { isError, text, data } = await apiGet('/games_api/final');

      if (isError) {
        dispatch(setLoader(false));
        dispatch(setMessage(text));
      } else {
        dispatch(setLoader(false));

        const x: {
          opponentCard: any;
          scoresOpponent: any[];
          ownCard: any;
          scoresOwn: any[];
          round: number;
          outcome: any;
          move: any;
          isLoaded: boolean;
        }[] = [];

        for (let index = 0; index < 5; index++) {
          const ownCard = data.own_cards[data.own_play_positions[index]];
          const opponentCard = data.opponent_cards[data.opponent_play_positions[index]];

          const y = {
            opponentCard: opponentCard,
            scoresOpponent: cardsGetScores(opponentCard),
            ownCard: ownCard,
            scoresOwn: cardsGetScores(ownCard),
            round: index + 1,
            outcome: data.outcomes[index],
            move: data.moves[index],
            isLoaded: true,
          };
          x.push(y);
        }

        setState(x);
        setGameResult(data.game_result);
        setOutcomes(data.outcomes);
        setOpponentName(data.opponent_name);
      }
    };

    get();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <DashboardLayout>
      <div className='game-final font-family-1'>
        <h1 className='primary-color text-center py-5 size-48'>GAME RESULT</h1>
        <div className='py-2'>
          <div className='white-color text-center pb-3 size-16'>YOUR OPPONENT</div>
          <div className='oppenent-name size-18 mb-3 '>{opponentName}</div>
        </div>

        {outcomes !== null && <GameFinalStatistic outcomes={outcomes} gameResult={gameResult} />}

        <div className='py-5'>
          {state && (
            <Fragment>
              {state.map((el: any, index: number) => {
                return (
                  <div className='pb-5' key={index}>
                    <div className='white-color size-40 text-center pb-4'>Round {el.round}</div>
                    <GameOutcome state={el} />
                  </div>
                );
              })}
              <h1 className='primary-color text-center py-5 size-48'>FINAL RESULT</h1>
              {outcomes !== null && <GameFinalStatistic outcomes={outcomes} gameResult={gameResult} />}
              <div className='next-round size-24 game-1 mt-5' onClick={() => navigate('/')}>
                Next Game
              </div>
            </Fragment>
          )}
        </div>
      </div>
    </DashboardLayout>
  );
};

export default GameFinal;
