import React, { useState, useEffect } from "react";
import { jumpTo } from "../../utils/helpers";

const BackToTopBtn = () => {
  const [active, setActive] = useState(false);
  const checkYOffset = () => {
    if (window.pageYOffset > 400) {
      setActive(true);
    } else {
      setActive(false);
    }
  };
  useEffect(() => {
    window.addEventListener("scroll", checkYOffset);
    return () => window.removeEventListener("scroll", checkYOffset);
  }, []);
  return (
    <div className='back-to-top-btn'>
      <div
        className={`back_btn ${active === true && "active"}`}
        onClick={() => {
          setActive(false);
          jumpTo("body");
        }}
      >
        <i className='fa fa-arrow-up'></i>
      </div>
    </div>
  );
};

export default BackToTopBtn;
