import React from 'react';
import { useDispatch } from 'react-redux';
import { userSetBuyOption } from '../../redux/user/userActions';

interface BuyButtonProps {
  text?: string;
  openBuyOptions?: boolean;
  classNameExtension?: string;
  onClick?: any;
}

const BuyButton: React.FC<BuyButtonProps> = ({
  text = 'Buy Now',
  openBuyOptions = true,
  classNameExtension = '',
  onClick = null,
}) => {
  const dispatch = useDispatch();
  return (
    <div className={`buy-button ${classNameExtension}`}>
      <button
        className='buy-button-btn'
        onClick={() => {
          if (onClick === null) {
            if (openBuyOptions) {
              dispatch(userSetBuyOption(true));
            }
          } else {
            onClick();
          }
        }}
      >
        {text}
      </button>
    </div>
  );
};

export default BuyButton;
