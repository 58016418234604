import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { gameSetDefaultMove } from '../../redux/game/GameActions';

import { setLoader, setMessage } from '../../redux/handler/handlerActions';
import { RootState } from '../../redux/store';

import { apiGet, apiPut } from '../../utils/helpers';
import { cardsGetScores } from '../Cards/CardsService';
import GameLayout from './GameLayout';
import GameOutcome from './GameOutcome';

interface GameShowdownProps {}

const GameShowdown: React.FC<GameShowdownProps> = () => {
  const dispatch = useDispatch();
  const { user, game } = useSelector((state: RootState) => state);
  const navigate = useNavigate();
  const [state, setState] = useState({
    opponentCard: null,
    scoresOpponent: [0, 0, 0, 0, 0, 0, 0],
    ownCard: null,
    scoresOwn: [0, 0, 0, 0, 0, 0, 0],
    round: 0,
    outcome: '',
    move: '',
    nextMove: false,
    isLoaded: false,
  });

  const [seconds, setSeconds] = useState(12);

  useEffect(() => {
    const getTimer = async () => {
      const { isError, data } = await apiGet('/timer_api');
      if (!isError) {
        if (data.rest_seconds_showdown > 0) {
          setSeconds(data.rest_seconds_showdown);
        } else {
          navigate('/arena');
        }
      }
    };
    const getRound = async () => {
      dispatch(gameSetDefaultMove());
      const { isError, data, text } = await apiGet('/rounds_api');
      await apiPut('/rounds_api/round_seen');
      if (isError) {
        dispatch(setMessage(text));
      } else {
        if (data.round !== 5) {
          await getTimer();
        }
        setState({
          opponentCard: data.opponent_card,
          scoresOpponent: cardsGetScores(data.opponent_card),
          ownCard: data.own_card,
          scoresOwn: cardsGetScores(data.own_card),
          round: data.round,
          outcome: data.outcome,
          move: data.move,
          nextMove: data.next_move,
          isLoaded: true,
        });
      }
    };

    getRound();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [game.round]);

  useEffect(() => {
    if (user.lastRoundSeen) {
      dispatch(setLoader(false));
    } else {
      dispatch(setLoader(true));
    }
    const interval = setInterval(() => {
      if (user.lastRoundSeen && state.isLoaded) {
        if (seconds > 0) {
          setSeconds((seconds) => seconds - 1);
        } else {
          if (state.round === 5) {
            navigate('/final');
          } else {
            navigate('/arena');
          }
        }
      }
    }, 1000);

    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [seconds, user.lastRoundSeen, state.round, state.isLoaded]);

  return (
    <GameLayout>
      <div className='game-showdown'>
        <div className='row'>
          <div className='col-3 flex-center'>
            <div className='nordic-logo'>
              <img
                className='nordic-logo-img'
                src='/img/logos/nordic-game-logo-corp.png'
                alt='Logo of the Nordic Games'
              />
            </div>
          </div>
          <div className='col-6'>
            <div className='white-color text-center pb-3 size-32 mt-3'> ROUND {state.round !== 0 && state.round}</div>
          </div>
          <div className='col-3'>
            <div className='game-name size-32 game-1 mt-3'>1 vs 1</div>
          </div>
        </div>
        <GameOutcome state={state} />

        {user.lastRoundSeen && (
          <div className='row pt-3'>
            <div className='col-2'></div>
            <div className='col-8'>
              {state.round === 5 && <div className='text-center primary-color size-32 pt-5'>THE GAME IS OVER</div>}
              <div
                className='next-round size-24 game-1 mt-5'
                onClick={() => {
                  if (state.round === 5) {
                    navigate('/final');
                  } else {
                    navigate('/arena');
                  }
                }}
              >
                {state.round !== 5 && <span className='primary-color mx-3'> {seconds}s</span>}
                {state.round === 5 ? ' SHOW GAME RESULT' : '  NEXT ROUND'}
              </div>
            </div>

            <div className='col-2'></div>
          </div>
        )}
      </div>
    </GameLayout>
  );
};

export default GameShowdown;
