import { useEffect, useState } from "react";

import {
  PROPERTY_AAF,
  PROPERTY_AF,
  PROPERTY_BK,
  PROPERTY_DEV,
  PROPERTY_PIL,
  PROPERTY_RSI,
  PROPERTY_SI,
  SCHEMA_COMMON,
  SCHEMA_EPIC,
  SCHEMA_RARE,
} from "../../Constants";

export const cardsGetScores = (data: any) => {
  if (data.immutable_data.AAF_score) {
    return [
      data.immutable_data.AAF_score,
      data.immutable_data.PIL_score,
      data.immutable_data.BK_score,
      data.immutable_data.SI_score,
      data.immutable_data.AF_score,
      data.immutable_data.DEV_score,
      data.immutable_data.RSI_score,
    ];
  } else {
    // when card with asset_id
    return [
      data.data.AAF_score,
      data.data.PIL_score,
      data.data.BK_score,
      data.data.SI_score,
      data.data.AF_score,
      data.data.DEV_score,
      data.data.RSI_score,
    ];
  }
};

export const cardsGetOneScore = (data: any, property: string) => {
  var scores = [];
  if (data.immutable_data.AAF_score) {
    scores = [
      data.immutable_data.AAF_score,
      data.immutable_data.PIL_score,
      data.immutable_data.BK_score,
      data.immutable_data.SI_score,
      data.immutable_data.AF_score,
      data.immutable_data.DEV_score,
      data.immutable_data.RSI_score,
    ];
  } else {
    // when card with asset_id
    scores = [
      data.data.AAF_score,
      data.data.PIL_score,
      data.data.BK_score,
      data.data.SI_score,
      data.data.AF_score,
      data.data.DEV_score,
      data.data.RSI_score,
    ];
  }

  switch (property) {
    case PROPERTY_AAF:
      return scores[0];
    case PROPERTY_PIL:
      return scores[1];
    case PROPERTY_BK:
      return scores[2];
    case PROPERTY_SI:
      return scores[3];
    case PROPERTY_AF:
      return scores[4];
    case PROPERTY_DEV:
      return scores[5];
    case PROPERTY_RSI:
      return scores[6];

    default:
      return "";
  }
};

export const cardsGetCurrentSchema = (templateId: string, schema: string, cards: any) => {
  var result = null;
  if (schema === SCHEMA_COMMON) {
    result = cards.common.find((x: any) => x.template_id === templateId);
  } else if (schema === SCHEMA_RARE) {
    result = cards.rare.find((x: any) => x.template_id === templateId);
  } else if (schema === SCHEMA_EPIC) {
    result = cards.epic.find((x: any) => x.template_id === templateId);
  }
  return result;
};

export const useCountCards = (templates: any, user_id: string, cardElement: any) => {
  const [count, setCount] = useState<number[]>([0, 0, 0]);
  useEffect(() => {
    if (templates && user_id !== "" && cardElement) {
      const x = templates.find((x: any) => x.template_id === cardElement.Common.template_id);
      const y = templates.find((x: any) => x.template_id === cardElement.Rare.template_id);
      const z = templates.find((x: any) => x.template_id === cardElement.Epic.template_id);
      var val0 = 0;
      var val1 = 0;
      var val2 = 0;
      if (user_id !== "") {
        if (x) {
          val0 = x.assets;
        }
        if (y) {
          val1 = y.assets;
        }
        if (z) {
          val2 = z.assets;
        }
        setCount([val0, val1, val2]);
      } else {
        setCount([0, 0, 0]);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [templates, user_id, cardElement]);
  return count;
};
