export enum GameActionType {
  REQUEST_START = 'GAME_REQUEST_START',
  REQUEST_FAIL = 'GAME_REQUEST_FAIL',
  DEFAULT_CURRENT_STATES = 'GAME_DEFAULT_CURRENT_STATES',
  ENTER_GAME = 'GAME_ENTER_GAME',
  SET_CURRENT_CARD = 'GAME_SET_CURRENT_CARD',
  SET_CURRENT_PROPERTY = 'GAME_SET_CURRENT_PROPERTY',
  SET_CURRENT_OPPONENT_CARD = 'GAME_SET_CURRENT_OPPONENT_CARD',
  SET_DEFAULT_MOVE = 'GAME_SET_DEFAULT_MOVE',
  NEXT = 'GAME_NEXT',
  GET_STATUS = 'GAME_GET_STATUS',
  GET_INFO = 'GAME_GET_INFO',
  SET_SEEN = 'GAME_SET_SEEN',
  END_GAME = 'GAME_END_GAME',
  LOGOUT = 'GAME_LOGOUT',
}

interface REQUEST_START {
  type: GameActionType.REQUEST_START;
  payload?: null;
}

interface REQUEST_FAIL {
  type: GameActionType.REQUEST_FAIL;
  payload?: null;
}
interface LOGOUT {
  type: GameActionType.LOGOUT;
  payload?: null;
}

interface GET_NEXT {
  type: GameActionType.NEXT;
  payload: { data: any; postiton: number };
}
interface DEFAULT_CURRENT_STATES {
  type: GameActionType.DEFAULT_CURRENT_STATES;
  payload: any;
}

interface ENTER_GAME {
  type: GameActionType.ENTER_GAME;
  payload: any;
}

interface SET_CURRENT_CARD {
  type: GameActionType.SET_CURRENT_CARD;
  payload: number;
}

interface SET_CURRENT_OPPONENT_CARD {
  type: GameActionType.SET_CURRENT_OPPONENT_CARD;
  payload: number;
}

interface SET_CURRENT_PROPERTY {
  type: GameActionType.SET_CURRENT_PROPERTY;
  payload: string;
}

interface SET_DEFAULT_MOVE {
  type: GameActionType.SET_DEFAULT_MOVE;
}

interface GET_STATUS {
  type: GameActionType.GET_STATUS;
  payload: any;
}

interface GET_INF0 {
  type: GameActionType.GET_INFO;
  payload: any;
}

interface SET_SEEN {
  type: GameActionType.SET_SEEN;
  payload: any;
}

interface END_GAME {
  type: GameActionType.END_GAME;
  payload: any;
}

export type GameActionModel =
  | REQUEST_START
  | REQUEST_FAIL
  | GET_NEXT
  | DEFAULT_CURRENT_STATES
  | LOGOUT
  | SET_CURRENT_CARD
  | SET_CURRENT_PROPERTY
  | SET_CURRENT_OPPONENT_CARD
  | SET_DEFAULT_MOVE
  | GET_STATUS
  | GET_INF0
  | SET_SEEN
  | END_GAME
  | ENTER_GAME;
