import React, { useEffect, useRef, useState } from 'react';

import { NFT_SOURCE_URL, ROUND_LOST, ROUND_TIE, ROUND_WON } from '../../Constants';

import { useWindowFocus } from '../../utils/customHooks';
import ContentLoader from 'react-content-loader';
import { isSafari } from '../../utils/helpers';

interface CardShowdownProps {
  card: any;
  outcome: string;
  own: boolean;
}

const CardShowdown: React.FC<CardShowdownProps> = ({ card, outcome, own }) => {
  const [videoLoaded, setVideoLoaded] = useState<boolean>(false);
  const [interOutcome, setInterOutcome] = useState('');
  const windowFocus = useWindowFocus();

  const videoRef = useRef<HTMLVideoElement>(null);

  useEffect(() => {
    if (windowFocus && videoLoaded && videoRef.current && isSafari()) {
      videoRef.current.play();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [windowFocus]);

  useEffect(() => {
    if (outcome !== '') {
      if (own) {
        setInterOutcome(outcome);
      } else {
        if (outcome === ROUND_LOST) {
          setInterOutcome(ROUND_WON);
        } else if (outcome === ROUND_WON) {
          setInterOutcome(ROUND_LOST);
        } else {
          setInterOutcome(outcome);
        }
      }
    }
  }, [outcome, own]);

  return (
    <div
      className={`card-showdown ${interOutcome === ROUND_LOST && 'out'} ${interOutcome === ROUND_WON && 'won'}  ${
        interOutcome === ROUND_TIE && 'tie'
      }`}
    >
      <div className='video-container py-3'>
        <div className={`text-center pb-4 card-name white-color`}>
          - {own === true && 'YOUR CARD'} {own === false && 'OPPONENT CARD'} -
        </div>
        <div className='mint-number'>#{card.template_mint}</div>

        <video
          ref={videoRef}
          src={NFT_SOURCE_URL + card.data.video}
          className={`card-video ${!videoLoaded && 'not-loaded'}`}
          playsInline
          autoPlay
          muted
          onMouseOver={(event: any) => event.target.play()}
          onLoadedData={() => setVideoLoaded(true)}
        ></video>

        {!videoLoaded && (
          <ContentLoader width={180} height={320} backgroundColor='#14192e' foregroundColor='#1a203c'>
            <rect x='1' y='1' rx='1' ry='1' width='180' height='250' />
          </ContentLoader>
        )}
      </div>
    </div>
  );
};

export default CardShowdown;
