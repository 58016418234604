import React, { Fragment, useRef, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import { userSetBuyOption } from '../../redux/user/userActions';
import BuyButton from './BuyButton';
import axios from 'axios';
import { axiosErrorText } from '../../utils/helpers';

export interface BuyOptionsState {}

interface BuyOptionsProps {}
const BuyOptions: React.FC<BuyOptionsProps> = () => {
  const [links, setLinks] = useState<any | null>(null);
  useEffect(() => {
    const fct = async () => {
      try {
        const { data } = await axios.get(`/pages_api/get_links`);
        setLinks(data.result);
      } catch (error) {
        axiosErrorText(error);
      }
    };
    fct();
  }, []);

  const dispatch = useDispatch();
  const user = useSelector((state: RootState) => state.user);
  const { isBuyOptions } = user;
  const messageRef0 = useRef<HTMLDivElement>(null);
  const messageRef1 = useRef<HTMLDivElement>(null);
  const messageRef2 = useRef<HTMLDivElement>(null);

  useEffect(() => {
    let handler = (e: any) => {
      if (
        !messageRef1.current?.contains(e.target) ||
        !messageRef0.current?.contains(e.target) ||
        !messageRef2.current?.contains(e.target)
      ) {
        // dispatch(userSetBuyOption(false));
      } else {
        // dispatch(userSetBuyOption(false));
      }
    };
    document.addEventListener('mousedown', handler);
    return () => {
      document.removeEventListener('mousedown', handler);
    };
  });
  const remove = () => {
    dispatch(userSetBuyOption(false));
  };

  return (
    <Fragment>
      <div className={`overlay-message ${isBuyOptions && 'active'}`}></div>{' '}
      <div className={`message buy-options ${isBuyOptions && 'active'}`} onClick={remove}>
        <div ref={messageRef0} className='content pt-3'>
          <div className='close'>
            <i className='far fa-times-circle' onClick={remove}></i>
          </div>
          {links && (
            <div className='buy-options-container'>
              <div className='one-el'>
                <div className='img-cont mb-4'>
                  <img
                    onClick={() => window.open(links.BUY_LINK_NEFTY, '_blank')}
                    className='img-buy'
                    src='/img/partner/logo-nefty.svg'
                    alt='Logo Nefty - Partner from Lost Treasures Galaxy'
                  />
                </div>
                <div className='text-center pt-4' onClick={() => window.open(links.BUY_LINK_NEFTY, '_blank')}>
                  <BuyButton text='Buy on NeftyBlocks' openBuyOptions={false} classNameExtension='btn-in-option' />
                </div>
              </div>
              <div className='one-el'>
                <div className='img-cont mb-4'>
                  <img
                    onClick={() => window.open(links.BUY_LINK_ATOMIC_HUB, '_blank')}
                    className='img-buy atomic-img'
                    src='/img/partner/atomicHub.svg'
                    alt='Logo AtomicHub - Partner from Lost Treasures Galaxy'
                  />
                </div>
                <div className='text-center pt-4' onClick={() => window.open(links.BUY_LINK_ATOMIC_HUB, '_blank')}>
                  <BuyButton text='Buy on AtomicHub' openBuyOptions={false} classNameExtension='btn-in-option' />
                </div>
              </div>
              <div className='one-el'>
                <div className='img-cont mb-4'>
                  <img
                    onClick={() => window.open(links.BUY_LINK_NFTHIVE, '_blank')}
                    className='img-buy'
                    src='/img/partner/nfthive-logo.svg'
                    alt='Logo NFTHive - Partner from Lost Treasures Galaxy'
                  />
                </div>
                <div className='text-center pt-4' onClick={() => window.open(links.BUY_LINK_NFTHIVE, '_blank')}>
                  <BuyButton text='Buy on NFTHive' openBuyOptions={false} classNameExtension='btn-in-option' />
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </Fragment>
  );
};

export default BuyOptions;
