export const IS_LOADING_REDUX_STATE = 'isLoading';
export const PROJECT_NAME = 'NORDICGAME';

export const BREAKPOINT_BIG_SCREEN = 1350;

export const NFT_SOURCE_URL = 'https://ltg.mypinata.cloud/ipfs/'; //'https://ipfs.atomichub.io/ipfs/'

export const URL_LTG_WEBSITE = 'https://lost-treasures-galaxy.io';
export const URL_KONVEX_WEBSITE = 'https://konvex-it.de/';

// Schema Name
export const SCHEMA_COMMON = 'nlsoneanicom';
export const SCHEMA_RARE = 'nlsoneanirar';
export const SCHEMA_EPIC = 'nlsoneaniepi';
export const SCHEMA_LTG_COIN = 'ltgcoin';
export const SCHEMA_NL_COIN = 'nlcoin';

// user Status
export const USER_STATUS_WAX = 'USER_STATUS_WAX';
export const USER_STATUS_ANCHOR = 'USER_STATUS_ANCHOR';

export const DRAG_DROP_FROM_SCROLL = 'DRAG_DROP_FROM_SCROLL';

// Properties
export const PROPERTY_AAF = 'PROPERTY_AAF';
export const PROPERTY_PIL = 'PROPERTY_PIL';
export const PROPERTY_BK = 'PROPERTY_BK';
export const PROPERTY_SI = 'PROPERTY_SI';
export const PROPERTY_AF = 'PROPERTY_AF';
export const PROPERTY_DEV = 'PROPERTY_DEV';
export const PROPERTY_RSI = 'PROPERTY_RSI';

// GAME STATUS
export const GAME_WAITING = 'GAME_WAITING';
export const GAME_WAITING_CONTRACTS_BOTH = 'GAME_WAITING_CONTRACTS_BOTH';
export const GAME_WAITING_CONTRACTS_OWN = 'GAME_WAITING_CONTRACTS_OWN';
export const GAME_WAITING_CONTRACTS_OWN_CONFIRMED = 'GAME_WAITING_CONTRACTS_OWN_CONFIRMED';
export const GAME_WAITING_CONTRACTS_OPPONENT = 'GAME_WAITING_CONTRACTS_OPPONENT';
export const GAME_PLAYING = 'GAME_PLAYING';
export const GAME_END = 'GAME_END';
export const GAME_LEFT = 'GAME_LEFT';
export const GAME_NON_EXIST = 'GAME_NON_EXIST';

export const GAME_WAITING_STATES = [
  GAME_WAITING,
  GAME_WAITING_CONTRACTS_BOTH,
  GAME_WAITING_CONTRACTS_OWN_CONFIRMED,
  GAME_WAITING_CONTRACTS_OWN,
  GAME_WAITING_CONTRACTS_OPPONENT,
];

//ROUND RESULTS
export const ROUND_TIE = 'ROUND_TIE';
export const ROUND_WON = 'ROUND_WON';
export const ROUND_LOST = 'ROUND_LOST';

//GAME RESULTS
export const GAME_RESULT_TIE = 'GAME_RESULT_TIE';
export const GAME_RESULT_WON = 'GAME_RESULT_WON';
export const GAME_RESULT_LOST = 'GAME_RESULT_LOST';

export const PLAYER_TYPE_NONE = 'PLAYER_TYPE_NONE';
export const PLAYER_TYPE_A = 'PLAYER_TYPE_A';
export const PLAYER_TYPE_B = 'PLAYER_TYPE_B';

// TIMERSTATUS
export const TIMER_NONE = 'TIMER_NONE';
export const TIMER_RUNNING = 'TIMER_RUNNING';
export const TIMER_OVER_OWN = 'TIMER_OVER_OWN';
export const TIMER_OVER_OPPONENT = 'TIMER_OVER_OPPONENT';
