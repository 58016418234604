import axios from 'axios';
import { Dispatch } from 'react';
import { RootState } from '../store';

import { ContractActionModel, ContractActionType } from './contractModel';
import { HandlerActionType } from '../handler/handlerModel';
import { axiosErrorText } from '../../utils/helpers';
import { GAME_WAITING_CONTRACTS_OWN_CONFIRMED, USER_STATUS_WAX } from '../../Constants';

import { UserActionType } from '../user/userModel';

import * as waxjs from '@waxio/waxjs/dist';
import AnchorLink from 'anchor-link';
import AnchorLinkBrowserTransport from 'anchor-link-browser-transport';

export const contractTransactionUser = () => async (dispatch: Dispatch<any>, getState: () => RootState) => {
  try {
    dispatch({
      type: ContractActionType.REQUEST_START,
    });
    const url = '/contracts_api/start_transfer';
    const { data } = await axios.post(url);

    const transaction_user_id = data.result.transaction_user_id;
    const action = [
      {
        account: 'atomicassets',
        name: 'transfer',
        authorization: [
          {
            actor: data.result.user_id,
            permission: 'active',
          },
        ],
        data: {
          from: data.result.user_id,
          to: data.result.receiver,
          asset_ids: data.result.asset_ids,
          memo: '',
        },
      },
    ];

    if (data.result.user_status === USER_STATUS_WAX) {
      const wax = new waxjs.WaxJS({
        rpcEndpoint: 'https://wax.greymass.com',
        userAccount: data.result.user_id,
        pubKeys: data.result.user_keys,
        tryAutoLogin: false,
      });

      await wax.login();
      const result = await wax.api.transact(
        {
          actions: action,
        },
        {
          blocksBehind: 3,
          expireSeconds: 1200,
        }
      );

      const transferSuccess = await transferSuccessUser(transaction_user_id, result);
      if (!transferSuccess) {
        dispatch({
          type: ContractActionType.REQUEST_FAIL,
        });
        dispatch({
          type: HandlerActionType.SET_MESSAGE,
          payload: { message: 'THERE HAVE BEEN PROBLEMS WITH YOUR TRANSFER....', isHTML: true },
        });
      } else {
        dispatch({
          type: UserActionType.UPDATE_GAME_STATUS,
          payload: {
            gameStatus: GAME_WAITING_CONTRACTS_OWN_CONFIRMED,
            lastRoundSeen: true,
          },
        });
      }
    } else {
      const transport = new AnchorLinkBrowserTransport();
      const link = new AnchorLink({
        transport,
        chains: [
          {
            chainId: '1064487b3cd1a897ce03ae5b6a865651747e2e152090f99c1d19d44e01aea5a4',
            nodeUrl: 'https://wax.greymass.com',
          },
        ],
      });
      var session = await link.restoreSession('mydapp');
      if (!session) {
        const identity = await link.login('mydapp');

        if (identity.session.auth) {
          session = identity.session;
        } else {
          console.log('error anchor login');
        }
      } else {
        if (String(session.auth.actor) !== data.result.user_id) {
          const identity = await link.login('mydapp');

          if (identity.session.auth) {
            session = identity.session;
          } else {
            console.log('error anchor login');
          }
        }
      }
      if (session) {
        const response = await session.transact({
          actions: action,
        });

        const transferSuccess = await transferSuccessUser(transaction_user_id, response.transaction.id);
        if (!transferSuccess) {
          dispatch({
            type: ContractActionType.REQUEST_FAIL,
          });
          dispatch({
            type: HandlerActionType.SET_MESSAGE,
            payload: { message: 'THERE HAVE BEEN PROBLEMS WITH YOUR TRANSFER....', isHTML: true },
          });
        } else {
          dispatch({
            type: UserActionType.UPDATE_GAME_STATUS,
            payload: {
              gameStatus: GAME_WAITING_CONTRACTS_OWN_CONFIRMED,
              lastRoundSeen: true,
            },
          });
        }
      }
    }
  } catch (error) {
    var errorText = 'THE TRANSFER WAS CANCELLED....';
    if (error instanceof Error) {
      if (error.message.trim() === 'Unable to open popup window') {
        errorText = 'PLEASE ALLOW IN YOUR BROWSER TO OPEN A POPUP WINDOW!';
      }
    }

    await transferFailerUser(error);
    dispatch({
      type: ContractActionType.REQUEST_FAIL,
    });
    dispatch({
      type: HandlerActionType.SET_MESSAGE,
      payload: { message: axiosErrorText(error, errorText), isHTML: true },
    });
    defaultLocalStorage();
  }
  dispatch({
    type: ContractActionType.SHOW_TRANSFER_NOW,
    payload: false,
  });
};

export const contractShowTransferNow = (payload: boolean) => async (dispatch: Dispatch<ContractActionModel>) => {
  dispatch({
    type: ContractActionType.SHOW_TRANSFER_NOW,
    payload: payload,
  });
};

const transferSuccessUser = async (transaction_user_id = '', log: any) => {
  try {
    const url = '/contracts_api/transfer_success_user';
    const reqData = {
      transaction_user_id: transaction_user_id,
      log: log,
    };
    await axios.post(url, reqData);
    return true;
  } catch (error) {
    return false;
  }
};

const transferFailerUser = async (error: any) => {
  try {
    const url = '/contracts_api/transfer_failer_user';
    var log = 'Error transferFailerUser()';
    if (error instanceof Error) {
      if (error.message.trim() === 'Unable to open popup window') {
        log = error.message.trim();
      }
    }
    const reqData = {
      log: log,
    };
    await axios.post(url, reqData);
    return true;
  } catch (error) {
    return false;
  }
};

const defaultLocalStorage = () => {
  // setSessionStorage("user.id", defaultState.user.id);
  // setSessionStorage("user.gameStatus", defaultState.user.gameStatus);
  // setSessionStorage("user.isLogged", defaultState.user.isLogged);
  // setSessionStorage("game.deck", defaultState.game.deck);
  // setSessionStorage("deck.chosen", defaultState.deck.chosen);
};
