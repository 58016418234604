import { HandlerActionType, HandlerActionModel } from "./handlerModel";
import axios from "axios";
import { Dispatch } from "redux";
import { axiosErrorText, setSessionStorage } from "../../utils/helpers";
import { RootState } from "../store";
// import { axiosErrorText } from "../utils/Helpers";

export const setLoader = (bool: boolean) => async (dispatch: Dispatch<HandlerActionModel>) => {
  dispatch({ type: HandlerActionType.SET_LOADER, payload: bool });
};

export const setCookieStatus =
  (bool: boolean) => async (dispatch: Dispatch<HandlerActionModel>, getState: () => RootState) => {
    dispatch({ type: HandlerActionType.SET_COOKIE_STATUS, payload: bool });
    setSessionStorage("handlers.cookieStatus", getState().handlers.cookieStatus);
  };

export const setMessage =
  (message: String, isHTML = false) =>
  async (dispatch: Dispatch) => {
    dispatch({
      type: HandlerActionType.SET_MESSAGE,
      payload: { message: message, isHTML: isHTML },
    });
  };

export const removeMessage = () => async (dispatch: Dispatch<HandlerActionModel>) => {
  dispatch({ type: HandlerActionType.REMOVE_MESSAGE, payload: null });
};

export const setErrorAxios = (error: any) => (dispatch: Dispatch<HandlerActionModel>) => {
  axiosErrorText(error);
  dispatch({
    type: HandlerActionType.SET_MESSAGE,
    payload: { message: axiosErrorText(error), isHTML: true },
  });
};

export const handlerGetTextServer = (type: string) => async (dispatch: Dispatch<HandlerActionModel>) => {
  try {
    const { data } = await axios.get("/views_api/get_text?type=" + type);

    dispatch({
      type: HandlerActionType.SET_MESSAGE,
      payload: { message: data.text, isHTML: true },
    });
  } catch (error) {
    const errorText = axiosErrorText(error);
    dispatch({
      type: HandlerActionType.SET_MESSAGE,
      payload: { message: errorText, isHTML: true },
    });
  }
};
