import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import HoverTippy from '../../components/gadgets/HoverTippy';
import {
  PROPERTY_AAF,
  PROPERTY_AF,
  PROPERTY_BK,
  PROPERTY_DEV,
  PROPERTY_PIL,
  PROPERTY_RSI,
  PROPERTY_SI,
} from '../../Constants';
import { GameSetCurrentProperty } from '../../redux/game/GameActions';
import { RootState } from '../../redux/store';
import { setHoverText } from '../../utils/NordicHelpers';
import { cardsGetOneScore } from '../Cards/CardsService';
import {
  allianzSrc,
  attackSrc,
  defenceSrc,
  importanceSrc,
  influenceSrc,
  karmaSrc,
  strategySrc,
} from '../../utils/NordicHelpers';
import { useDemand } from './GameService';

interface GamePropertiesProps {}

const GameProperties: React.FC<GamePropertiesProps> = () => {
  const { game, user } = useSelector((state: RootState) => state);
  const { currentCard, currentProperty, deck } = game;
  const dispatch = useDispatch();
  const [score, setScore] = useState('');
  const [rarity, setRarity] = useState('');
  const [imgSrc, setImgSrc] = useState(0);

  const { shine } = useDemand(user, game);

  useEffect(() => {
    if (currentCard !== -1 && currentProperty !== '') {
      setRarity(deck[currentCard].data.rarity);
      setScore(cardsGetOneScore(deck[currentCard], currentProperty));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentCard, currentProperty]);

  useEffect(() => {
    if (currentCard !== -1) {
      setRarity(deck[currentCard].data.rarity);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentCard]);
  useEffect(() => {
    if (rarity === 'Common') {
      setImgSrc(0);
    }
    if (rarity === 'Rare') {
      setImgSrc(1);
    }
    if (rarity === 'Epic') {
      setImgSrc(2);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rarity]);

  return (
    <div className={`game-properties ${shine === 'STRATEGY' && 'active'}`}>
      <div className='content'>
        <div className='font-family-1 size-18 text-center white-color pb-3 pt-2'>
          {score === '' ? (
            <span>choose your strategy</span>
          ) : (
            <span className={`size-32 ${currentProperty === PROPERTY_BK ? 'red-color' : 'primary-color'}`}>
              {score}
            </span>
          )}
        </div>
        <div className='one-row'>
          <div className='one-col pb-4' onClick={() => dispatch(GameSetCurrentProperty(PROPERTY_AAF))}>
            <HoverTippy
              hoverElement={<ImageContainer src={allianzSrc[imgSrc]} active={currentProperty === PROPERTY_AAF} />}
              classNameExtension='tippy-game-property'
            >
              <span>{setHoverText(PROPERTY_AAF)}</span>
            </HoverTippy>
          </div>
          <div className='one-col pb-4' onClick={() => dispatch(GameSetCurrentProperty(PROPERTY_PIL))}>
            <HoverTippy
              hoverElement={<ImageContainer src={influenceSrc[imgSrc]} active={currentProperty === PROPERTY_PIL} />}
              classNameExtension='tippy-game-property'
            >
              <span>{setHoverText(PROPERTY_PIL)}</span>
            </HoverTippy>
          </div>
        </div>
        <div className='one-row'>
          <div className='one-col pb-4' onClick={() => dispatch(GameSetCurrentProperty(PROPERTY_BK))}>
            <HoverTippy
              hoverElement={<ImageContainer src={karmaSrc[imgSrc]} active={currentProperty === PROPERTY_BK} />}
              classNameExtension='tippy-game-property'
            >
              <span>{setHoverText(PROPERTY_BK)}</span>
            </HoverTippy>
          </div>
          <div className='one-col pb-4' onClick={() => dispatch(GameSetCurrentProperty(PROPERTY_SI))}>
            <HoverTippy
              hoverElement={<ImageContainer src={strategySrc[imgSrc]} active={currentProperty === PROPERTY_SI} />}
              classNameExtension='tippy-game-property'
            >
              <span>{setHoverText(PROPERTY_SI)}</span>
            </HoverTippy>
          </div>
        </div>
        <div className='one-row'>
          <div className='one-col pb-4' onClick={() => dispatch(GameSetCurrentProperty(PROPERTY_AF))}>
            <HoverTippy
              hoverElement={<ImageContainer src={attackSrc[imgSrc]} active={currentProperty === PROPERTY_AF} />}
              classNameExtension='tippy-game-property'
            >
              <span>{setHoverText(PROPERTY_AF)}</span>
            </HoverTippy>
          </div>
          <div className='one-col pb-4' onClick={() => dispatch(GameSetCurrentProperty(PROPERTY_DEV))}>
            <HoverTippy
              hoverElement={<ImageContainer src={defenceSrc[imgSrc]} active={currentProperty === PROPERTY_DEV} />}
              classNameExtension='tippy-game-property'
            >
              <span>{setHoverText(PROPERTY_DEV)}</span>
            </HoverTippy>
          </div>
        </div>
        <div className='one-row'>
          <div className='one-col one-col-last' onClick={() => dispatch(GameSetCurrentProperty(PROPERTY_RSI))}>
            <HoverTippy
              hoverElement={<ImageContainer src={importanceSrc[imgSrc]} active={currentProperty === PROPERTY_RSI} />}
              classNameExtension='tippy-game-property'
            >
              <span>{setHoverText(PROPERTY_RSI)}</span>
            </HoverTippy>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GameProperties;

interface ImageContainerProps {
  src: string;
  active?: boolean;
}
const ImageContainer: React.FC<ImageContainerProps> = ({ src, active = false }) => {
  return (
    <div className={`img-container flex-center ${active && 'active'}`}>
      <img src={src} alt='' className='img-property' />
    </div>
  );
};
