import React from 'react';
import { Routes, Route } from 'react-router-dom';

import NotFound from '../app/NotFound/NotFound';
import WalletLayout from '../app/Wallet/WalletLayout';
import GameShowdown from '../app/Game/GameShowdown';
import GameArena from '../app/Game/GameArena';
import GameFinal from '../app/Game/GameFinal';
import PrivateRoute from '../utils/PrivateRoute';

import DashboardRules from '../app/Dashboard/DashboardRules';
import DashboardSelection from '../app/Dashboard/DashboardSelection';

const MainRoutes: React.FC = () => {
  return (
    <div>
      <Routes>
        <Route
          path=''
          element={
            <PrivateRoute
              role='DASHBOARD'
              component={DashboardSelection}
              screenSize='LARGE'
              title='DASHBOARD - NORDIC LEGEND THE GAME'
            />
          }
        />
        <Route
          path='/arena'
          element={<PrivateRoute role='ARENA' component={GameArena} screenSize='LARGE' title='NORDIC LEGEND ARENA' />}
        />
        <Route
          path='/showdown'
          element={
            <PrivateRoute
              role='SHOWDOWN'
              component={GameShowdown}
              screenSize='LARGE'
              title='NL SHOWDOWN - WHO WON THE ROUND ?'
            />
          }
        />
        <Route
          path='/final'
          element={
            <PrivateRoute
              role='FINAL'
              component={GameFinal}
              screenSize='LARGE'
              title='GAME RESULT -NORDIC LEGEND THE GAME'
            />
          }
        />
        <Route
          path='/wallet'
          element={
            <PrivateRoute
              role='NO_RESTRICTION'
              component={WalletLayout}
              screenSize='ALL'
              title='NL WALLET by LOST TREASURES GALAXY'
            />
          }
        />
        <Route
          path='/rules'
          element={
            <PrivateRoute
              role='NO_RESTRICTION'
              component={DashboardRules}
              screenSize='ALL'
              title='NORDIC LEGEND THE GAME RULES'
            />
          }
        />
        <Route
          path='/*'
          element={
            <PrivateRoute
              role='NO_RESTRICTION'
              component={NotFound}
              screenSize='ALL'
              title='NORDIC LEGEND THE GAME - NOT FOUND'
            />
          }
        />
      </Routes>
    </div>
  );
};

export default MainRoutes;
