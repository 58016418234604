import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { GameGetStatus } from '../redux/game/GameActions';
import { userIsLogged } from '../redux/user/userActions';
import { apiGet } from './helpers';

export const useUserIsLogged = () => {
  const dispatch = useDispatch();
  const socketTime = 13000;
  useEffect(() => {
    const interval = setInterval(() => {
      dispatch(userIsLogged());
    }, socketTime);
    return () => {
      clearInterval(interval);
    };
    // eslint-disable-next-line
  }, []);
};

export const useGameStatus = () => {
  const dispatch = useDispatch();
  const socketTime = 3000;
  useEffect(() => {
    const interval = setInterval(() => dispatch(GameGetStatus()), socketTime);
    return () => {
      clearInterval(interval);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};

export const useIsWaiting = () => {
  const [isMessage, setIsMessage] = useState(false);
  const socketTime = 4000;
  useEffect(() => {
    const getSocket = async () => {
      const { isError, data } = await apiGet('/games_api/is-waiting');
      if (!isError) {
        setIsMessage(data);
      }
    };
    getSocket();
    const interval = setInterval(() => getSocket(), socketTime);
    return () => {
      clearInterval(interval);
    };
  }, []);

  return isMessage;
};
