import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useWindowSize } from './customHooks';
interface ScrollToTopProps {
  children: JSX.Element;
}
const ScrollToTop: React.FC<ScrollToTopProps> = ({ children }) => {
  const location = useLocation();
  const windowSize = useWindowSize();
  useEffect(() => {
    window.scrollTo(0, 0);

    // eslint-disable-next-line
  }, [windowSize.width, location]);
  return children;
};
export default ScrollToTop;
