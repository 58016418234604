import React, { Fragment, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PointsHorizontal from '../../components/nordic-points/PointsHorizontal';
import { DRAG_DROP_FROM_SCROLL, NFT_SOURCE_URL } from '../../Constants';
import { deckDelete, deckSetNext } from '../../redux/deck/DeckActions';
import { RootState } from '../../redux/store';
import { cardsGetCurrentSchema, cardsGetScores } from './CardsService';
import { useDrag, useDrop } from 'react-dnd';
import ContentLoader from 'react-content-loader';
import { useWindowFocus } from '../../utils/customHooks';
import { isSafari } from '../../utils/helpers';
interface CardChoosenProps {
  position: number;
}

const CardChoosen: React.FC<CardChoosenProps> = ({ position = 0 }) => {
  const dispatch = useDispatch();
  const [scores, setScores] = useState<any | null>(null);
  const { deck, cards } = useSelector((state: RootState) => state);
  const { chosen } = deck;
  const [videoLoaded, setVideoLoaded] = useState<boolean>(false);

  const [state, setState] = useState<any | null>(null);

  useEffect(() => {
    if (chosen[position] !== null) {
      const current = cardsGetCurrentSchema(chosen[position].templateId, chosen[position].schema, cards);
      if (current) {
        setScores(cardsGetScores(current));
        setState(current.immutable_data);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chosen[position], cards]);

  const windowFocus = useWindowFocus();
  const videoRef = useRef<HTMLDivElement>(null);

  const onDrop = (item: any) => {
    dispatch(deckSetNext(item.schema, item.templateId, position));
  };
  const accept = [DRAG_DROP_FROM_SCROLL];
  const [{ isOver, canDrop }, drop] = useDrop({
    accept,
    drop: onDrop,
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  });

  const [, drag] = useDrag(
    () => ({
      type: DRAG_DROP_FROM_SCROLL,
      item: { schema: chosen[position].schema, templateId: chosen[position].templateId },
      collect: (monitor) => ({
        isDragging: !!monitor.isDragging(),
      }),
      canDrag: chosen[position] !== null,
    }),
    [chosen]
  );

  const isActive = isOver && canDrop;

  useEffect(() => {
    if (windowFocus && videoLoaded && videoRef.current && isSafari()) {
      const video = videoRef.current.querySelector('video');
      if (video) {
        video.play();
      }
    }
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [windowFocus]);

  return (
    <div className='card-choosen' ref={videoRef}>
      {state && (
        <Fragment>
          <div className='video-container' ref={drop}>
            {canDrop === false && (
              <Fragment>
                <div
                  className='delete-icon'
                  onClick={() => {
                    dispatch(deckDelete(position));
                  }}
                >
                  <i className='fas fa-times'></i>
                </div>
                <div className='mint-number'>#{chosen[position].mint}</div>
              </Fragment>
            )}

            <video
              ref={drag}
              src={NFT_SOURCE_URL + state.video}
              className={`card-video ${isActive && 'active'} ${!videoLoaded && 'not-loaded'}`}
              playsInline
              autoPlay
              muted
              onMouseOver={(event: any) => event.target.play()}
              onLoadedData={() => setVideoLoaded(true)}
            ></video>
            {!videoLoaded && (
              <ContentLoader width={160} height={250} backgroundColor='#14192e' foregroundColor='#1a203c'>
                <rect x='1' y='1' rx='1' ry='1' width='160' height='250' />
              </ContentLoader>
            )}
          </div>
          <div className='py-4'>
            <PointsHorizontal
              scores={scores}
              showBar={true}
              schema={''}
              classNameExtension='points-horizontal--card-drag-drop'
            />
          </div>
        </Fragment>
      )}
    </div>
  );
};

export default CardChoosen;
