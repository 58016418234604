// import { setSessionStorage } from "../utils/ReduxHelpers";
import axios from 'axios';
import { Dispatch } from 'react';
import { GAME_LEFT } from '../../Constants';
import { axiosErrorText } from '../../utils/helpers';
import { DeckActionType } from '../deck/DeckModel';
import { HandlerActionType } from '../handler/handlerModel';

import { RootState } from '../store';
import { UserActionType } from '../user/userModel';
import { GameActionModel, GameActionType } from './GameModel';

export const GameEnterArena = () => async (dispatch: Dispatch<any>, getState: () => RootState) => {
  try {
    const url = '/games_api/create';

    const isReady =
      getState().deck.chosen[0] &&
      getState().deck.chosen[1] &&
      getState().deck.chosen[2] &&
      getState().deck.chosen[3] &&
      getState().deck.chosen[4];
    const isLogged = getState().user.isLogged;

    if (!isReady || !isLogged) {
      return;
    }

    dispatch({
      type: GameActionType.REQUEST_START,
    });
    const reqBody = {
      deck: getState().deck.chosen,
      friend: getState().deck.friend,
    };
    const { data } = await axios.post(url, reqBody);

    dispatch({
      type: DeckActionType.LOGOUT,
    });

    dispatch({
      type: UserActionType.UPDATE_GAME_STATUS,
      payload: { gameStatus: data.result.status, lastRoundSeen: true },
    });

    dispatch({
      type: GameActionType.ENTER_GAME,
      payload: null,
    });
  } catch (error) {
    const text = axiosErrorText(error);
    dispatch({
      type: HandlerActionType.SET_MESSAGE,
      payload: { message: text, isHTML: true },
    });
    dispatch({
      type: GameActionType.REQUEST_FAIL,
    });
  }
};

export const GameGetStatus = () => async (dispatch: Dispatch<any>, getState: () => RootState) => {
  try {
    const url = '/games_api/status';

    const { data } = await axios.get(url);
    if (data.result.status === GAME_LEFT && !data.result.is_player_left) {
      dispatch({
        type: HandlerActionType.SET_MESSAGE,
        payload: { message: 'Your opponent left the game....', isHTML: true },
      });
    }
    dispatch({
      type: GameActionType.GET_STATUS,
      payload: data.result,
    });

    dispatch({
      type: UserActionType.UPDATE_GAME_STATUS,
      payload: {
        gameStatus: data.result.status,
        timerStatus: data.result.timer_status,
        lastRoundSeen: data.result.last_round_seen,
      },
    });
  } catch (error) {
    dispatch({
      type: GameActionType.REQUEST_FAIL,
    });
  }
};

export const GameGetInfo = () => async (dispatch: Dispatch<GameActionModel>, getState: () => RootState) => {
  try {
    const url = '/games_api/info';

    const { data } = await axios.get(url);
    dispatch({
      type: GameActionType.GET_INFO,
      payload: data.result,
    });
  } catch (error) {
    dispatch({
      type: GameActionType.REQUEST_FAIL,
    });
  }
};

export const gameSetDefaultMove = () => async (dispatch: Dispatch<GameActionModel>, getState: () => RootState) => {
  dispatch({
    type: GameActionType.SET_DEFAULT_MOVE,
  });
};

export const GameSetCurrentCard =
  (position: number) => async (dispatch: Dispatch<GameActionModel>, getState: () => RootState) => {
    if (getState().game.nextMove === false) {
      return;
    }
    dispatch({
      type: GameActionType.SET_CURRENT_CARD,
      payload: position,
    });
  };

export const GameSetCurrentOppoenentCard =
  (position: number) => async (dispatch: Dispatch<GameActionModel>, getState: () => RootState) => {
    if (getState().game.nextMove === false) {
      return;
    }
    dispatch({
      type: GameActionType.SET_CURRENT_OPPONENT_CARD,
      payload: position,
    });
  };

export const GameSetCurrentProperty =
  (property: string) => async (dispatch: Dispatch<GameActionModel>, getState: () => RootState) => {
    if (getState().game.nextMove === false) {
      return;
    }
    dispatch({
      type: GameActionType.SET_CURRENT_PROPERTY,
      payload: property,
    });
  };

export const gameLeftGame = () => async (dispatch: Dispatch<any>, getState: () => RootState) => {
  try {
    dispatch({
      type: UserActionType.UPDATE_GAME_STATUS,
      payload: { gameStatus: GAME_LEFT, lastRoundSeen: true },
    });
    dispatch({
      type: GameActionType.END_GAME,
      payload: {},
    });
  } catch (error) {
    dispatch({
      type: GameActionType.REQUEST_FAIL,
      payload: {},
    });
  }
};
