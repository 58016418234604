import React from 'react';

interface ErrorTextProps {
  text: string;
}

const ErrorText: React.FC<ErrorTextProps> = ({ text }) => {
  return <small className={`error-text ${text !== '' && 'active'} `}>{text}</small>;
};

export default ErrorText;
