import axios from 'axios';
import { Dispatch } from 'react';
import { axiosErrorText, timeout } from '../../utils/helpers';
import { RootState } from '../store';
import { UserActionType, UserActionModel } from './userModel';

import { DeckActionModel, DeckActionType } from '../deck/DeckModel';

import { GameActionModel, GameActionType } from '../game/GameModel';
import { USER_STATUS_ANCHOR, USER_STATUS_WAX } from '../../Constants';

import * as waxjs from '@waxio/waxjs/dist';
import AnchorLink from 'anchor-link';
import AnchorLinkBrowserTransport from 'anchor-link-browser-transport';
import { HandlerActionType } from '../handler/handlerModel';

export const userLoginWallet = (walletType: string) => async (dispatch: Dispatch<any>, getState: () => RootState) => {
  try {
    const url = '/users_api/login';
    var reqData = {};

    if (walletType === USER_STATUS_WAX) {
      //  LOGIN WAX WALLET
      const wax = new waxjs.WaxJS({
        rpcEndpoint: 'https://wax.greymass.com',
      });
      await wax.login();

      if (wax.user) {
        reqData = {
          walletType: walletType,
          name: wax.user.account,
          keys: wax.user.keys,
        };
      } else {
        return;
      }
    } else if (walletType === USER_STATUS_ANCHOR) {
      // LOGIN ANCHOR
      const transport = new AnchorLinkBrowserTransport();
      const link = new AnchorLink({
        transport,
        chains: [
          {
            chainId: '1064487b3cd1a897ce03ae5b6a865651747e2e152090f99c1d19d44e01aea5a4',
            nodeUrl: 'https://wax.greymass.com',
          },
        ],
      });

      const identity = await link.login('mydapp');

      const { session, payload } = identity;
      if (session.auth) {
        reqData = {
          walletType: walletType,
          name: payload.sa,
          keys: '',
        };
      }
    } else {
      return;
    }

    dispatch({
      type: UserActionType.SET_LOGIN_OPTION,
      payload: false,
    });

    dispatch({ type: UserActionType.REQUEST_START });
    const { data } = await axios.post(url, reqData);
    dispatch({
      type: UserActionType.LOGIN_SUCCESS,
      payload: {
        id: data.id,
        userStatus: data.user_status,
        gameStatus: data.game_status,
        nlsoneanicom: data.result.nlsoneanicom,
        nlsoneanirar: data.result.nlsoneanirar,
        nlsoneaniepi: data.result.nlsoneaniepi,
        ltgcoin: data.result.ltgcoin,
        nlcoin: data.result.nlcoin,
        nordic_count: data.result.nordic_count,
        templates: data.result.templates,
      },
    });
  } catch (error) {
    axiosErrorText(error);
    if (error instanceof Error) {
      if (error.message.trim() === 'Unable to open popup window') {
        const errorText = 'PLEASE ALLOW IN YOUR BROWSER TO OPEN A POPUP WINDOW!';
        dispatch({
          type: HandlerActionType.SET_MESSAGE,
          payload: { message: errorText, isHTML: true },
        });
      }
    }
  }
};

export const userIsLogged = () => async (dispatch: Dispatch<UserActionModel>, getState: () => RootState) => {
  try {
    const url = '/users_api/load_user';
    const { data } = await axios.get(url);
    const payload = {
      id: data.id,
      isLogged: data.id === '' ? false : true,
      userStatus: data.user_status,
      gameStatus: data.game_status,
      result: data.result,
    };

    dispatch({
      type: UserActionType.LOAD_USER,
      payload: payload,
    });
  } catch (error) {
    dispatch({
      type: UserActionType.REQUEST_FAIL,
    });
  }
};

export const userLogout = () => async (dispatch: Dispatch<UserActionModel | DeckActionModel | GameActionModel>) => {
  try {
    const url = '/users_api/logout';
    dispatch({ type: UserActionType.REQUEST_START });
    await axios.get(url);

    await timeout(1200);

    dispatch({
      type: UserActionType.LOGOUT_SUCCESS,
    });

    dispatch({
      type: DeckActionType.LOGOUT,
    });

    dispatch({
      type: GameActionType.LOGOUT,
    });
  } catch (error) {
    dispatch({
      type: UserActionType.REQUEST_FAIL,
    });
  }
};

export const userSetBuyOption = (payload: boolean) => async (dispatch: Dispatch<UserActionModel>) => {
  dispatch({
    type: UserActionType.SET_BUY_OPTION,
    payload: payload,
  });
};

export const userSetLoginOption = (payload: boolean) => async (dispatch: Dispatch<UserActionModel>) => {
  dispatch({
    type: UserActionType.SET_LOGIN_OPTION,
    payload: payload,
  });
};
