import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ConfirmStep, { ConfirmStepState } from '../../components/gadgets/ConfirmStep';
import { gameLeftGame } from '../../redux/game/GameActions';
import { setLoader, setMessage } from '../../redux/handler/handlerActions';
import { RootState } from '../../redux/store';
import { apiPut, timeout } from '../../utils/helpers';
import { useLeaveText } from './GameService';

interface GameLeaveProps {}

const GameLeave: React.FC<GameLeaveProps> = () => {
  const { user } = useSelector((state: RootState) => state);
  const dispatch = useDispatch();
  const [confirm, setConfirm] = useState<ConfirmStepState>({
    question: 'ARE YOU SURE TO LEAVE THE GAME?',
    confirmText: 'CONFIRM',
    rejectText: 'DECLINE',
    answer: false,
    answerReject: false,
    active: false,
    outsideClick: false,
    classNameExtension: 'question',
  });

  useLeaveText(user.gameStatus, user.timerStatus, confirm, setConfirm);

  useEffect(() => {
    const leaveFct = async () => {
      dispatch(setLoader(true));
      const { isError, text } = await apiPut('/timer_api/leave');
      await timeout(1200);
      if (isError) {
        dispatch(setLoader(false));
        dispatch(setMessage(text));
        return;
      }
      dispatch(setLoader(false));
      dispatch(setMessage('You left the game....'));
      dispatch(gameLeftGame());
    };
    if (confirm.answer === true) {
      leaveFct();
    }
    // eslint-disable-next-line
  }, [confirm.answer, confirm.answerReject]);
  return (
    <Fragment>
      <ConfirmStep confirm={confirm} setConfirm={setConfirm} />
      <div
        className='game-arena--leave size-32 game-1 mt-5'
        onClick={() =>
          setConfirm({
            ...confirm,
            active: true,
          })
        }
      >
        LEAVE
      </div>
    </Fragment>
  );
};

export default GameLeave;
