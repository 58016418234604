import React, { CSSProperties, useState } from 'react';
import { ErrorModel } from '../../Models';
import { validation, ValidationModel, ValidationType } from '../../utils/Validation';
import ErrorText from './ErrorText';

interface InputProps {
  name: string;
  title: string;
  state: ValidationModel;
  setState: any;
  isError: boolean;
  type: string;
  styles?: CSSProperties;
  classNameExtension?: string;
  showErrorText?: boolean;
  onBlur?: any;
  onFocus?: any;
  placeholder?: string;
}

const Input: React.FC<InputProps> = ({
  name,
  title,
  state,
  styles,
  setState,
  isError,
  classNameExtension = '',
  type = 'text',
  showErrorText = true,
  onBlur = null,
  onFocus = null,
  placeholder = '',
}) => {
  const [error, setError] = useState<ErrorModel>({
    isError: true,
    text: '',
  });

  const onBlurIntern = () => {
    if (onBlur === null) {
      var check = validation(state);
      setError(check);
    } else {
      onBlur();
    }
  };
  const onFocusIntern = () => {
    if (onFocus === null) {
      setError({
        isError: false,
        text: '',
      });
    } else {
      onFocus();
    }
  };
  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    if (state.type === ValidationType.PLZ) {
      const re = /^[0-9\b]+$/;
      if (!(!(re.test(value) || value === '') || value.length > 5)) {
        setState(value);
      }
    } else {
      setState(value);
    }
  };

  return (
    <div className={`input ${classNameExtension}`}>
      <p className='shared-input-title'>{title}</p>
      <input
        name={name}
        onChange={onChange}
        onFocus={onFocusIntern}
        onBlur={onBlurIntern}
        style={styles}
        value={state.value}
        type={type}
        placeholder={placeholder}
        className={isError === true && error.isError ? 'error' : ''}
      />
      <ErrorText text={showErrorText ? error.text : ''} />
    </div>
  );
};

export default Input;
