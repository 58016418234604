import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
// const query = useQuery();
// query.get('sad');
// is null when not there...
export const useQuery = (): URLSearchParams => {
  return new URLSearchParams(useLocation().search);
};

// Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
//  const windowSize = useWindowSize();
export const useWindowSize = () => {
  // Initialize state with undefined width/height so server and client renders match
  const [windowSize, setWindowSize] = useState({
    width: 0,
    height: 0,
  });
  useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      // Set window width/height to state
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }
    // Add event listener
    window.addEventListener('resize', handleResize);
    // Call handler right away so state gets updated with initial window size
    handleResize();
    // Remove event listener on cleanup
    return () => window.removeEventListener('resize', handleResize);
  }, []); // Empty array ensures that effect is only run on mount
  return windowSize;
};

export const useWindowFocus = () => {
  const [windowFocus, setWindowFocus] = useState(true);
  const onFocus = () => {
    setWindowFocus(true);
  };

  // User has switched away from the tab (AKA tab is hidden)
  const onBlur = () => {
    setWindowFocus(false);
  };
  useEffect(() => {
    window.addEventListener('focus', onFocus);
    window.addEventListener('blur', onBlur);
    // Calls onFocus when the window first loads
    onFocus();
    // Specify how to clean up after this effect:
    return () => {
      window.removeEventListener('focus', onFocus);
      window.removeEventListener('blur', onBlur);
    };
  }, []);

  return windowFocus;
};
