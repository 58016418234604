import React from "react";
import { ROUND_LOST, ROUND_TIE, ROUND_WON } from "../../Constants";

interface StampProps {
  state: string;
}

const Stamp: React.FC<StampProps> = ({ state }) => {
  var colorClass = "";
  var text = "";

  if (state === ROUND_LOST) {
    colorClass = "lost";
    text = "LOST";
  } else if (state === ROUND_WON) {
    colorClass = "won";
    text = "WON";
  } else if (state === ROUND_TIE) {
    colorClass = "tie";
    text = "TIE";
  } else {
    return null;
  }
  return (
    <div className={`stamp ${colorClass}`}>
      <div className='stamp-text'>{text}</div>
    </div>
  );
};

export default Stamp;
