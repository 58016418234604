import { defaultState } from '../reduxStates';
import { GameActionModel, GameActionType } from './GameModel';
export const gameReducer = (state = defaultState.game, action: GameActionModel) => {
  switch (action.type) {
    case GameActionType.REQUEST_START:
      return {
        ...state,
        isLoading: true,
      };
    case GameActionType.REQUEST_FAIL:
      return {
        ...defaultState.game,
        isGame: false,
        isLoading: false,
      };
    case GameActionType.DEFAULT_CURRENT_STATES:
      return {
        ...state,
        currentCard: -1,
        currentOpponentCard: -1,
        currentProperty: '',
        isLoading: false,
      };

    case GameActionType.ENTER_GAME:
      return {
        ...defaultState.game,
      };

    case GameActionType.SET_CURRENT_CARD:
      return {
        ...state,
        currentCard: action.payload,
        isLoading: false,
      };

    case GameActionType.SET_CURRENT_OPPONENT_CARD:
      return {
        ...state,
        currentOpponentCard: action.payload,
        isLoading: false,
      };

    case GameActionType.SET_CURRENT_PROPERTY:
      return {
        ...state,
        currentProperty: action.payload,
        isLoading: false,
      };
    case GameActionType.SET_DEFAULT_MOVE:
      return {
        ...state,
        currentProperty: '',
        currentOpponentCard: -1,
        currentCard: -1,
        isLoading: false,
      };
    case GameActionType.GET_STATUS:
      return {
        ...state,
        round: action.payload.round,
        playerType: action.payload.player_type,
        nextMove: action.payload.next_move,
        ownPlayPositions: action.payload.own_play_positions,
        opponentPlayPositions: action.payload.opponent_play_positions,
        outcomes: action.payload.outcomes,
        moves: action.payload.moves,
        isLoading: false,
      };

    case GameActionType.GET_INFO:
      return {
        ...state,
        deck: action.payload.deck,
        opponentName: action.payload.opponent_name,
        isLoading: false,
      };
    case GameActionType.END_GAME:
      return {
        ...defaultState.game,
        isLoading: false,
      };
    case GameActionType.LOGOUT:
      return {
        ...defaultState.game,
        isLoading: false,
      };

    default:
      return state;
  }
};
