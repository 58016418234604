import axios from 'axios';
import jump from 'jump.js';
import { PROJECT_NAME } from '../Constants';
import { ResponseModel } from '../Models';

export function timeout(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export const fromSessionStorage = (name: string, defaultValue: any) => {
  const relativeName = PROJECT_NAME + '__' + name;

  return sessionStorage.getItem(relativeName) ? JSON.parse(sessionStorage.getItem(relativeName) || '') : defaultValue;
};

export const setSessionStorage = (name: string, value: any) => {
  sessionStorage.setItem(PROJECT_NAME + '__' + name, JSON.stringify(value));
};

export const jumpTo = (query = 'body', maxDuration = 1200) => {
  try {
    var elementExists = document.querySelector(query);
    if (elementExists !== null) {
      jump(query, {
        duration: (distance) => Math.min(Math.abs(distance) / 2, maxDuration),
        offset: 0,
        callback: undefined,
      });
    }
  } catch (error) {
    console.log(error);
  }
};

export const axiosErrorText = (error: any, text = 'SOMETHING WENT WRONG') => {
  if (error.response) {
    if (error.response.data) {
      if (error.response.data.error_text) {
        console.log(error.response.data);
        return error.response.data.error_text;
      } else {
        return text;
      }
    }
  }
  return text;
};

export const axiosErrorType = (error: any) => {
  if (error.response) {
    if (error.response.data) {
      if (error.response.data.error_text) {
        console.log(error.response.data);
        return error.response.data.error_type;
      } else {
        return 'ERROR_IN';
      }
    }
  }
  return 'ERROR_IN_axiosErrorType';
};

export const setSetState = (name: string, state: any, setState: any) => {
  const fct = (value: string): any => {
    setState({
      ...state,
      [name]: {
        ...state[name],
        value: value,
      },
    });
  };
  return fct;
};

export const isElementInViewport = (el: HTMLElement) => {
  var rect = el.getBoundingClientRect();
  return (
    rect.top >= 0 &&
    rect.left >= 0 &&
    rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
    rect.right <= (window.innerWidth || document.documentElement.clientWidth)
  );
};

export const getURLParam = (name: string): string => {
  const queryString = window.location.search;
  const query = new URLSearchParams(queryString);
  const value = query.get(name);
  if (value === null) {
    return '';
  } else {
    return value;
  }
};

export const detectBrowser = () => {
  //https://stackoverflow.com/questions/62409889/how-to-detect-browser-for-chrome

  const agent = window.navigator.userAgent.toLowerCase();
  switch (true) {
    case agent.indexOf('edge') > -1:
      return 'MS Edge (EdgeHtml)';
    case agent.indexOf('edg') > -1:
      return 'MS Edge Chromium';
    case agent.indexOf('opr') > -1:
      return 'opera';
    case agent.indexOf('chrome') > -1:
      return 'chrome';
    case agent.indexOf('trident') > -1:
      return 'Internet Explorer';
    case agent.indexOf('firefox') > -1:
      return 'firefox';
    case agent.indexOf('safari') > -1:
      return 'safari';
    default:
      return 'other';
  }
};

export const isSafari = () => {
  return detectBrowser() === 'safari';
};

export const isFirefox = () => {
  return detectBrowser() === 'firefox';
};

export const isChrome = () => {
  return detectBrowser() === 'chrome';
};

export const apiGet = async (url: string): Promise<ResponseModel> => {
  try {
    const { data } = await axios.get(url);
    return {
      isError: false,
      text: '',
      data: data.result,
    };
  } catch (error) {
    return {
      isError: true,
      text: axiosErrorText(error),
      data: axiosErrorType(error),
    };
  }
};

export const apiPut = async (url: string, body: any = {}): Promise<ResponseModel> => {
  try {
    const { data } = await axios.put(url, body);
    return {
      isError: false,
      text: '',
      data: data.result,
    };
  } catch (error) {
    return {
      isError: true,
      text: axiosErrorText(error),
      data: axiosErrorType(error),
    };
  }
};

export const apiPost = async (url: string, body: any = {}): Promise<ResponseModel> => {
  try {
    const { data } = await axios.post(url, body);
    return {
      isError: false,
      text: '',
      data: data.result,
    };
  } catch (error) {
    return {
      isError: true,
      text: axiosErrorText(error),
      data: axiosErrorType(error),
    };
  }
};

export const apiDelete = async (url: string): Promise<ResponseModel> => {
  try {
    const { data } = await axios.delete(url);
    return {
      isError: false,
      text: '',
      data: data.result,
    };
  } catch (error) {
    return {
      isError: true,
      text: axiosErrorText(error),
      data: axiosErrorType(error),
    };
  }
};

export const apiPostForm = async (url: string, formData: any = {}): Promise<ResponseModel> => {
  try {
    const { data } = await axios.post(url, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    return {
      isError: false,
      text: '',
      data: data.result,
    };
  } catch (error) {
    return {
      isError: true,
      text: axiosErrorText(error),
      data: axiosErrorType(error),
    };
  }
};
