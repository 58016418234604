import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { GameGetInfo, GameGetStatus } from '../../redux/game/GameActions';
import { RootState } from '../../redux/store';
import { useGameStatus } from '../../utils/useSockets';

interface GameLayoutProps {
  children: JSX.Element;
}

const GameLayout: React.FC<GameLayoutProps> = ({ children }) => {
  const { user, game } = useSelector((state: RootState) => state);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(GameGetStatus());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    dispatch(GameGetInfo());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, game.round]);

  useGameStatus();

  return (
    <div className='game-layout background-welcome font-family-1'>
      <div className='game-layout-container'>{children}</div>
    </div>
  );
};

export default GameLayout;
