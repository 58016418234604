import React from "react";
import { useDrop } from "react-dnd";
import { useDispatch } from "react-redux";
import PointsHorizontal from "../../components/nordic-points/PointsHorizontal";
import { DRAG_DROP_FROM_SCROLL } from "../../Constants";
import { deckSetNext } from "../../redux/deck/DeckActions";

interface CardDrapDropProps {
  position: number;
}

const CardDrapDrop: React.FC<CardDrapDropProps> = ({ position }) => {
  const dispatch = useDispatch();
  const accept = [DRAG_DROP_FROM_SCROLL];
  const onDrop = (item: any) => {
    dispatch(deckSetNext(item.schema, item.templateId, position));
  };
  const [{ isOver, canDrop }, drop] = useDrop({
    accept,
    drop: onDrop,
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  });
  const isActive = isOver && canDrop;
  return (
    <div className='card-drag-drop'>
      <div ref={drop} className={`card-drag-drop-card ${isActive && "active"}`}>
        <div className='card-icon'></div>
        <div className='card-text white-color font-family-1 size-24'>drag & drop</div>
      </div>
      <div className='py-4'>
        <PointsHorizontal
          scores={null}
          showBar={true}
          schema={""}
          classNameExtension='points-horizontal--card-drag-drop'
        />
      </div>
    </div>
  );
};

export default CardDrapDrop;
