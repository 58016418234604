import React from 'react';
import { useNavigate } from 'react-router-dom';
import BuyButton from '../../components/nordic-games/BuyButton';
import DashboardTopElements from '../Dashboard/DashboardTopElements';
interface Props {}

const NotFound: React.FC<Props> = () => {
  const navigate = useNavigate();
  return (
    <div className='dashboard-layout background-welcome'>
      <DashboardTopElements />
      <div className='container pt-5'>
        <h1 className='text-center pt-5 white-color pb-3'>PAGE NOT FOUND</h1>
        <div className='text-center py-4'>
          <BuyButton text='BACK TO WALLET' classNameExtension='size-big' onClick={() => navigate('/wallet')} />
        </div>
      </div>
    </div>
  );
};

export default NotFound;
