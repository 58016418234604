import React from 'react';
import DashboardLayout from './DashboardLayout';
import {
  allianzSrc,
  attackSrc,
  defenceSrc,
  importanceSrc,
  influenceSrc,
  karmaSrc,
  setHoverText,
  strategySrc,
} from '../../utils/NordicHelpers';
import HoverTippy from '../../components/gadgets/HoverTippy';
import {
  PROPERTY_AAF,
  PROPERTY_AF,
  PROPERTY_BK,
  PROPERTY_DEV,
  PROPERTY_PIL,
  PROPERTY_RSI,
  PROPERTY_SI,
} from '../../Constants';
import BuyButton from '../../components/nordic-games/BuyButton';
import { useNavigate } from 'react-router-dom';

interface DashboardRulesProps {}

const DashboardRules: React.FC<DashboardRulesProps> = () => {
  const navigate = useNavigate();
  return (
    <DashboardLayout>
      <div className='dashboard-rules pt-5 mt-5 font-family-1 shy'>
        <div className='container'>
          <div className='content-text white-color'>
            <div className='size-40 size-32-md'>
              GAME RULES: <span className='no-wrap'>1 vs 1</span>
            </div>
            <hr className='shared-hr' />
            <div>
              <div className='size-32 size-24-md pt-5 pb-3 primary-color'>PRE-GAME REQUIREMENTS:</div>
              <div className='size-18 text-justify pb-3'>
                1. Minimum of 5 original Nordic Legends cards from Lost Treasures Galaxy.
              </div>
              <div className='size-18 text-justify pb-3'>
                2. Lost Treasures Galaxy Coin to join the game OR 1 Nordic Legends Coin for one game (The LTG Coin will
                NOT be burned after the game compared to the NL Coin)
              </div>
              <div className='size-18 text-justify pb-5'>3. Stable internet connection</div>
            </div>
            <div>
              <div className='size-32 size-24-md pt-5 pb-3 primary-color'>GAMEPLAY:</div>
              <div className='size-18 text-justify pb-3'>1. players meet in the arena.</div>
              <div className='size-18 text-justify pb-3'>
                2. the attacking player chooses one of the seven strategies.
              </div>
              <div className='size-18 text-justify pb-3'>
                3. the attacking player chooses one of his own five previously determined cards.
              </div>
              <div className='size-18 text-justify pb-3'>
                4. the attacking player selects one of the five unknown cards determined by the opponent.
              </div>
              <div className='size-18 text-justify pb-3'>5. the players' selected cards meet. </div>
            </div>
            <div>
              <div className='size-32 size-24-md pt-5 pb-3 primary-color'>SHOWDOWN:</div>
              <div className='one-round pb-4 pt-4'>
                <div className='one-poss'>
                  <HoverTippy
                    hoverElement={<ImageContainer src={allianzSrc[0]} />}
                    classNameExtension='tippy-game-property'
                  >
                    <span>{setHoverText(PROPERTY_AAF)}</span>
                  </HoverTippy>{' '}
                </div>

                <div className='size-24 size-18-md'>vs</div>

                <div className='one-poss'>
                  <HoverTippy
                    hoverElement={<ImageContainer src={allianzSrc[0]} />}
                    classNameExtension='tippy-game-property'
                  >
                    <span>{setHoverText(PROPERTY_AAF)}</span>
                  </HoverTippy>{' '}
                </div>
                <div className='size-24 size-18-md result'> = HIGHER VALUE WINS</div>
              </div>
              <div className='one-round pb-4'>
                <div className='one-poss'>
                  <HoverTippy
                    hoverElement={<ImageContainer src={influenceSrc[0]} />}
                    classNameExtension='tippy-game-property'
                  >
                    <span>{setHoverText(PROPERTY_PIL)}</span>
                  </HoverTippy>{' '}
                </div>

                <div className='size-24 size-18-md'>vs</div>

                <div className='one-poss'>
                  <HoverTippy
                    hoverElement={<ImageContainer src={influenceSrc[0]} />}
                    classNameExtension='tippy-game-property'
                  >
                    <span>{setHoverText(PROPERTY_PIL)}</span>
                  </HoverTippy>{' '}
                </div>
                <div className='size-24 size-18-md result'> = HIGHER VALUE WINS</div>
              </div>

              <div className='one-round pb-4'>
                <div className='one-poss'>
                  <HoverTippy
                    hoverElement={<ImageContainer src={karmaSrc[0]} />}
                    classNameExtension='tippy-game-property'
                  >
                    <span>{setHoverText(PROPERTY_BK)}</span>
                  </HoverTippy>{' '}
                </div>

                <div className='size-24 size-18-md'>vs</div>

                <div className='one-poss'>
                  <HoverTippy
                    hoverElement={<ImageContainer src={karmaSrc[0]} />}
                    classNameExtension='tippy-game-property'
                  >
                    <span>{setHoverText(PROPERTY_BK)}</span>
                  </HoverTippy>{' '}
                </div>
                <div className='size-24 size-18-md result'> = LOWER VALUE WINS</div>
              </div>

              <div className='one-round pb-4'>
                <div className='one-poss'>
                  <HoverTippy
                    hoverElement={<ImageContainer src={strategySrc[0]} />}
                    classNameExtension='tippy-game-property'
                  >
                    <span>{setHoverText(PROPERTY_SI)}</span>
                  </HoverTippy>{' '}
                </div>

                <div className='size-24 size-18-md'>vs</div>

                <div className='one-poss'>
                  <HoverTippy
                    hoverElement={<ImageContainer src={strategySrc[0]} />}
                    classNameExtension='tippy-game-property'
                  >
                    <span>{setHoverText(PROPERTY_SI)}</span>
                  </HoverTippy>{' '}
                </div>
                <div className='size-24 size-18-md result'> = HIGHER VALUE WINS</div>
              </div>

              <div className='one-round pb-4'>
                <div className='one-poss'>
                  <HoverTippy
                    hoverElement={<ImageContainer src={attackSrc[0]} />}
                    classNameExtension='tippy-game-property'
                  >
                    <span>{setHoverText(PROPERTY_AF)}</span>
                  </HoverTippy>{' '}
                </div>

                <div className='size-24 size-18-md'>vs</div>

                <div className='one-poss'>
                  <HoverTippy
                    hoverElement={<ImageContainer src={defenceSrc[0]} />}
                    classNameExtension='tippy-game-property'
                  >
                    <span>{setHoverText(PROPERTY_DEV)}</span>
                  </HoverTippy>{' '}
                </div>
                <div className='size-24 size-18-md result'> = HIGHER VALUE WINS</div>
              </div>
              <div className='one-round pb-4'>
                <div className='one-poss'>
                  <HoverTippy
                    hoverElement={<ImageContainer src={importanceSrc[0]} />}
                    classNameExtension='tippy-game-property'
                  >
                    <span>{setHoverText(PROPERTY_RSI)}</span>
                  </HoverTippy>{' '}
                </div>

                <div className='size-24 size-18-md'>vs</div>

                <div className='one-poss'>
                  <HoverTippy
                    hoverElement={<ImageContainer src={importanceSrc[0]} />}
                    classNameExtension='tippy-game-property'
                  >
                    <span>{setHoverText(PROPERTY_RSI)}</span>
                  </HoverTippy>{' '}
                </div>
                <div className='size-24 size-18-md result'> = HIGHER VALUE WINS</div>
              </div>
            </div>
            <div>
              <div className='size-32 size-24-md pt-5 pb-3 primary-color'>POSSIBLE SCENARIOS:</div>
              <div className='size-18 text-justify pb-3'>
                1. Player A defeats Player B by the selected higher/lower value = The won card of Player B, is
                transferred to Player A's Wallet.
              </div>
              <div className='size-18 text-justify pb-3'>
                2. Player B defeats player A by the selected higher/lower value = the won card of player A is
                transferred to the Wallet of player B.
              </div>
              <div className='size-18 text-justify pb-3'>
                3. Player A and Player B score the same number of points and the match is a draw. The selected cards for
                this match are transferred back to the original wallets. These cards cannot be used again within this
                current match.
              </div>
            </div>
            <div className='pb-3'>
              <div className='size-32 size-24-md pt-5 pb-3 red-color'>IMPORTANT INFO:</div>
              <div className='size-18 text-justify pb-3'>
                To ensure a fair game experience for all, the player who leaves the game early will receive a warning
                and may even be blacklisted if the game is repeated.
              </div>
            </div>
            <div className='text-center py-5'>
              <BuyButton text='PLAY NOW' onClick={() => navigate('/')} classNameExtension='size-big' />
            </div>
          </div>
        </div>
      </div>
    </DashboardLayout>
  );
};

export default DashboardRules;

interface ImageContainerProps {
  src: string;
}
const ImageContainer: React.FC<ImageContainerProps> = ({ src }) => {
  return (
    <div className={`img-container flex-center`}>
      <img src={src} alt='' className='img-property' />
    </div>
  );
};
