// import { setSessionStorage } from "../utils/ReduxHelpers";
import { Dispatch } from 'react';
import { apiGet } from '../../utils/helpers';
import { RootState } from '../store';
import { DeckActionModel, DeckActionType } from './DeckModel';

export const deckSetNext =
  (newSchema: string, newTemplateId: string, position = -1) =>
  async (dispatch: Dispatch<DeckActionModel>, getState: () => RootState) => {
    try {
      var alreadyInDeckIndex = -1;

      var nextIndex = -1;
      if (position >= 0 && position <= 4) {
        nextIndex = position;
      } else {
        nextIndex = getState().deck.chosen.indexOf(null);
      }
      getState().deck.chosen.forEach((el: any, index: number) => {
        if (el) {
          if (el.schema === newSchema && el.templateId === newTemplateId) {
            alreadyInDeckIndex = index;
          }
        }
      });

      if (alreadyInDeckIndex !== -1) {
        if (getState().deck.chosen[position]) {
          dispatch({
            type: DeckActionType.NEXT,
            payload: { postiton: alreadyInDeckIndex, data: getState().deck.chosen[position] },
          });
        } else {
          dispatch({
            type: DeckActionType.NEXT,
            payload: { postiton: alreadyInDeckIndex, data: null },
          });
        }
      }

      if (nextIndex !== -1) {
        const { data, isError } = await apiGet(`/cards_api/get_highest_mint/${newSchema}/${newTemplateId}`);
        if (isError) return;
        const newEl = {
          schema: newSchema,
          templateId: newTemplateId,
          mint: data.mint,
          asset_id: data.asset_id,
        };
        dispatch({
          type: DeckActionType.NEXT,
          payload: { postiton: nextIndex, data: newEl },
        });
      }
    } catch (error) {
      dispatch({
        type: DeckActionType.REQUEST_FAIL,
      });
    }
  };
export const deckDelete =
  (position: number) => async (dispatch: Dispatch<DeckActionModel>, getState: () => RootState) => {
    try {
      dispatch({
        type: DeckActionType.DELETE,
        payload: position,
      });
    } catch (error) {
      dispatch({
        type: DeckActionType.REQUEST_FAIL,
      });
    }
  };

export const deckSetFriend =
  (name: string) => async (dispatch: Dispatch<DeckActionModel>, getState: () => RootState) => {
    dispatch({
      type: DeckActionType.SET_FRIEND,
      payload: name,
    });
  };
