import React from "react";

interface FullScreenWaitingProps {}

const FullScreenWaiting: React.FC<FullScreenWaitingProps> = () => {
  return (
    <div className='full-screen-waiting background-welcome'>
      <div className='waiting-loader'></div>

      <h2 className='waiting-text font-family-1 text-uppercase'>Loading...</h2>
    </div>
  );
};

export default FullScreenWaiting;
