import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Input from '../../components/forms/Input';
import BuyButton from '../../components/nordic-games/BuyButton';
import { ErrorModel } from '../../Models';
import { deckSetFriend } from '../../redux/deck/DeckActions';
import { RootState } from '../../redux/store';
import { userSetLoginOption } from '../../redux/user/userActions';
import { apiGet, setSetState } from '../../utils/helpers';
import { ValidationModel, ValidationType } from '../../utils/Validation';

interface DashboardFriendProps {
  classNameExtension?: string;
}

export interface InternState {
  opponentName: ValidationModel;
}

const DashboardFriend: React.FC<DashboardFriendProps> = ({ classNameExtension }) => {
  const dispatch = useDispatch();
  const { user } = useSelector((state: RootState) => state);
  const [state, setState] = useState<InternState>({
    opponentName: {
      type: ValidationType.NOT_EMPTY,
      value: '',
    },
  });

  const [success, setSuccess] = useState('');

  const [error, setError] = useState<ErrorModel>({
    isError: false,
    text: '',
  });
  const onBlur = async () => {
    if (state.opponentName.value === '') {
      setError({
        isError: false,
        text: '',
      });
      setSuccess('');
      dispatch(deckSetFriend(''));
      return;
    }
    const { isError, text } = await apiGet('/users_api/user_name_exist/' + state.opponentName.value);
    if (isError) {
      setError({
        isError: true,
        text: text,
      });
      setSuccess('');
      dispatch(deckSetFriend(''));
    } else {
      setError({
        isError: false,
        text: '',
      });
      setSuccess('User exist!');
      dispatch(deckSetFriend(state.opponentName.value));
    }
  };
  const onFocus = () => {
    setError({
      isError: false,
      text: '',
    });
    setSuccess('');
  };

  return (
    <div className={`dashboard-friend font-family-1  ${classNameExtension}`}>
      <div className={`question-container`}>
        <div className='text-part white-color size-24 pr-5' style={{ transform: 'translateY(5px)' }}>
          YOU WANT TO PLAY WITH A FRIEND ?
        </div>

        {user.isLogged && (
          <div className='input-part'>
            <Input
              name='partner'
              title=''
              type='text'
              isError={error.isError}
              state={state.opponentName}
              showErrorText={false}
              onBlur={onBlur}
              onFocus={onFocus}
              setState={setSetState('opponentName', state, setState)}
              placeholder={''}
            />
            <div className='red-color size-18 '>{error.text}</div>
            <div className='success-color size-18 '>{success}</div>
          </div>
        )}
        {!user.isLogged && (
          <div className='input-part'>
            <BuyButton
              text='LOGIN FIRST'
              onClick={() => {
                dispatch(userSetLoginOption(true));
              }}
              classNameExtension='dashboard-card-scroll--buy-now pb-3'
            />
          </div>
        )}
      </div>
      <div className='announce-container pt-4'>
        <div className='announce-text white-color size-20'>Announce in our Discord Community</div>
        <div
          className='announce-img pl-4 flex-center'
          onClick={() => window.open('https://discord.gg/6jMx2TRzjR', '_blank')}
        >
          <img src='/img/social/Discord.png' alt='' className='discord-img' />
        </div>
      </div>
    </div>
  );
};

export default DashboardFriend;
