import React, { Fragment, useState } from 'react';

import { useNavigate } from 'react-router';

import { useTransition } from '@react-spring/core';
import { animated } from '@react-spring/web';

import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import { userLogout, userSetBuyOption, userSetLoginOption } from '../../redux/user/userActions';
import BuyButton from '../../components/nordic-games/BuyButton';
import { NFT_SOURCE_URL, URL_LTG_WEBSITE } from '../../Constants';
import { useWindowSize } from '../../utils/customHooks';

interface UserDropProps {
  classExtension?: string;
}

const UserDrop: React.FC<UserDropProps> = ({ classExtension = '' }) => {
  const { user, cards } = useSelector((state: RootState) => state);
  const breakWindowSize = 1199.98;
  const windowSize = useWindowSize();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [dropdown, setDropdown] = useState(false);
  const [isLtgCoin, setIsLtgCoin] = useState(true);

  const transitionBig = useTransition(dropdown, {
    from: { opacity: 0, transform: 'translateY(32px)' },
    enter: { opacity: 1, transform: 'translateY(48px)' },
    leave: { opacity: 0, transform: 'translateY(32px)' },
    config: { duration: 300 },
  });

  return (
    <div className='user-drop-container'>
      <div
        className={`user-drop ${classExtension}`}
        onMouseEnter={() => {
          if (windowSize.width > breakWindowSize) {
            setDropdown(true);
          }
        }}
        onMouseLeave={() => {
          if (windowSize.width > breakWindowSize) {
            setDropdown(false);
          }
        }}
        onClick={() => {
          if (windowSize.width <= breakWindowSize) {
            setDropdown(!dropdown);
          }
        }}
      >
        {transitionBig(
          (style, item) =>
            item === true && (
              <animated.div style={style}>
                <ul className='dropdown-menu'>
                  <li className='dropdown-link' onClick={() => navigate('/')}>
                    GAME DASHBOARD
                  </li>
                  <li className='dropdown-link' onClick={() => navigate('/wallet')}>
                    WALLET
                  </li>

                  <li className='dropdown-link' onClick={() => navigate('/rules')}>
                    RULES
                  </li>

                  <li
                    className='dropdown-link'
                    onClick={() => {
                      window.location.href = URL_LTG_WEBSITE;
                    }}
                  >
                    BACK TO WEBSITE
                  </li>
                  {user.isLogged && (
                    <Fragment>
                      <li className='dropdown-link' onClick={() => dispatch(userLogout())}>
                        LOGOUT
                      </li>
                    </Fragment>
                  )}

                  {!user.isLogged && (
                    <Fragment>
                      <li className='dropdown-link' onClick={() => dispatch(userSetLoginOption(true))}>
                        LOGIN
                      </li>
                    </Fragment>
                  )}
                  <li className='text-center pt-2 pb-4'>
                    <BuyButton />
                  </li>
                </ul>
              </animated.div>
            )
        )}

        <div
          className='drop-btn'
          onClick={() => {
            if (!user.isLogged && windowSize.width > breakWindowSize) {
              dispatch(userSetLoginOption(true));
            }
          }}
        >
          {user.isLogged ? (
            <span>{user.id}</span>
          ) : (
            <span>{windowSize.width > breakWindowSize ? 'LOGIN' : 'MENU'}</span>
          )}

          <i className='fas fa-caret-down ml-3' style={{ transform: 'translateY(-2px)' }} />
        </div>
      </div>{' '}
      {cards.ltgCoin && cards.nlCoin && (
        <div className={`user-drop-coin-container pt-2 ${dropdown && 'is-dropdown'}`}>
          <div className={`icon-left `} onClick={() => setIsLtgCoin(!isLtgCoin)}>
            <i className='fas fa-chevron-left'></i>
          </div>
          <div className={`icon-right `} onClick={() => setIsLtgCoin(!isLtgCoin)}>
            <i className='fas fa-chevron-right'></i>
          </div>

          {isLtgCoin && (
            <Fragment>
              <div className={`user-circle-container`}>
                {cards.ltgCoin.length > 0 && (
                  <video
                    src={NFT_SOURCE_URL + cards.ltgCoin[0].immutable_data.video}
                    className={`user-drop-coin-video`}
                    playsInline
                    autoPlay
                    muted
                    loop
                    onClick={() => {
                      dispatch(userSetBuyOption(true));
                    }}
                  ></video>
                )}
              </div>

              <div className='user-drop-coin-title'>
                <div className='font-family-1 white-color pt-2 coin-title '>
                  {user.isLogged && (
                    <Fragment>
                      {user.ltgcoin} {user.ltgcoin === 1 ? 'LTG Coin' : 'LTG Coins'}
                    </Fragment>
                  )}

                  {!user.isLogged && <Fragment>LOST TREASURES GALAXY COIN</Fragment>}
                </div>
              </div>
            </Fragment>
          )}
          {!isLtgCoin && (
            <Fragment>
              <div className={`user-circle-container-nl-coin`}>
                {cards.nlCoin.length > 0 && (
                  <video
                    src={NFT_SOURCE_URL + cards.nlCoin[0].immutable_data.video}
                    className={`user-drop-coin-video-nl-coin`}
                    playsInline
                    autoPlay
                    muted
                    loop
                    onClick={() => {
                      dispatch(userSetBuyOption(true));
                    }}
                  ></video>
                )}
              </div>

              <div className='user-drop-coin-title'>
                <div className='font-family-1 white-color pt-2 coin-title'>
                  {user.isLogged && (
                    <Fragment>
                      {user.nlcoin} {user.nlcoin === 1 ? 'NL Coin' : 'NL Coins'}
                    </Fragment>
                  )}

                  {!user.isLogged && <Fragment>NORDIC LEGEND COIN</Fragment>}
                </div>
              </div>
            </Fragment>
          )}
        </div>
      )}
    </div>
  );
};

export default UserDrop;
