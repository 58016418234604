import React, { Fragment, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import HoverTippy from '../../components/gadgets/HoverTippy';
import {
  PROPERTY_AAF,
  PROPERTY_AF,
  PROPERTY_BK,
  PROPERTY_DEV,
  PROPERTY_PIL,
  PROPERTY_RSI,
  PROPERTY_SI,
} from '../../Constants';
import { RootState } from '../../redux/store';
import {
  allianzSrc,
  attackSrc,
  defenceSrc,
  importanceSrc,
  influenceSrc,
  karmaSrc,
  setHoverText,
  strategySrc,
} from '../../utils/NordicHelpers';
interface GameRulesProps {
  setShow: any;
  show: boolean;
}

const GameRules: React.FC<GameRulesProps> = ({ setShow, show }) => {
  const handlers = useSelector((state: RootState) => state.handlers);

  const { isMessage } = handlers;
  let messageRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    let handler = (e: any) => {
      if (!messageRef.current?.contains(e.target) && show === true) {
        setShow(false);
      }
    };
    document.addEventListener('mousedown', handler);
    return () => {
      document.removeEventListener('mousedown', handler);
    };
  });

  return (
    <Fragment>
      <div className={`message game-rules ${show && !isMessage && 'active'}`}>
        <div ref={messageRef} className='content' style={{ maxWidth: '900px' }}>
          <div className='close'>
            <i className='far fa-times-circle' onClick={() => setShow(false)}></i>
          </div>

          <div className='content-text white-color'>
            <div className='size-32 size-24-md'>GAME RULES: 1 vs 1</div>
            <hr className='shared-hr' />
            <div className='one-round pb-4 pt-4'>
              <div className='one-poss'>
                <HoverTippy
                  hoverElement={<ImageContainer src={allianzSrc[0]} />}
                  classNameExtension='tippy-game-property'
                >
                  <span>{setHoverText(PROPERTY_AAF)}</span>
                </HoverTippy>{' '}
              </div>

              <div className='size-24 size-18-md'>vs</div>

              <div className='one-poss'>
                <HoverTippy
                  hoverElement={<ImageContainer src={allianzSrc[0]} />}
                  classNameExtension='tippy-game-property'
                >
                  <span>{setHoverText(PROPERTY_AAF)}</span>
                </HoverTippy>{' '}
              </div>
              <div className='size-24 size-18-md result'> = HIGHER VALUE WINS</div>
            </div>
            <div className='one-round pb-4'>
              <div className='one-poss'>
                <HoverTippy
                  hoverElement={<ImageContainer src={influenceSrc[0]} />}
                  classNameExtension='tippy-game-property'
                >
                  <span>{setHoverText(PROPERTY_PIL)}</span>
                </HoverTippy>{' '}
              </div>

              <div className='size-24 size-18-md'>vs</div>

              <div className='one-poss'>
                <HoverTippy
                  hoverElement={<ImageContainer src={influenceSrc[0]} />}
                  classNameExtension='tippy-game-property'
                >
                  <span>{setHoverText(PROPERTY_PIL)}</span>
                </HoverTippy>{' '}
              </div>
              <div className='size-24 size-18-md result'> = HIGHER VALUE WINS</div>
            </div>

            <div className='one-round pb-4'>
              <div className='one-poss'>
                <HoverTippy
                  hoverElement={<ImageContainer src={karmaSrc[0]} />}
                  classNameExtension='tippy-game-property'
                >
                  <span>{setHoverText(PROPERTY_BK)}</span>
                </HoverTippy>{' '}
              </div>

              <div className='size-24 size-18-md'>vs</div>

              <div className='one-poss'>
                <HoverTippy
                  hoverElement={<ImageContainer src={karmaSrc[0]} />}
                  classNameExtension='tippy-game-property'
                >
                  <span>{setHoverText(PROPERTY_BK)}</span>
                </HoverTippy>{' '}
              </div>
              <div className='size-24 size-18-md result'> = LOWER VALUE WINS</div>
            </div>

            <div className='one-round pb-4'>
              <div className='one-poss'>
                <HoverTippy
                  hoverElement={<ImageContainer src={strategySrc[0]} />}
                  classNameExtension='tippy-game-property'
                >
                  <span>{setHoverText(PROPERTY_SI)}</span>
                </HoverTippy>{' '}
              </div>

              <div className='size-24 size-18-md'>vs</div>

              <div className='one-poss'>
                <HoverTippy
                  hoverElement={<ImageContainer src={strategySrc[0]} />}
                  classNameExtension='tippy-game-property'
                >
                  <span>{setHoverText(PROPERTY_SI)}</span>
                </HoverTippy>{' '}
              </div>
              <div className='size-24 size-18-md result'> = HIGHER VALUE WINS</div>
            </div>

            <div className='one-round pb-4'>
              <div className='one-poss'>
                <HoverTippy
                  hoverElement={<ImageContainer src={attackSrc[0]} />}
                  classNameExtension='tippy-game-property'
                >
                  <span>{setHoverText(PROPERTY_AF)}</span>
                </HoverTippy>{' '}
              </div>

              <div className='size-24 size-18-md'>vs</div>

              <div className='one-poss'>
                <HoverTippy
                  hoverElement={<ImageContainer src={defenceSrc[0]} />}
                  classNameExtension='tippy-game-property'
                >
                  <span>{setHoverText(PROPERTY_DEV)}</span>
                </HoverTippy>{' '}
              </div>
              <div className='size-24 size-18-md result'> = HIGHER VALUE WINS</div>
            </div>
            <div className='one-round pb-4'>
              <div className='one-poss'>
                <HoverTippy
                  hoverElement={<ImageContainer src={importanceSrc[0]} />}
                  classNameExtension='tippy-game-property'
                >
                  <span>{setHoverText(PROPERTY_RSI)}</span>
                </HoverTippy>{' '}
              </div>

              <div className='size-24 size-18-md'>vs</div>

              <div className='one-poss'>
                <HoverTippy
                  hoverElement={<ImageContainer src={importanceSrc[0]} />}
                  classNameExtension='tippy-game-property'
                >
                  <span>{setHoverText(PROPERTY_RSI)}</span>
                </HoverTippy>{' '}
              </div>
              <div className='size-24 size-18-md result'> = HIGHER VALUE WINS</div>
            </div>

            <div className='text-center py-5'>
              <button className='size-24 size-18-md' onClick={() => setShow(false)}>
                BACK
              </button>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default GameRules;

interface ImageContainerProps {
  src: string;
}
const ImageContainer: React.FC<ImageContainerProps> = ({ src }) => {
  return (
    <div className={`img-container flex-center`}>
      <img src={src} alt='' className='img-property' />
    </div>
  );
};
