import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Stamp from '../../components/nordic-games/Stamp';
import PointsHorizontal from '../../components/nordic-points/PointsHorizontal';
import { NFT_SOURCE_URL, ROUND_LOST, ROUND_TIE, ROUND_WON } from '../../Constants';
import { GameSetCurrentCard } from '../../redux/game/GameActions';

import { RootState } from '../../redux/store';
import { cardsGetScores } from './CardsService';
import ContentLoader from 'react-content-loader';
import { useWindowFocus } from '../../utils/customHooks';
import { isSafari } from '../../utils/helpers';
import { useDemand } from '../Game/GameService';

interface CardGameProps {
  position: number;
}

const CardGame: React.FC<CardGameProps> = ({ position = 0 }) => {
  const dispatch = useDispatch();
  const { game, user } = useSelector((state: RootState) => state);
  const { currentCard, nextMove, deck, outcomes, ownPlayPositions, moves } = game;

  const [scores, setScores] = useState<any | null>(null);

  const { shine } = useDemand(user, game);

  const [videoLoaded, setVideoLoaded] = useState<boolean>(false);
  const windowFocus = useWindowFocus();
  const videoRef = useRef<HTMLVideoElement>(null);
  const [roundIndex, setRoundIndex] = useState(-1);
  const [outcome, setOutcome] = useState('');
  useEffect(() => {
    setScores(cardsGetScores(deck[position]));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deck[position]]);

  useEffect(() => {
    if (windowFocus && videoLoaded && videoRef.current && isSafari()) {
      videoRef.current.play();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [windowFocus]);

  useEffect(() => {
    const index = ownPlayPositions.indexOf(position);
    if (index !== -1) {
      setRoundIndex(index);
      setOutcome(outcomes[index]);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ownPlayPositions, outcomes]);

  return (
    <div
      className={`card-game ${currentCard === position && 'active'}  ${
        (!nextMove || roundIndex !== -1) && 'no-pointer'
      } ${outcome === ROUND_WON && 'won'} ${outcome === ROUND_LOST && 'lost'}
      ${outcome === ROUND_TIE && 'tie'} `}
      onClick={() => {
        if (roundIndex === -1) {
          dispatch(GameSetCurrentCard(position));
        }
      }}
    >
      <div className={`is-chosen mb-4 flex-center ${shine === 'OWN_CARD' && roundIndex === -1 && 'active'}`}>
        {roundIndex !== -1 && roundIndex + 1}
      </div>
      <div className='video-container'>
        {deck[position] && (
          <video
            ref={videoRef}
            src={NFT_SOURCE_URL + deck[position].data.video}
            className={`card-video ${!videoLoaded && 'not-loaded'}`}
            playsInline
            autoPlay
            muted
            onMouseOver={(event: any) => event.target.play()}
            onLoadedData={() => setVideoLoaded(true)}
          ></video>
        )}
        {!videoLoaded && (
          <ContentLoader width={160} height={250} backgroundColor='#14192e' foregroundColor='#1a203c'>
            <rect x='1' y='1' rx='1' ry='1' width='160' height='250' />
          </ContentLoader>
        )}
        <Stamp state={outcome} />
      </div>
      <div className='pt-4 pb-1'>
        <PointsHorizontal
          scores={scores}
          showBar={true}
          schema={''}
          classNameExtension='points-horizontal--card-drag-drop'
          highlightProperty={roundIndex !== -1 ? moves[roundIndex] : ''}
        />
      </div>
    </div>
  );
};

export default CardGame;
