import { createStore, combineReducers, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';
import { handlerReducer } from './handler/handlerReducers';
import { initialState } from './reduxStates';
import { userReducer } from './user/userReducers';
import { cardsReducer } from './cards/cardsReducers';
import { deckReducer } from './deck/DeckReducers';
import { gameReducer } from './game/GameReducers';
import { contractReducer } from './contract/contractReducers';

const reducers = combineReducers({
  handlers: handlerReducer,
  user: userReducer,
  cards: cardsReducer,
  deck: deckReducer,
  game: gameReducer,
  contract: contractReducer,
});

const middleware = [thunk];
const store = createStore(reducers, initialState, composeWithDevTools(applyMiddleware(...middleware)));

export default store;

export type RootState = ReturnType<typeof reducers>;
