import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import OnlyBigScreens from '../app/NotFound/OnlyBigScreens';
import {
  BREAKPOINT_BIG_SCREEN,
  GAME_END,
  GAME_LEFT,
  GAME_NON_EXIST,
  GAME_PLAYING,
  GAME_WAITING,
  GAME_WAITING_CONTRACTS_BOTH,
  GAME_WAITING_CONTRACTS_OPPONENT,
  GAME_WAITING_CONTRACTS_OWN,
  GAME_WAITING_CONTRACTS_OWN_CONFIRMED,
} from '../Constants';
import { RootState } from '../redux/store';
import { userIsLogged } from '../redux/user/userActions';
import { useWindowSize } from './customHooks';

interface Props {
  component: React.ComponentType | any;
  role: 'NO_RESTRICTION' | 'DASHBOARD' | 'ARENA' | 'SHOWDOWN' | 'FINAL';
  screenSize?: 'ALL' | 'LARGE';
  title?: string;
}

const PrivateRoute: React.FC<Props> = ({
  component: RouteComponent,
  role = 'DASHBOARD',
  screenSize = 'ALL',
  title = '',
}) => {
  const dispatch = useDispatch();
  const { isLogged, gameStatus } = useSelector((state: RootState) => state.user);
  const activeGame = [
    GAME_PLAYING,
    GAME_WAITING,
    GAME_WAITING_CONTRACTS_OWN,
    GAME_WAITING_CONTRACTS_OPPONENT,
    GAME_WAITING_CONTRACTS_OWN_CONFIRMED,
    GAME_WAITING_CONTRACTS_BOTH,
  ];
  var toWallet = '/wallet';
  var toDashboard = '/';
  var toArena = '/arena';
  var toShowdown = '/showdown';
  const windowSize = useWindowSize();

  useEffect(() => {
    dispatch(userIsLogged());
  }, [dispatch]);
  useEffect(() => {
    if (title !== '') {
      document.title = title;
    }
  }, [title]);

  if (screenSize === 'LARGE') {
    if (windowSize.width < BREAKPOINT_BIG_SCREEN) {
      return <OnlyBigScreens />;
    }
  }
  if (role === 'NO_RESTRICTION') {
    return <RouteComponent />;
  } else if (role === 'DASHBOARD') {
    if (isLogged && activeGame.includes(gameStatus)) {
      return <Navigate to={toArena} />;
    } else {
      return <RouteComponent />;
    }
  } else if (role === 'SHOWDOWN' || role === 'FINAL') {
    if (gameStatus === GAME_NON_EXIST || gameStatus === GAME_LEFT) {
      return <Navigate to={toDashboard} />;
    } else {
      return <RouteComponent />;
    }
  } else if (role === 'ARENA') {
    if (gameStatus === GAME_NON_EXIST || gameStatus === GAME_LEFT) {
      return <Navigate to={toDashboard} />;
    } else if (gameStatus === GAME_END) {
      return <Navigate to={toShowdown} />;
    } else {
      return <RouteComponent />;
    }
  }

  return <Navigate to={toWallet} />;
};

export default PrivateRoute;
