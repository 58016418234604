export enum DeckActionType {
  REQUEST_START = 'DECK_REQUEST_START',
  DELETE = 'DECK_DELETE',
  REQUEST_FAIL = 'DECK_REQUEST_FAIL',
  NEXT = 'DECK_NEXT',
  SET_FRIEND = 'DECK_SET_FRIEND',
  LOGOUT = 'DECK_LOGOUT',
}

interface REQUEST_START {
  type: DeckActionType.REQUEST_START;
  payload?: null;
}

interface REQUEST_FAIL {
  type: DeckActionType.REQUEST_FAIL;
  payload?: null;
}
interface LOGOUT {
  type: DeckActionType.LOGOUT;
  payload?: null;
}

interface DECK_NEXT {
  type: DeckActionType.NEXT;
  payload: { data: any; postiton: number };
}
interface DECK_DELETE {
  type: DeckActionType.DELETE;
  payload: number;
}

interface SET_FRIEND {
  type: DeckActionType.SET_FRIEND;
  payload: string;
}

export type DeckActionModel = REQUEST_START | REQUEST_FAIL | LOGOUT | DECK_NEXT | DECK_DELETE | SET_FRIEND;
