import React, { useEffect, useState } from 'react';
import Tippy from '@tippyjs/react/headless'; // different import path!
import { timeout } from '../../utils/helpers';

interface TippyProps {
  classNameExtension?: string;
  children: JSX.Element;
  hoverElement: JSX.Element;
  onLeave?: boolean;
}
const HoverTippy: React.FC<TippyProps> = ({ hoverElement, children, onLeave = true, classNameExtension = '' }) => {
  const [visible, setVisible] = useState(false);
  const [curserOnInfo, setCurserOnInfo] = useState(false);
  const [curserOnIcon, setCurserOnIcon] = useState(false);

  useEffect(() => {
    let mounted = true;
    const fct = async () => {
      if (curserOnIcon === true) {
        setVisible(true);
        await timeout(800);
        if (!mounted) return;
        if (curserOnInfo === false) {
          setVisible(false);
        }
      }
    };
    fct();
    return () => {
      mounted = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [curserOnIcon]);

  return (
    <span className={`tippy ${classNameExtension}`}>
      <Tippy
        render={(attrs) => (
          <div
            className='tippy-box'
            onMouseEnter={() => {
              setCurserOnInfo(true);
              setVisible(true);
            }}
            onMouseLeave={() => {
              setVisible(false);
            }}
            tabIndex={-1}
            {...attrs}
          >
            {children}
          </div>
        )}
        interactive={true}
        visible={visible}
        onClickOutside={() => setVisible(false)}
      >
        <span
          className='hover-element'
          onMouseEnter={() => {
            setCurserOnIcon(true);
            setVisible(true);
          }}
          onClick={() => setVisible(!visible)}
          onMouseLeave={() => {
            if (onLeave) {
              setVisible(false);
            }
          }}
        >
          {hoverElement}
        </span>
      </Tippy>
    </span>
  );
};

export default HoverTippy;

/*
Example:
              <span className='tippy-container'>
                <StuffTippy>
                  <divclassName='text>
                    Lorem, ipsum dolor sit amet consectetur adipisicing elit.
                    Culpa laudantium suscipit nobis!
                    // <Link to='/users/kontakt' className>Mehr</Link>
                  </div>
                </StuffTippy>
              </span>

*/
