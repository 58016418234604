import React, { Fragment, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { NFT_SOURCE_URL, ROUND_LOST, ROUND_TIE, ROUND_WON } from '../../Constants';
import { GameSetCurrentOppoenentCard } from '../../redux/game/GameActions';

import { RootState } from '../../redux/store';
import { useWindowFocus } from '../../utils/customHooks';
import ContentLoader from 'react-content-loader';
import Stamp from '../../components/nordic-games/Stamp';

import { apiGet, isSafari } from '../../utils/helpers';
import { useDemand } from '../Game/GameService';

interface CardOpponentProps {
  position: number;
}

const CardOpponent: React.FC<CardOpponentProps> = ({ position = 0 }) => {
  const dispatch = useDispatch();

  const [videoLoaded, setVideoLoaded] = useState<boolean>(false);
  const windowFocus = useWindowFocus();
  const videoRef = useRef<HTMLVideoElement>(null);

  const { game, user } = useSelector((state: RootState) => state);
  const { nextMove, deck, outcomes, currentOpponentCard, opponentPlayPositions } = game;
  const { shine } = useDemand(user, game);
  const [roundIndex, setRoundIndex] = useState(-1);
  const [outcome, setOutcome] = useState('');
  const [card, setCard] = useState<any | null>(null);
  useEffect(() => {
    if (windowFocus && videoLoaded && videoRef.current && isSafari()) {
      videoRef.current.play();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [windowFocus]);
  useEffect(() => {
    const index = opponentPlayPositions.indexOf(position);
    if (index !== -1) {
      setRoundIndex(index);
      setOutcome(outcomes[index]);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [opponentPlayPositions, outcomes]);

  useEffect(() => {
    let mounted = true;
    const fct = async () => {
      const { isError, data } = await apiGet('/games_api/get_opponent_card/' + position);
      if (!mounted) return;
      if (isError) return;

      setCard(data);
    };
    if (roundIndex !== -1) {
      fct();
    }
    return () => {
      mounted = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [roundIndex]);

  return (
    <div
      className={`card-opponent ${position === currentOpponentCard && 'active'} ${
        (!nextMove || roundIndex !== -1) && 'no-pointer'
      }
        ${outcome === ROUND_WON && 'won'} ${outcome === ROUND_LOST && 'lost'}
      ${outcome === ROUND_TIE && 'tie'} `}
      onClick={() => {
        if (roundIndex === -1) {
          dispatch(GameSetCurrentOppoenentCard(position));
        }
      }}
    >
      {roundIndex !== -1 && (
        <div className='video-container'>
          {card && (
            <Fragment>
              <video
                ref={videoRef}
                src={NFT_SOURCE_URL + card.data.video}
                className={`card-video ${!videoLoaded && 'not-loaded'}`}
                playsInline
                autoPlay
                muted
                onMouseOver={(event: any) => event.target.play()}
                onLoadedData={() => setVideoLoaded(true)}
              ></video>
            </Fragment>
          )}
          {!videoLoaded && (
            <ContentLoader width={160} height={250} backgroundColor='#14192e' foregroundColor='#1a203c'>
              <rect x='1' y='1' rx='1' ry='1' width='160' height='250' />
            </ContentLoader>
          )}
          <Stamp state={outcome} />
        </div>
      )}
      {roundIndex === -1 && (
        <div className='img-container'>
          {deck[position] && (
            <img
              src={NFT_SOURCE_URL + deck[position].data.backimg}
              className={`card-img`}
              alt='Background of Nordic Legend Card'
            ></img>
          )}
        </div>
      )}

      <div className={`is-chosen mt-4 flex-center ${shine === 'OPPONENT_CARD' && roundIndex === -1 && 'active'}`}>
        {roundIndex !== -1 && roundIndex + 1}
      </div>
    </div>
  );
};

export default CardOpponent;
