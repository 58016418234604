import React, { Fragment } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import BackToTopBtn from '../../components/layout/BackToTopBtn';
import Footer from '../../components/layout/Footer';
import BuyButton from '../../components/nordic-games/BuyButton';

import { RootState } from '../../redux/store';
import { userSetLoginOption } from '../../redux/user/userActions';
import CustomLazyload from '../../utils/CustomLazyload';
import { NordicCategories } from '../../utils/NordicHelpers';

import CardWallet from '../Cards/CardWallet';
import DashboardTopElements from '../Dashboard/DashboardTopElements';

interface WalletLayoutProps {}

const WalletLayout: React.FC<WalletLayoutProps> = () => {
  const { cards, user } = useSelector((state: RootState) => state);
  const dispatch = useDispatch();

  return (
    <Fragment>
      <div className='wallet-layout background-welcome pt-5'>
        <div className='pb-3'>
          <DashboardTopElements />
        </div>
        <div className='text-center white-color size-48 size-32-md font-family-1 pt-5 text-uppercase'>
          Your Nordic Game Wallet
          <div className='text-center py-4'>
            {!user.isLogged ? (
              <BuyButton
                text='LOGIN'
                onClick={() => {
                  dispatch(userSetLoginOption(true));
                }}
                classNameExtension={'size-big'}
              />
            ) : (
              <BuyButton text='EXTEND YOUR WALLET NOW' />
            )}
          </div>
        </div>
        {!cards.isLoading && !user.isLoading && (
          <div className='container'>
            <div className='pt-5'>
              {NordicCategories.map((el, index) => {
                return (
                  <OneCategory
                    key={index}
                    title={el.title}
                    data={cards.data.filter((x: any) => x.category === el.id)}
                  />
                );
              })}
              <div className='text-center pb-5'>
                <BuyButton text='EXTEND YOUR WALLET NOW' />
              </div>
            </div>
            <div className='py-5'></div>
            <div className='py-5'></div>
          </div>
        )}
      </div>
      <BackToTopBtn />
      <Footer />
    </Fragment>
  );
};

export default WalletLayout;

interface OneCategoryProps {
  title: string;
  data: any;
}
const OneCategory: React.FC<OneCategoryProps> = ({ title, data }) => {
  return (
    <div className='one-category'>
      <div className='size-40 white-color text-center font-family-1'>{title}</div>
      <hr className='shared-hr m-auto' />
      <div className='row py-5'>
        {data.map((el: any, key: any) => {
          return (
            <Fragment key={key}>
              <div className='col-xl-3 col-lg-4 col-md-6 text-center pb-5'>
                <CustomLazyload>
                  <CardWallet cardElement={el} />
                </CustomLazyload>
              </div>
            </Fragment>
          );
        })}
      </div>
    </div>
  );
};
