import React, { Fragment, useEffect, useState } from 'react';
import PointsHorizontal from '../../components/nordic-points/PointsHorizontal';
import { PROPERTY_AF, PROPERTY_DEV, ROUND_LOST, ROUND_TIE, ROUND_WON } from '../../Constants';
import { getPropertyImage, getPropertyIndex, moveOfEnemy, setHoverText } from '../../utils/NordicHelpers';
import CardShowdown from '../Cards/CardShowdown';

interface GameOutcomeProps {
  state: {
    opponentCard: any;
    scoresOpponent: any;
    ownCard: any;
    scoresOwn: any;
    round: number;
    outcome: string;
    move: string;
    isLoaded: boolean;
  };
}

const GameOutcome: React.FC<GameOutcomeProps> = ({ state }) => {
  const { scoresOwn, scoresOpponent, ownCard, opponentCard, isLoaded, outcome, move } = state;

  const [ownSchema, setOwnSchema] = useState('');
  const [opponentSchema, setOpponentSchema] = useState('');
  const [points, setPoints] = useState([0, 0]);

  const [propertySrc, setPropertySrc] = useState('');
  const [propertyText, setPropertyText] = useState('');
  const [propertySrc1, setPropertySrc1] = useState('');
  const [propertyText1, setPropertyText1] = useState('');
  const [outcomeText, setOutcomeText] = useState('');

  useEffect(() => {
    if (ownCard !== null && opponentCard !== null) {
      setOwnSchema(ownCard.data.rarity);

      setOpponentSchema(opponentCard.data.rarity);
    }
  }, [ownCard, opponentCard, move]);

  useEffect(() => {
    if (scoresOwn[0] !== 0 && scoresOpponent[0] !== 0 && move !== '' && outcome !== '') {
      setPoints([scoresOwn[getPropertyIndex(move, true)], scoresOpponent[getPropertyIndex(move, false)]]);
      setPropertySrc(getPropertyImage(move));
      setPropertyText(setHoverText(move));
      if (move === PROPERTY_DEV) {
        setPropertySrc1(getPropertyImage(PROPERTY_AF));
        setPropertyText1(setHoverText(PROPERTY_AF));
      } else if (move === PROPERTY_AF) {
        setPropertySrc1(getPropertyImage(PROPERTY_DEV));
        setPropertyText1(setHoverText(PROPERTY_DEV));
      }
      if (outcome === ROUND_WON) {
        setOutcomeText('YOU WON');
      } else if (outcome === ROUND_LOST) {
        setOutcomeText('YOU LOST');
      } else {
        setOutcomeText('TIE GAME');
      }
    }
  }, [scoresOwn, scoresOpponent, move, outcome]);

  const isAF_vs_DEV = move === PROPERTY_AF || move === PROPERTY_DEV;

  return (
    <div className='game-outcome'>
      {isLoaded && (
        <div className='row'>
          <div className='col-3 points-container'>
            <Fragment>
              <div
                className={`text-center size-48 text-center  ${outcome === ROUND_WON && 'primary-color'} 
              ${outcome === ROUND_LOST && 'red-color'} ${outcome === ROUND_TIE && 'white-color'}  py-4`}
              >
                {points[0]}
              </div>

              <PointsHorizontal
                scores={scoresOwn}
                schema={ownSchema}
                classNameExtension='points-horizontal--game-outcome'
                highlightProperty={move}
              />
            </Fragment>
          </div>

          <div className='col-2 test'>
            <div className=' py-4'>
              <CardShowdown card={ownCard} own={true} outcome={outcome} />
            </div>
          </div>
          <div className='col-2 pt-5 font-family-1'>
            {isAF_vs_DEV ? (
              <Fragment>
                <div className={`img-container flex-center`}>
                  {propertySrc !== '' && <img src={propertySrc} alt='' className='img-property-extra' />}
                </div>
                <div className='value-explain white-color size-16 text-center pt-3'>{propertyText}</div>

                <div className='text-center white-color py-5'>vs</div>
                <div className={`img-container flex-center`}>
                  {propertySrc !== '' && <img src={propertySrc1} alt='' className='img-property-extra' />}
                </div>
                <div className='value-explain white-color size-16 text-center py-5'>{propertyText1}</div>
              </Fragment>
            ) : (
              <Fragment>
                <div className={`img-container flex-center`}>
                  {propertySrc !== '' && <img src={propertySrc} alt='' className='img-property' />}
                </div>
                <div className='value-explain white-color size-24 text-center py-3'>{propertyText}</div>
              </Fragment>
            )}
          </div>

          <Fragment>
            <div className='col-2'>
              <div className=' py-4'>
                <CardShowdown card={opponentCard} own={false} outcome={outcome} />
              </div>
            </div>
            <div className='col-3 points-container'>
              <div
                className={`text-center size-48 text-center  ${outcome === ROUND_LOST && 'primary-color'} 
              ${outcome === ROUND_WON && 'red-color'} ${outcome === ROUND_TIE && 'white-color'} py-4`}
              >
                {points[1]}
              </div>
              <PointsHorizontal
                scores={scoresOpponent}
                schema={opponentSchema}
                classNameExtension='points-horizontal--game-outcome'
                highlightProperty={moveOfEnemy(move)}
              />
            </div>
          </Fragment>

          <div className='col-12 pt-5'>
            <div
              className={`text-center size-48 text-center ${outcome === ROUND_WON && 'primary-color'} 
              ${outcome === ROUND_LOST && 'red-color'} ${outcome === ROUND_TIE && 'white-color'} py-4`}
            >
              - {outcomeText} -
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default GameOutcome;
