import { defaultState } from "../reduxStates";
import { ContractActionModel, ContractActionType } from "./contractModel";

export const contractReducer = (state = defaultState.contract, action: ContractActionModel) => {
  switch (action.type) {
    case ContractActionType.REQUEST_START:
      return {
        ...state,
        isLoading: true,
      };
    case ContractActionType.SHOW_TRANSFER_NOW:
      return {
        ...state,
        transferNow: action.payload,
        isLoading: false,
      };

    case ContractActionType.REQUEST_FAIL:
      return {
        ...defaultState.contract,
        isLoading: false,
      };

    default:
      return state;
  }
};
