import React, { Fragment, useEffect, useRef, useState } from 'react';
import { useDrag } from 'react-dnd';
import { useDispatch, useSelector } from 'react-redux';
import PointsHorizontal from '../../components/nordic-points/PointsHorizontal';
import { DRAG_DROP_FROM_SCROLL, SCHEMA_COMMON, SCHEMA_EPIC, SCHEMA_RARE, NFT_SOURCE_URL } from '../../Constants';
import { deckSetNext } from '../../redux/deck/DeckActions';
import { RootState } from '../../redux/store';
import ContentLoader from 'react-content-loader';

import { cardsGetCurrentSchema, cardsGetScores, useCountCards } from './CardsService';
import { useWindowFocus } from '../../utils/customHooks';
import { isSafari } from '../../utils/helpers';

interface CardScrollProps {
  cardElement: any;
}

const CardScroll: React.FC<CardScrollProps> = ({ cardElement }) => {
  const reduxState = useSelector((state: RootState) => state);

  const dispatch = useDispatch();
  const { templates } = reduxState.user;
  const cards = reduxState.cards;
  const { chosen } = reduxState.deck;
  const [state, setState] = useState<any | null>(null);
  const [schema, setSchema] = useState<string>(SCHEMA_COMMON);
  const [templateId, setTemplateId] = useState<string>(cardElement.Common.template_id);
  const [scores, setScores] = useState<any | null>(null);
  const count = useCountCards(templates, reduxState.user.id, cardElement);

  const windowFocus = useWindowFocus();
  const videoRef = useRef<HTMLDivElement>(null);

  const [isAvailable, setIsAvailable] = useState<boolean>(false);
  const [videoLoaded, setVideoLoaded] = useState<boolean>(false);
  const [videoClass, setVideoClass] = useState('');

  useEffect(() => {
    const current = cardsGetCurrentSchema(templateId, schema, cards);
    if (current) {
      setScores(cardsGetScores(current));
      setState(current.immutable_data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [templateId, cards, schema]);

  useEffect(() => {
    if (schema === SCHEMA_COMMON) {
      if (count[0] > 0) {
        setIsAvailable(true);
        setVideoClass('grabbing');
      } else {
        setIsAvailable(false);
        setVideoClass('blur');
      }
    } else if (schema === SCHEMA_RARE) {
      if (count[1] > 0) {
        setIsAvailable(true);
        setVideoClass('grabbing');
      } else {
        setIsAvailable(false);
        setVideoClass('blur');
      }
    } else if (schema === SCHEMA_EPIC) {
      if (count[2] > 0) {
        setIsAvailable(true);
        setVideoClass('grabbing');
      } else {
        setIsAvailable(false);
        setVideoClass('blur');
      }
    }
  }, [chosen, schema, count]);

  const [, drag] = useDrag(
    () => ({
      type: DRAG_DROP_FROM_SCROLL,
      item: { schema: schema, templateId: templateId },
      collect: (monitor) => ({
        isDragging: !!monitor.isDragging(),
      }),
      canDrag: isAvailable,
    }),
    [isAvailable, schema, templateId]
  );

  useEffect(() => {
    setVideoLoaded(false);
  }, [schema]);

  useEffect(() => {
    if (windowFocus && videoLoaded && videoRef.current && isSafari()) {
      const video = videoRef.current.querySelector('video');
      if (video) {
        video.play();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [windowFocus]);

  const cardToDeck = () => {
    if (isAvailable === true) {
      dispatch(deckSetNext(schema, templateId));
    }
  };

  return (
    <div className='card-scroll pb-3'>
      {state && (
        <Fragment>
          <div className='numbers-container py-2'>
            <div
              className={`size-18 number ${schema === SCHEMA_COMMON && 'active'}`}
              onClick={() => {
                setSchema(SCHEMA_COMMON);
                setTemplateId(cardElement.Common.template_id);
              }}
            >
              {count[0]}
            </div>
            <div className={`size-18 number py-1`}>-</div>
            <div
              className={`size-18 number silver  ${schema === SCHEMA_RARE && 'active'}`}
              onClick={() => {
                setSchema(SCHEMA_RARE);
                setTemplateId(cardElement.Rare.template_id);
              }}
            >
              {count[1]}
            </div>
            <div className={`size-18 number py-1`}>-</div>
            <div
              className={`size-18 number gold  ${schema === SCHEMA_EPIC && 'active'}`}
              onClick={() => {
                setSchema(SCHEMA_EPIC);
                setTemplateId(cardElement.Epic.template_id);
              }}
            >
              {count[2]}
            </div>
          </div>
          <div className='video-container' onClick={cardToDeck} ref={videoRef}>
            <video
              ref={drag}
              src={NFT_SOURCE_URL + state.video}
              className={`card-video  ${videoClass} ${!videoLoaded && 'not-loaded'}`}
              playsInline
              autoPlay
              muted
              onMouseOver={(event: any) => event.target.play()}
              onLoadedData={() => setVideoLoaded(true)}
            ></video>

            {!videoLoaded && (
              <ContentLoader width={100} height={160} backgroundColor='#14192e' foregroundColor='#1a203c'>
                <rect x='1' y='1' rx='1' ry='1' width='100' height='160' />
              </ContentLoader>
            )}
          </div>

          <div className='points-container'>
            <PointsHorizontal
              scores={scores}
              showBar={false}
              schema={schema}
              classNameExtension={'card-scroll--points'}
            />
          </div>
        </Fragment>
      )}
    </div>
  );
};

export default CardScroll;
