import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";

interface UserOwnsProps {}

const UserOwns: React.FC<UserOwnsProps> = () => {
  const user = useSelector((state: RootState) => state.user);
  return (
    <div className='user-owns'>
      <div className='size-16 bold text-center'> YOU OWN: </div>
      <div className='dash size-16 bold  text-center'> - </div>
      <div className='size-16 bold text-center'>
        {user.isLogged ? (
          <span>
            {user.nlsoneanicom} /{" "}
            <span className='number silver'> {user.nlsoneanirar}</span> /{" "}
            <span className='number gold'> {user.nlsoneaniepi}</span>
          </span>
        ) : (
          <span> - / - / - </span>
        )}
      </div>
      <div className='size-16 bold text-center pt-2'> TOTAL: </div>
      <div className='dash size-16 bold text-center'> - </div>
      <div className='size-16 bold text-center'>
        {user.isLogged ? (
          <span>
            {user.nordic_count} {user.nordic_count === 1 ? "Card" : "Cards"}
          </span>
        ) : (
          <span> - Cards </span>
        )}
      </div>
    </div>
  );
};

export default UserOwns;
