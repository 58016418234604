import React, { Fragment, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import PointsHorizontal from '../../components/nordic-points/PointsHorizontal';
import { SCHEMA_COMMON, SCHEMA_EPIC, SCHEMA_RARE, NFT_SOURCE_URL, URL_LTG_WEBSITE } from '../../Constants';
import { RootState } from '../../redux/store';

import { cardsGetCurrentSchema, cardsGetScores, useCountCards } from './CardsService';
import ContentLoader from 'react-content-loader';
import { useWindowFocus } from '../../utils/customHooks';
import { isSafari } from '../../utils/helpers';

interface CardWalletProps {
  cardElement: any;
}

const CardWallet: React.FC<CardWalletProps> = ({ cardElement }) => {
  const { user, cards } = useSelector((state: RootState) => state);
  const windowFocus = useWindowFocus();
  const videoRef = useRef<HTMLVideoElement>(null);
  const { templates } = user;
  const [state, setState] = useState<any | null>(null);
  const [schema, setSchema] = useState<string>(SCHEMA_COMMON);
  const [templateId, setTemplateId] = useState<string>(cardElement.Common.template_id);
  const [scores, setScores] = useState<any | null>(null);
  const count = useCountCards(templates, user.id, cardElement);
  const [videoLoaded, setVideoLoaded] = useState<boolean>(false);
  useEffect(() => {
    const current = cardsGetCurrentSchema(templateId, schema, cards);
    if (current) {
      setScores(cardsGetScores(current));
      setState(current.immutable_data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [templateId, cards, schema]);

  useEffect(() => {
    if (windowFocus && videoLoaded && videoRef.current && isSafari()) {
      videoRef.current.play();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [windowFocus]);

  useEffect(() => {
    setVideoLoaded(false);
  }, [schema]);

  return (
    <div className='card-wallet'>
      {state && (
        <Fragment>
          <div className='video-container'>
            <video
              ref={videoRef}
              onClick={() => {
                window.open(URL_LTG_WEBSITE + '/details/' + cardElement.id);
              }}
              src={NFT_SOURCE_URL + state.video}
              className={`card-video ${!videoLoaded && 'not-loaded'}`}
              playsInline
              autoPlay
              muted
              onMouseOver={(event: any) => event.target.play()}
              onLoadedData={() => setVideoLoaded(true)}
            ></video>

            {!videoLoaded && (
              <ContentLoader width={220} height={350} backgroundColor='#14192e' foregroundColor='#1a203c'>
                <rect x='1' y='1' rx='1' ry='1' width='220' height='350' />
              </ContentLoader>
            )}
          </div>
          <div className='numbers-container py-2'>
            <span
              className={`size-32 number ${schema === SCHEMA_COMMON && 'active'}`}
              onClick={() => {
                setSchema(SCHEMA_COMMON);
                setTemplateId(cardElement.Common.template_id);
              }}
            >
              {user.isLogged ? count[0] : '-'}
            </span>
            <span className={`size-32 number mx-3`}>/</span>
            <span
              className={`size-32 number silver  ${schema === SCHEMA_RARE && 'active'}`}
              onClick={() => {
                setSchema(SCHEMA_RARE);
                setTemplateId(cardElement.Rare.template_id);
              }}
            >
              {user.isLogged ? count[1] : '-'}
            </span>
            <span className={`size-32 number mx-3`}>/</span>
            <span
              className={`size-32 number gold  ${schema === SCHEMA_EPIC && 'active'}`}
              onClick={() => {
                setSchema(SCHEMA_EPIC);
                setTemplateId(cardElement.Epic.template_id);
              }}
            >
              {user.isLogged ? count[2] : '-'}
            </span>
          </div>

          <div className='points-container'>
            <PointsHorizontal scores={scores} schema={schema} />
          </div>
        </Fragment>
      )}
    </div>
  );
};

export default CardWallet;
