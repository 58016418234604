import './theme/main.scss';
import React, { useEffect, useState } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

import Loader from './components/layout/Loader';
import onLoad from './utils/onLoad';
import { getURLParam, timeout } from './utils/helpers';
import Message from './components/layout/Message';

import { useDispatch, useSelector } from 'react-redux';
import { handlerGetTextServer } from './redux/handler/handlerActions';
import ScrollToTop from './utils/ScrollToTop';
import BuyOptions from './components/nordic-games/BuyOptions';

import { getTemplates } from './redux/cards/cardsActions';
import { userIsLogged } from './redux/user/userActions';
import { RootState } from './redux/store';

import LoginOptions from './components/nordic-games/LoginOptions';
import FullScreenWaiting from './components/layout/FullScreenWaiting';
import MainRoutes from './routes/MainRoutes';
import { useUserIsLogged } from './utils/useSockets';

onLoad();

function App() {
  const { user, cards } = useSelector((state: RootState) => state);

  const dispatch = useDispatch();
  const [loaderSafari, setLoaderSafari] = useState(false);
  const [isWaiting, setisWaiting] = useState(true);

  const onLoadText = async () => {
    const paramValue = getURLParam('onLoadText');

    if (paramValue !== '') {
      dispatch(handlerGetTextServer(paramValue));
    }
  };

  useEffect(() => {
    dispatch(getTemplates());
    dispatch(userIsLogged());
    // eslint-disable-next-line
  }, []);

  useUserIsLogged();

  useEffect(() => {
    const fct = async () => {
      setLoaderSafari(true);
      setisWaiting(false);
      await timeout(400);
      await onLoadText();
    };
    if (!user.isLoading && cards.data.length > 0) {
      fct();
    }

    // eslint-disable-next-line
  }, [user.isLoading, cards.data]);

  // Track page view

  return (
    <BrowserRouter>
      <ScrollToTop>
        <div id='preload' className='preload__'>
          {isWaiting && <FullScreenWaiting />}

          {!isWaiting && (
            <div className='mobile-overflow-wrapper'>
              <Loader loadingSafari={loaderSafari} />
              <Message />
              <BuyOptions />
              <LoginOptions />
              <Routes>
                <Route path='/*' element={<MainRoutes />} />
              </Routes>
            </div>
          )}
        </div>
      </ScrollToTop>
    </BrowserRouter>
  );
}

export default App;
