export enum UserActionType {
  REQUEST_START = "USER_REQUEST_START",
  LOGIN_SUCCESS = "USER_LOGIN_SUCCESS",
  SET_BUY_OPTION = "USER_SET_BUY_OPTION",
  SET_LOGIN_OPTION = "USER_SET_LOGIN_OPTION",
  LOGOUT_SUCCESS = "USER_LOGOUT_SUCCESS",
  REQUEST_FAIL = "USER_REQUEST_FAIL",
  LOAD_USER = "USER_LOAD_USER",
  UPDATE_GAME_STATUS = "USER_UPDATE_GAME_STATUS",
}

interface REQUEST_START {
  type: UserActionType.REQUEST_START;
}

interface LOGIN_SUCCESS {
  type: UserActionType.LOGIN_SUCCESS;
  payload: any;
}
interface SET_BUY_OPTION {
  type: UserActionType.SET_BUY_OPTION;
  payload: boolean;
}

interface LOGOUT_SUCCESS {
  type: UserActionType.LOGOUT_SUCCESS;
}

interface REQUEST_FAIL {
  type: UserActionType.REQUEST_FAIL;
}

interface LOAD_USER {
  type: UserActionType.LOAD_USER;
  payload: any;
}
interface UPDATE_GAME_STATUS {
  type: UserActionType.UPDATE_GAME_STATUS;
  payload: any;
}

interface LOGIN_OPTION {
  type: UserActionType.SET_LOGIN_OPTION;
  payload: boolean;
}

export type UserActionModel =
  | REQUEST_START
  | LOGIN_SUCCESS
  | LOGOUT_SUCCESS
  | REQUEST_FAIL
  | SET_BUY_OPTION
  | LOAD_USER
  | UPDATE_GAME_STATUS
  | LOGIN_OPTION;
