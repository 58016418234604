import React, { Fragment } from "react";
import { useSelector } from "react-redux";

import { RootState } from "../../redux/store";
import CardOpponent from "../Cards/CardOpponent";

interface GameOpponentCardsProps {}

const GameOpponentCards: React.FC<GameOpponentCardsProps> = () => {
  const { deck } = useSelector((state: RootState) => state.game);
  return (
    <Fragment>
      <div className='game-opponent-cards'>
        <div className='one-card-container'>{deck[0] && <CardOpponent position={0} />}</div>
        <div className='one-card-container'>{deck[1] && <CardOpponent position={1} />}</div>
        <div className='one-card-container'>{deck[2] && <CardOpponent position={2} />}</div>
        <div className='one-card-container'>{deck[3] && <CardOpponent position={3} />}</div>
        <div className='one-card-container'>{deck[4] && <CardOpponent position={4} />}</div>
      </div>
    </Fragment>
  );
};

export default GameOpponentCards;
