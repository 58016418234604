import React from 'react';
import {
  PROPERTY_AAF,
  PROPERTY_AF,
  PROPERTY_BK,
  PROPERTY_DEV,
  PROPERTY_PIL,
  PROPERTY_RSI,
  PROPERTY_SI,
} from '../../Constants';
import { setHoverText } from '../../utils/NordicHelpers';
import HoverTippy from '../gadgets/HoverTippy';

interface PointsHorizontalProps {
  scores: any;
  showBar?: boolean;
  schema: string;
  classNameExtension?: string;
  highlightProperty?: string;
}

const PointsHorizontal: React.FC<PointsHorizontalProps> = ({
  scores,
  showBar = true,
  schema,
  classNameExtension = '',
  highlightProperty = '',
}) => {
  const isThisProperty = (property: string) => {
    if (highlightProperty === property) {
      return true;
    } else {
      return false;
    }
  };
  return (
    <div className={`points-horizontal ${classNameExtension}`}>
      <HoverTippy
        hoverElement={
          <OneProperty
            title={'AAF'}
            score={scores ? scores[0] : '--'}
            showbar={showBar}
            schema={schema}
            highlight={isThisProperty(PROPERTY_AAF)}
          />
        }
        classNameExtension='tippy-hover-points'
      >
        <span>{setHoverText(PROPERTY_AAF)}</span>
      </HoverTippy>
      <HoverTippy
        hoverElement={
          <OneProperty
            title={'PIL'}
            score={scores ? scores[1] : '--'}
            showbar={showBar}
            schema={schema}
            highlight={isThisProperty(PROPERTY_PIL)}
          />
        }
        classNameExtension='tippy-hover-points'
      >
        <span>{setHoverText(PROPERTY_PIL)}</span>
      </HoverTippy>
      <HoverTippy
        hoverElement={
          <OneProperty
            title={'BK'}
            score={scores ? scores[2] : '--'}
            showbar={showBar}
            schema={schema}
            highlight={isThisProperty(PROPERTY_BK)}
          />
        }
        classNameExtension='tippy-hover-points'
      >
        <span>{setHoverText(PROPERTY_BK)}</span>
      </HoverTippy>
      <HoverTippy
        hoverElement={
          <OneProperty
            title={'SI'}
            score={scores ? scores[3] : '--'}
            showbar={showBar}
            schema={schema}
            highlight={isThisProperty(PROPERTY_SI)}
          />
        }
        classNameExtension='tippy-hover-points'
      >
        <span>{setHoverText(PROPERTY_SI)}</span>
      </HoverTippy>
      <HoverTippy
        hoverElement={
          <OneProperty
            title={'AF'}
            score={scores ? scores[4] : '--'}
            showbar={showBar}
            schema={schema}
            highlight={isThisProperty(PROPERTY_AF)}
          />
        }
        classNameExtension='tippy-hover-points'
      >
        <span>{setHoverText(PROPERTY_AF)}</span>
      </HoverTippy>
      <HoverTippy
        hoverElement={
          <OneProperty
            title={'DEV'}
            score={scores ? scores[5] : '--'}
            showbar={showBar}
            schema={schema}
            highlight={isThisProperty(PROPERTY_DEV)}
          />
        }
        classNameExtension='tippy-hover-points'
      >
        <span>{setHoverText(PROPERTY_DEV)}</span>
      </HoverTippy>
      <HoverTippy
        hoverElement={
          <OneProperty
            title={'RSI'}
            score={scores ? scores[6] : '--'}
            showbar={showBar}
            schema={schema}
            highlight={isThisProperty(PROPERTY_RSI)}
          />
        }
        classNameExtension='tippy-hover-points'
      >
        <span>{setHoverText(PROPERTY_RSI)}</span>
      </HoverTippy>
    </div>
  );
};

export default PointsHorizontal;

interface OnePropertyProps {
  title: string;
  schema: string;
  score?: string;
  highlight?: boolean;
  showbar: boolean;
}
const OneProperty: React.FC<OnePropertyProps> = ({ title, schema, score = '--', showbar, highlight = false }) => {
  const widthTransform = (score: string) => {
    if (score === '--') {
      return '0%';
    }
    const val = parseInt(score);
    return (val / 120) * 100 + '%';
  };
  return (
    <div className={`one-point ${highlight && 'highlight-point'}`}>
      <div className='name'>{title}:</div>
      <div
        className={`points number ${schema === 'Rare' && !highlight && 'silver'} ${
          schema === 'Epic' && !highlight && 'gold'
        }`}
      >
        {score}
      </div>
      {showbar && (
        <div className='bar-wrap'>
          <div className='bar' style={{ width: `${widthTransform(score)}` }}></div>
        </div>
      )}
    </div>
  );
};
