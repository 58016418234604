import React, { Fragment } from 'react';
import SpringTransition from '../../utils/SpringTransition';
import { useIsWaiting } from '../../utils/useSockets';

interface DashboardMessageProps {}

const DashboardMessage: React.FC<DashboardMessageProps> = () => {
  const isMessage = useIsWaiting();
  return (
    <Fragment>
      {isMessage && (
        <SpringTransition isLoaded={isMessage} type={'fadeIn'}>
          <div className='dashboard-message'>
            <div className='waiting mb-4'>
              <span className='waiting-text font-family-1 size-24 '>Hurry Up! Someone is waiting in the Arena</span>
            </div>
          </div>
        </SpringTransition>
      )}
    </Fragment>
  );
};

export default DashboardMessage;
