import { ErrorModel } from "../Models";

export enum ValidationType {
  NOT_EMPTY = "NOT_EMPTY",
  EMAIL = "EMAIL",
  PHONE = "PHONE",
  PLZ = "PLZ",
  PWD = "PWD",
  NULL = "NULL",
  LAW = "LAW",
  BIRTHDAY = "BIRTHDAY",
}

interface LAW_MODEL {
  type: ValidationType.LAW;
  value: boolean;
}
interface BIRTHDAY_MODEL {
  type: ValidationType.BIRTHDAY;
  value: string;
}
interface NOT_EMPTY_MODEL {
  type: ValidationType.NOT_EMPTY;
  value: string;
}

interface EMAIL_MODEL {
  type: ValidationType.EMAIL;
  value: string;
}

interface PHONE_MODEL {
  type: ValidationType.PHONE;
  value: string;
}

interface PLZ_MODEL {
  type: ValidationType.PLZ;
  value: string;
}
interface PWD_MODEL {
  type: ValidationType.PWD;
  value: string;
}
interface NULL_MODEL {
  type: ValidationType.NULL;
  value: any;
}

export type ValidationModel =
  | LAW_MODEL
  | NOT_EMPTY_MODEL
  | EMAIL_MODEL
  | PHONE_MODEL
  | PLZ_MODEL
  | PWD_MODEL
  | NULL_MODEL
  | BIRTHDAY_MODEL;

const law = (value: boolean): boolean => {
  return value;
};
const empty = (value: string): boolean => {
  if (value.trim() !== "") {
    return false;
  } else {
    return true;
  }
};
const email = (value: string): boolean => {
  const re =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  if (re.test(value.trim()) && value.trim() !== "") {
    return true;
  } else {
    return false;
  }
};

const phone = (value: string): boolean => {
  const re = /^(\(?\+?[0-9]*\)?)?[0-9_\- \(\)]*$/;
  const size = value.trim().length;

  if (re.test(value.trim()) && value.trim() !== "" && size > 8 && size < 20) {
    return true;
  } else {
    return false;
  }
};

const plz = (value: string): boolean => {
  let bool = true;
  function isNormalInteger(str: string) {
    var n = Math.floor(Number(str));
    return n !== Infinity && String(n) === str && n >= 0;
  }
  if (value === "") {
    bool = false;
    return false;
  }
  if (value.length !== 5) {
    bool = false;
  }
  if (!isNormalInteger(value)) {
    bool = false;
  }
  if (!bool) {
    return false;
  } else {
    return true;
  }
};

const pwd = (pwd: string): boolean => {
  if (pwd.length < 8) {
    return false;
  } else if (!/[0-9]/.test(pwd)) {
    return false;
  } else if (!/[A-Z]/.test(pwd)) {
    return false;
  } else if (!/[a-z]/.test(pwd)) {
    return false;
  } else {
    return true;
  }
};
const birthday = (date: string): boolean => {
  if (date === "" || date.length !== 10) {
    return false;
  } else {
    return true;
  }
};

export function validation(action: ValidationModel): ErrorModel {
  switch (action.type) {
    case ValidationType.LAW:
      return {
        isError: !law(action.value),
        text: !law(action.value) === true ? "Bitte stimme zu!" : "",
      };
    case ValidationType.BIRTHDAY:
      return {
        isError: !birthday(action.value),
        text:
          !birthday(action.value) === true
            ? "Bitte wähle ein gültiges Geburtsdatum"
            : "",
      };
    case ValidationType.NOT_EMPTY:
      return {
        isError: empty(action.value),
        text: empty(action.value) === true ? "Bitte fülle das Feld aus" : "",
      };
    case ValidationType.EMAIL:
      return {
        isError: !email(action.value),
        text:
          email(action.value) === false
            ? "Bitte wähle eine gültige E-Mail-Adresse"
            : "",
      };
    case ValidationType.PHONE:
      return {
        isError: !phone(action.value),
        text:
          phone(action.value) === false
            ? "Bitte wähle eine gültige Telefonnummer"
            : "",
      };
    case ValidationType.PLZ:
      return {
        isError: !plz(action.value),
        text:
          plz(action.value) === false
            ? "Bitte wähle eine gültige Postleitzahl"
            : "",
      };
    case ValidationType.PWD:
      return {
        isError: !pwd(action.value),
        text:
          pwd(action.value) === false
            ? "Bitte wähle ein Passwort mit mindestens 8 Zeichen, einer Zahl sowie einem Klein- und Großbuchstaben."
            : "",
      };
    case ValidationType.NULL:
      return {
        isError: false,
        text: "",
      };
    default:
      return {
        isError: false,
        text: "",
      };
  }
}
