import React, { Fragment } from 'react';
import { URL_KONVEX_WEBSITE, URL_LTG_WEBSITE } from '../../Constants';

const Footer = () => {
  var date = new Date();
  const year = date.getFullYear();

  return (
    <Fragment>
      <footer>
        <div className='z-container py-4'>
          <div className='row text-center'>
            <div className='col-12'>
              <div className='row'>
                <div className='col-xl-8 order-xl-4'>
                  <div className='row'>
                    <div className='col-md-3 pb-2'>
                      <a href={URL_LTG_WEBSITE + `/privacy-policy`}> Privacy Policy </a>
                    </div>
                    <div className='col-md-3 pb-2'>
                      <a href={URL_LTG_WEBSITE + `/imprint`}>Imprint </a>
                    </div>
                    <div className='col-md-3 pb-2'>
                      <a href={URL_LTG_WEBSITE + `/about-us`}>About Us</a>
                    </div>
                    <div className='col-md-3 pb-2'>
                      <a href={URL_LTG_WEBSITE + `/contact`}>Contact</a>
                    </div>
                  </div>
                </div>

                <div className='col-xl-4 order-xl-1 pt-3 pt-xl-0'>
                  <div className='copyright text-center'>
                    <span>{year}</span> {'\u00A9'} LOST TREASURES GALAXY
                  </div>
                  <div className=' text-center created-by pt-2'>
                    powered by
                    {'  '}{' '}
                    <a href={URL_KONVEX_WEBSITE} target='_blank' rel='noreferrer' className='link-konvex bold'>
                      konvex-IT.de
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </Fragment>
  );
};

export default Footer;
