import { defaultState } from '../reduxStates';
import { DeckActionModel, DeckActionType } from './DeckModel';
export const deckReducer = (state = defaultState.deck, action: DeckActionModel) => {
  switch (action.type) {
    case DeckActionType.REQUEST_START:
      return {
        ...state,
        isLoading: false,
      };
    case DeckActionType.REQUEST_FAIL:
      return {
        ...state,
        isLoading: false,
      };
    case DeckActionType.NEXT:
      return {
        ...state,
        chosen: state.chosen.map((val: any, i: number) => (i === action.payload.postiton ? action.payload.data : val)),
        isLoading: false,
      };
    case DeckActionType.DELETE:
      return {
        ...state,
        chosen: state.chosen.map((val: any, i: number) => (i === action.payload ? null : val)),
        isLoading: false,
      };

    case DeckActionType.LOGOUT:
      return {
        ...state,
        chosen: state.chosen.map((val: any, i: number) => null),
        friend: '',
        isLoading: false,
      };
    case DeckActionType.SET_FRIEND:
      return {
        ...state,
        friend: action.payload,
        isLoading: false,
      };
    default:
      return state;
  }
};
