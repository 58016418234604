import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import BuyButton from '../../components/nordic-games/BuyButton';
import { GAME_WAITING_CONTRACTS_BOTH, GAME_WAITING_CONTRACTS_OWN } from '../../Constants';
import { contractShowTransferNow, contractTransactionUser } from '../../redux/contract/contractActions';

import { RootState } from '../../redux/store';
import { timeout } from '../../utils/helpers';

interface GameTransferNowProps {}

const GameTransferNow: React.FC<GameTransferNowProps> = () => {
  const { handlers, contract, user } = useSelector((state: RootState) => state);
  const dispatch = useDispatch();
  const [messageActive, setMessageActive] = useState(false);
  const { isMessage } = handlers;
  const { transferNow } = contract;

  useEffect(() => {
    let mounted = true;
    const fct = async () => {
      await timeout(400);
      if (!mounted) return;
      setMessageActive(transferNow);
    };

    fct();
    return () => {
      mounted = false;
    };
  }, [transferNow]);

  useEffect(() => {
    if (user.gameStatus === GAME_WAITING_CONTRACTS_BOTH || user.gameStatus === GAME_WAITING_CONTRACTS_OWN) {
      dispatch(contractShowTransferNow(true));
    } else {
      dispatch(contractShowTransferNow(false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user.gameStatus]);
  return (
    <Fragment>
      <div className='game-transfer-now'>
        <div className={`extra-background ${transferNow && !isMessage && 'active'}`}>
          <div className={`message game-transfer-now ${messageActive && !isMessage && 'active'}`}>
            <div className='content' style={{ maxWidth: '1000px' }}>
              <div className='close'>
                <i className='far fa-times-circle' onClick={() => dispatch(contractShowTransferNow(false))}></i>
                <div className='primary-color size-40 text-uppercase pb-4 text-center'>Your opponent is here</div>
                <div className='text-center white-color size-20 pb-5 text-uppercase'>
                  Transfer your Deck now to our Escrow wallet so the game can start
                </div>
                <div className='text-center pb-4'>
                  <BuyButton
                    classNameExtension='size-big'
                    text='TRANSFER NOW'
                    onClick={() => {
                      dispatch(contractShowTransferNow(false));
                      dispatch(contractTransactionUser());
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default GameTransferNow;
