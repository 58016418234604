import { GAME_NON_EXIST, TIMER_NONE } from '../Constants';

export type initialStateModel = {
  handlers: {
    isMessage: boolean;
    message: string;
    cookieStatus: boolean;
    isHTML: boolean;
    isLoading: boolean;
  };
  user: {
    id: string;
    userStatus: string;
    gameStatus: string;
    timerStatus: string;
    lastRoundSeen: boolean;
    nlsoneanicom: number;
    nlsoneanirar: number;
    nlsoneaniepi: number;
    ltgcoin: number;
    nlcoin: number;
    nordic_count: number;
    templates: [];
    isLogged: boolean;
    isBuyOptions: boolean;
    isLoginOptions: boolean;
    isLoading: boolean;
  };
  cards: {
    data: [];
    common: [];
    rare: [];
    epic: [];
    ltgCoin: [];
    nlCoin: [];
    isLoading: boolean;
  };
  deck: {
    chosen: any;
    friend: string;
    isLoading: boolean;
  };
  game: {
    round: number;
    playerType: string;
    nextMove: boolean;
    ownPlayPositions: any;
    opponentPlayPositions: any;
    outcomes: any;
    moves: any;
    //
    deck: any;
    opponentName: string;
    //
    currentCard: number;
    currentOpponentCard: number;
    currentProperty: string;
    isLoading: boolean;
  };

  contract: {
    transferNow: boolean;
    isLoading: boolean;
  };
};

export const defaultState: initialStateModel = {
  handlers: {
    isMessage: false,
    message: 'There is a problem right now....',
    cookieStatus: false,
    isHTML: false,
    isLoading: false,
  },
  user: {
    id: '',
    userStatus: '',
    gameStatus: GAME_NON_EXIST,
    timerStatus: TIMER_NONE,
    lastRoundSeen: true,
    nlsoneanicom: 0,
    nlsoneanirar: 0,
    nlsoneaniepi: 0,
    ltgcoin: 0,
    nlcoin: 0,
    nordic_count: 0,
    templates: [],
    isBuyOptions: false,
    isLoginOptions: false,
    isLogged: false,
    isLoading: true,
  },
  cards: {
    data: [],
    common: [],
    rare: [],
    epic: [],
    ltgCoin: [],
    nlCoin: [],
    isLoading: false,
  },
  deck: {
    chosen: [null, null, null, null, null],
    friend: '',
    isLoading: false,
  },
  game: {
    round: 0,
    playerType: '',
    nextMove: false,
    ownPlayPositions: [-1, -1, -1, -1, -1],
    opponentPlayPositions: [-1, -1, -1, -1, -1],
    outcomes: ['', '', '', '', ''],
    moves: ['', '', '', '', ''],
    //
    deck: [null, null, null, null, null],
    opponentName: '',
    //
    currentCard: -1,
    currentOpponentCard: -1,
    currentProperty: '',
    //
    isLoading: false,
  },
  contract: {
    transferNow: false,
    isLoading: false,
  },
};

export const initialState = {
  ...defaultState,
};
