import React from 'react';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { DndProvider } from 'react-dnd';

import DashboardCardScroll from './DashboardCardScroll';
import DashboardChoosenCards from './DashboardChoosenCards';
import DashboardGameStrip from './DashboardGameStrip';
import DashboardMessage from './DashboardMessage';

import DashboardLayout from './DashboardLayout';
import DashboardFriend from './DashboardFriend';
import StatsHome from '../Stats/StatsHome';

interface DashboardSelectionProps {}

const DashboardSelection: React.FC<DashboardSelectionProps> = () => {
  return (
    <DndProvider backend={HTML5Backend}>
      <DashboardLayout>
        <div className='py-5 mt-5'>
          <DashboardMessage /> <div className='py-3'></div>
          <div className='row'>
            <div className='col-3'>
              <DashboardCardScroll />
            </div>
            <div className='col-9 pt-5 dashboard-layout--col'>
              <div className='py-4'></div>
              <DashboardChoosenCards />
              <div className='py-5 my-2'></div>
              <DashboardGameStrip />
              <DashboardFriend classNameExtension='pt-5' />
              <div className='py-3'></div>
            </div>
            <div className='col-12'>
              <StatsHome />
            </div>
          </div>
        </div>
      </DashboardLayout>
    </DndProvider>
  );
};

export default DashboardSelection;
