import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import BuyButton from '../../components/nordic-games/BuyButton';
import { RootState } from '../../redux/store';
import { userSetLoginOption } from '../../redux/user/userActions';
import { apiGet } from '../../utils/helpers';

interface StatsHomeProps {}

const StatsHome: React.FC<StatsHomeProps> = () => {
  const dispatch = useDispatch();
  const { user } = useSelector((state: RootState) => state);
  const [state, setState] = useState({
    gamesNumber: 0,
    gamesWon: 0,
    gamesLost: 0,
    gamesTie: 0,
    gamesResult: 0,
    cardsWon: 0,
    cardsLost: 0,
    cardsResult: 0,
    isLoaded: false,
  });

  const [stateAll, setStateAll] = useState({
    recordGames: [],
    recordCards: [],
    isLoaded: false,
  });
  useEffect(() => {
    let mounted = true;
    const get = async () => {
      const { data, isError } = await apiGet('/stats_api/main_stats');
      if (isError) return;
      if (!mounted) return;
      setState({
        gamesNumber: data.games_number,
        gamesWon: data.games_won,
        gamesLost: data.games_lost,
        gamesTie: data.games_tie,
        gamesResult: data.games_result,
        cardsWon: data.cards_won,
        cardsLost: data.cards_lost,
        cardsResult: data.cards_result,
        isLoaded: true,
      });
    };
    const getAll = async () => {
      const { data, isError } = await apiGet('/stats_api/main_stats_all');
      if (isError) return;
      if (!mounted) return;
      setStateAll({
        recordGames: data.record_games,
        recordCards: data.record_cards,
        isLoaded: true,
      });
    };
    if (user.isLogged) {
      get();
    }
    getAll();
    return () => {
      mounted = false;
    };
  }, [user.isLogged, user.gameStatus, user.id]);
  return (
    <div className='stats-home font-family-1'>
      <div className='main-boxes-container'>
        <div className='sub-boxes-container'>
          <div className='primary-color size-32 bold pb-4'>General Stats</div>
          <div className='boxes-container'>
            {stateAll.isLoaded && (
              <Fragment>
                <div className='one-box white-color  mr-3'>
                  <div className='size-18 pt-2 bold'>Games won</div> <hr className='shared-hr ' />
                  {stateAll.recordGames.map((_el: any, index) => {
                    return (
                      <div className='one-el-2 pt-2' key={index}>
                        <div className={`part-1 ${user.id === _el[0] && 'primary-color'}`}> {_el[0]}</div>
                        <div className={`part-2 ${_el[1] > 0 && 'primary-color'}  ${_el[1] < 0 && 'red-color'}`}>
                          {_el[1] > 0 && '+'}
                          {_el[1]}
                        </div>
                      </div>
                    );
                  })}
                </div>
                <div className='one-box white-color  mr-3'>
                  <div className='size-18 pt-2 bold'>Cards earned</div> <hr className='shared-hr ' />
                  {stateAll.recordCards.map((_el: any, index) => {
                    return (
                      <div className='one-el-2 pt-2' key={index}>
                        <div className={`part-1  ${user.id === _el[0] && 'primary-color'}`}> {_el[0]}</div>
                        <div className={`part-2 ${_el[1] > 0 && 'primary-color'}  ${_el[1] < 0 && 'red-color'}`}>
                          {_el[1] > 0 && '+'}
                          {_el[1]}
                        </div>
                      </div>
                    );
                  })}
                </div>
              </Fragment>
            )}
          </div>
        </div>

        <div className='sub-boxes-container'>
          <div className='primary-color size-32 bold pb-4'>Personal Stats</div>
          <div className='boxes-container'>
            {user.isLogged && state.isLoaded && (
              <Fragment>
                <div className='one-box white-color text-uppercase mr-3'>
                  <div className='one-el size-18 pt-2'>
                    <div className={`part-1 bold`}>Games</div>
                    <div className={`part-2`}> {state.gamesNumber}</div>
                  </div>{' '}
                  <hr className='shared-hr ' />
                  <div className='one-el-3 pt-2'>
                    <div className={`part-1 bold`}>Won:</div>
                    <div className={`part-2 primary-color`}> +{state.gamesWon}</div>
                  </div>
                  <div className='one-el-3 pt-2'>
                    <div className={`part-1 bold`}>Lost:</div>
                    <div className={`part-2 red-color`}> -{state.gamesLost}</div>
                  </div>
                  <div className='one-el-3 pt-2'>
                    <div className={`part-1 bold`}>Tie:</div>
                    <div className={`part-2`}> {state.gamesTie}</div>
                  </div>
                  <div className='one-line my-3'></div>
                  <div className='one-el-3 pt-2'>
                    <div className={`part-1 bold`}>Result:</div>
                    <div
                      className={`part-2 bold size-24 ${state.gamesResult > 0 && 'primary-color'}  ${
                        state.gamesResult < 0 && 'red-color'
                      }`}
                    >
                      {state.gamesResult > 0 && '+'}
                      {state.gamesResult < 0 && '-'}
                      {state.gamesResult}
                    </div>
                  </div>
                </div>

                <div className='one-box white-color text-uppercase mr-3'>
                  <div className='one-el size-18 pt-2'>
                    <div className={`part-1 bold`}>Cards</div>
                    <div className={`part-2`}> </div>
                  </div>{' '}
                  <hr className='shared-hr ' />
                  <div className='one-el-3 pt-2'>
                    <div className={`part-1 bold`}>Won:</div>
                    <div className={`part-2 primary-color`}> +{state.cardsWon}</div>
                  </div>
                  <div className='one-el-3 pt-2'>
                    <div className={`part-1 bold`}>Lost:</div>
                    <div className={`part-2 red-color`}> -{state.cardsLost}</div>
                  </div>
                  <div className='one-el-2 pt-2'>
                    <div className={`part-1 bold`}>NL Coins:</div>
                    <div className={`part-2`}> +{state.gamesWon}</div>
                  </div>
                  <div className='one-line my-3'></div>
                  <div className='one-el-3 pt-2'>
                    <div className={`part-1 bold`}>Result:</div>
                    <div
                      className={`part-2 bold size-24 ${state.cardsResult > 0 && 'primary-color'}  ${
                        state.cardsResult < 0 && 'red-color'
                      }`}
                    >
                      {state.cardsResult > 0 && '+'}
                      {state.cardsResult}
                    </div>
                  </div>
                </div>
              </Fragment>
            )}

            {!user.isLogged && (
              <Fragment>
                <div className='one-box white-color text-uppercase mr-3 see-more'>
                  <div className='text-center'>Login to see personal STATS</div>
                  <div className='pt-4'>
                    <BuyButton
                      text='LOGIN'
                      onClick={() => {
                        dispatch(userSetLoginOption(true));
                      }}
                      classNameExtension='dashboard-card-scroll--buy-now pb-3'
                    />
                  </div>
                </div>
              </Fragment>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default StatsHome;
