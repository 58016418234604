import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { ROUND_LOST, ROUND_TIE, ROUND_WON } from '../../Constants';
import { RootState } from '../../redux/store';

interface GameRoundsProps {
  outcomeExtern?: any;
}

const GameRounds: React.FC<GameRoundsProps> = ({ outcomeExtern = null }) => {
  const { outcomes } = useSelector((state: RootState) => state.game);

  const [outcomesIntern, setOutcomesIntern] = useState(['', '', '', '', '']);

  useEffect(() => {
    if (outcomeExtern === null) {
      setOutcomesIntern(outcomes);
    } else {
      setOutcomesIntern(outcomeExtern);
    }
  }, [outcomeExtern, outcomes]);

  const getClass = (outcome: string, own: boolean) => {
    if (outcome === ROUND_WON) {
      if (own) {
        return 'won';
      } else {
        return 'lost';
      }
    } else if (outcome === ROUND_LOST) {
      if (own) {
        return 'lost';
      } else {
        return 'won';
      }
    } else if (outcome === ROUND_TIE) {
      return '';
    } else {
      return '';
    }
  };
  const getText = (outcome: string, own: boolean) => {
    if (outcome === ROUND_WON) {
      if (own) {
        return 'WON';
      } else {
        return 'OUT';
      }
    } else if (outcome === ROUND_LOST) {
      if (own) {
        return 'OUT';
      } else {
        return 'WON';
      }
    } else if (outcome === ROUND_TIE) {
      return 'TIE';
    } else {
      return '-?-';
    }
  };
  return (
    <div className='game-rounds font-family-1 white-color'>
      <table className='table'>
        <tbody>
          <tr className='head-col'>
            <td className='round-td-head'> ROUND</td>
            <td className={`result-td-head`}>YOU</td>
            <td className={`result-td-head`}>PLAYER</td>
          </tr>
          <tr>
            <td className='round-td'> 1</td>
            <td className={`result-td  ${getClass(outcomesIntern[0], true)}`}>{getText(outcomesIntern[0], true)}</td>
            <td className={`result-td ${getClass(outcomesIntern[0], false)}`}>{getText(outcomesIntern[0], false)}</td>
          </tr>
          <tr>
            <td className='round-td'> 2</td>
            <td className={`result-td  ${getClass(outcomesIntern[1], true)}`}>{getText(outcomesIntern[1], true)}</td>
            <td className={`result-td  ${getClass(outcomesIntern[1], false)}`}>{getText(outcomesIntern[1], false)}</td>
          </tr>
          <tr>
            <td className='round-td'> 3</td>
            <td className={`result-td  ${getClass(outcomesIntern[2], true)}`}>{getText(outcomesIntern[2], true)}</td>
            <td className={`result-td  ${getClass(outcomesIntern[2], false)}`}>{getText(outcomesIntern[2], false)}</td>
          </tr>
          <tr>
            <td className='round-td'> 4</td>
            <td className={`result-td  ${getClass(outcomesIntern[3], true)}`}>{getText(outcomesIntern[3], true)}</td>
            <td className={`result-td  ${getClass(outcomesIntern[3], false)}`}>{getText(outcomesIntern[3], false)}</td>
          </tr>
          <tr>
            <td className='round-td'> 5</td>
            <td className={`result-td  ${getClass(outcomesIntern[4], true)}`}>{getText(outcomesIntern[4], true)}</td>
            <td className={`result-td  ${getClass(outcomesIntern[4], false)}`}>{getText(outcomesIntern[4], false)}</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default GameRounds;
